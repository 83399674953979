import { Component } from "react";
import ErrorPage from "./ErrorPage";

import { AppContext } from "../../contexts/AppContextProvider";

class ErrorBoundary extends Component {
    static contextType = AppContext;

    static getDerivedStateFromError(error) {
        return { error };
    }

    componentDidCatch(error, info) {
        this.context.dispatch({ type: "SET_ERROR", payload: error });
    }

    render() {
        if (this.context.state.error) return <ErrorPage error={this.context.state.error} />;

        return this.props.children;
    }
}

export default ErrorBoundary;
