import { useState, useContext, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Text, usePrismic } from "@buildwise/ui";
import { asText } from "@prismicio/helpers";

import { ModelViewerContext } from "../../contexts/ModelViewerContextProvider";

const PopupProject = (props) => {
    const { state: modelState } = useContext(ModelViewerContext);

    const [name, setName] = useState(props.project === undefined ? "" : props.project.name || "");
    const [desc, setDesc] = useState(props.project === undefined ? "" : props.project.description || "");
    const [canSave, setCanSave] = useState(false);

    const [document] = usePrismic("bimio_viewer");

    useEffect(() => {
        window.document.addEventListener("keyup", handleKeyUp);
        return () => window.document.removeEventListener("keyup", handleKeyUp);
    }, []);

    useEffect(() => {
        if (props.isSaving || !name || name.trim().length === 0 || !validateInput(name)) {
            setCanSave(false);
            return;
        }

        setCanSave(true);
    }, [name, props.isSaving]);

    const handleKeyUp = (e) => {
        if (e.key === "Enter" && e.target.type !== "textarea" && canSave) save();
        else if (e.key === "Escape") props.onClose();
    };

    const save = () => {
        if (!validateInput(name)) return;

        props.onSave({
            projectBody: {
                name: name,
                description: desc,
            },
        });
    };

    const validateInput = (input) => {
        const existingProject = modelState.projects.find(
            (x) => x.name.trim().toLowerCase() === input.trim().toLowerCase()
        );
        setCanSave(
            existingProject === null ||
                existingProject === undefined ||
                (props.project && existingProject.id === props.project.id)
        );

        return (
            existingProject === null ||
            existingProject === undefined ||
            (props.project && existingProject.id === props.project.id)
        );
    };

    return (
        <Modal size="small" onClose={() => props.onClose()} open={true} backdrop>
            <ModalHeader>
                <ModalTitle>{props.title}</ModalTitle>
            </ModalHeader>

            <ModalBody>
                <div>
                    <strong>{asText(document.data.Name)}</strong>
                    <Text
                        type="text"
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                        value={name}
                        placeholder={asText(document.data.NamePlaceholder)}
                        required={name.trim().length === 0}
                        onValidate={validateInput}
                        validationError={asText(document.data.ProjectAlreadyExists)}
                    />
                </div>
                <div>
                    <strong>{asText(document.data.ShortDesc)}</strong>
                    <Text
                        type="textarea"
                        onChange={(e) => setDesc(e.target.value)}
                        value={desc}
                        placeholder={asText(document.data.ShortDescPlaceholder)}
                    />
                </div>
            </ModalBody>

            <ModalFooter>
                <Button id="popup-project-cancel-button" variant="tertiary" onClick={() => props.onClose()}>
                    {asText(document.data.Cancel)}
                </Button>
                <Button id="popup-project-save-button" onClick={() => save()} disabled={!canSave}>
                    {props.isSaving ? asText(document.data.BusySaving) : asText(document.data.Save)}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default PopupProject;
