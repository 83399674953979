import { useEffect, useState } from "react";
import { usePrismic } from "@buildwise/ui";

import { getVersion } from "../../adapters/VersionAdapter";

import version from "../../version.json";

import styles from "./Version.module.css";

const Version = () => {
    const [apiVersion, setApiVersion] = useState(null);
    const [document] = usePrismic("bimio_viewer");

    const uiVersion = version.ui;

    useEffect(() => {
        getVersion().then(setApiVersion);
    }, []);

    return (
        <div className={styles.versioning}>
            {document.data.application_copyright_notice} <br />
            API {apiVersion} - UI {uiVersion}
        </div>
    );
};

export default Version;
