import { useContext, useEffect, useState, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { usePrismic } from "@buildwise/ui";
import useModelViewer from "./Hooks/useModelViewer";
import useSectionPlanes from "./Hooks/useSectionPlanes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHomeLgAlt,
    faExpandArrows,
    faCompressArrowsAlt,
    faClipboardList,
    faCog,
    faVideo,
    faLayerPlus,
    faLayerGroup,
    // faMousePointer,
    // faRuler,
    // faHandPaper,
    // faRedo,
    // faArrowsV,
    faSearch,
    faCube,
    faLevelUp,
    faLongArrowDown,
    faLongArrowUp,
    faLongArrowLeft,
    faLongArrowRight,
    faCompass,
    faFileExport,
    faObjectsColumn,
    faFrame,
    // faPlus,
} from "@fortawesome/pro-light-svg-icons";
import { asText } from "@prismicio/helpers";

import ActionBar from "./ActionBar/ActionBar";
import ActionBarItem from "./ActionBar/ActionBarItem";

import { ModelViewerContext } from "../../contexts/ModelViewerContextProvider";

import { config } from "../../_configuration/configuration";

const ActionBars = () => {
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);

    const [showCameraSubmenu, setShowCameraSubmenu] = useState(false);
    const [showSectionPlaneSubmenu, setShowSectionPlanSubmenu] = useState(false);
    const [, /*showSelectionModeSubmenu */ setShowSelectionModeSubmenu] = useState(false);
    const [, forceUpdate] = useState(0);
    const [ifcSpaceToggle, setIfcSpaceToggle] = useState(false);
    const [ifcGridToggle, setIfcGridToggle] = useState(false);
    const fullscreenStatus = useRef(false);

    const { zoomToFit, getSelectedObjects, getObjects, setXRayedObjects, setVisibleObjects, setCameraAngle } =
        useModelViewer();
    const {
        createSectionPlane,
        getSectionPlanes,
        toggleSectionPlane,
        getActiveSectionPlaneControl,
        toggleSectionPlaneControl,
    } = useSectionPlanes();

    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        window.document.addEventListener("fullscreenchange", updateFullscreenStatus);
        if (isFullscreen()) fullscreenStatus.current = true;

        return () => window.document.removeEventListener("fullscreenchange", updateFullscreenStatus);
    }, []);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [showCameraSubmenu, showSectionPlaneSubmenu]);

    useEffect(() => {
        for (let i = 0, len = modelState.loaded.length; i < len; i++) {
            SetIfcSpaceElementsVisiblity(modelState.loaded[i], ifcSpaceToggle);
            SetIfcGridElementsVisibility(modelState.loaded[i], ifcGridToggle);
        }
    }, [modelState.models]);

    useEffect(() => {
        for (let i = 0, len = modelState.loaded.length; i < len; i++) {
            SetIfcSpaceElementsVisiblity(modelState.loaded[i], ifcSpaceToggle);
        }
    }, [ifcSpaceToggle]);

    useEffect(() => {
        for (let i = 0, len = modelState.loaded.length; i < len; i++) {
            SetIfcGridElementsVisibility(modelState.loaded[i], ifcGridToggle);
        }
    }, [ifcGridToggle]);

    const isFullscreen = () => {
        const elem = window.document.getElementById("viewer-content");

        if (
            window.document.fullscreenElement === elem ||
            window.document.mozFullScreenElement === elem ||
            window.document.msFullscreenElement === elem ||
            window.document.webkitFullscreenElement === elem
        )
            return true;

        return false;
    };

    const updateFullscreenStatus = () => {
        fullscreenStatus.current = isFullscreen();
        forceUpdate((x) => x + 1);
    };

    const handleZoom = () => {
        zoomToFit(getSelectedObjects());
    };

    const toggleFullscreen = () => {
        const elem = window.document.getElementById("viewer-content");
        const headerElem = window.document.getElementById("viewer-header");

        if (window.document.fullscreenElement === elem) {
            window.document.exitFullscreen();
            headerElem.scrollIntoView();
            return;
        }

        if (window.document.mozFullScreenElement === elem) {
            window.document.mozCancelFullScreen();
            headerElem.scrollIntoView();
            return;
        }

        if (window.document.msFullscreenElement === elem) {
            window.document.msExitFullscreen();
            headerElem.scrollIntoView();
            return;
        }

        if (window.document.webkitFullscreenElement === elem) {
            window.document.webkitExitFullscreen();
            headerElem.scrollIntoView();
            return;
        }

        window.scrollTo(0, 0);

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            /* IE/Edge */
            elem.msRequestFullscreen();
        }
    };

    const resetActiveView = () => {
        // Disable active view and filter
        modelDispatch({ type: "RESET_ACTIVE_VIEW" });
        modelDispatch({ type: "FILTER_GUIDS", payload: null });
        modelDispatch({ type: "ACTIVE_FILTER", payload: null });

        // Reset xray and visibility
        setXRayedObjects(getObjects(), false);
        setVisibleObjects(getObjects(), true);

        // Hide IfcSpaces
        for (let i = 0, len = modelState.loaded.length; i < len; i++) {
            SetIfcSpaceElementsVisiblity(modelState.loaded[i]);
        }

        // Disable sections
        const sections = getSectionPlanes();
        const activeId = getActiveSectionPlaneControl();
        for (let i = 0, len = sections.length; i < len; i++) {
            const section = sections[i];
            if (section.active) toggleSectionPlane(section);
            if (section.id === activeId) toggleSectionPlaneControl(section);
        }

        // Move to default camera position
        setCameraAngle("front-top-right");
    };

    const SetIfcSpaceElementsVisiblity = (model, visibility) => {
        const ifcSpaceElements = [];
        if (!model && modelState.mergedClassTree.length === 0) return;

        traverseIfcSpaceNodes(
            model ? model.id : null,
            model ? model.spatialStructureTree : modelState.mergedClassTree,
            ifcSpaceElements,
            visibility
        );

        setVisibleObjects(ifcSpaceElements, visibility);
    };

    const SetIfcGridElementsVisibility = (model, visibility) => {
        const ifcGridElements = [];
        if (!model && modelState.mergedClassTree.length === 0) return;

        traverseIfcGridNodes(
            model ? model.id : null,
            model ? model.spatialStructureTree : modelState.mergedClassTree,
            ifcGridElements,
            visibility
        );

        setVisibleObjects(ifcGridElements, visibility);
    };
    const traverseIfcGridNodes = (modelId, nodes, arr, visibility) => {
        const myNodes = Array.isArray(nodes) ? nodes : [nodes];
        for (let i = 0; i < myNodes.length; i++) {
            const node = myNodes[i];

            if (node.type === "IfcGrid" || (node.parent && node.parent.endsWith("IfcGrid"))) {
                node.visible = visibility;
                arr.push(node.id);
            }

            if (node.children && node.children.length > 0) {
                traverseIfcGridNodes(modelId, node.children, arr, visibility);
            }
        }
    };
    const traverseIfcSpaceNodes = (modelId, nodes, arr, visibility) => {
        const myNodes = Array.isArray(nodes) ? nodes : [nodes];
        for (let i = 0; i < myNodes.length; i++) {
            const node = myNodes[i];

            if (node.type === "IfcSpace" || (node.parent && node.parent.endsWith("IfcSpace"))) {
                node.visible = visibility;
                arr.push(node.id);
            }

            if (node.children && node.children.length > 0) {
                traverseIfcSpaceNodes(modelId, node.children, arr, visibility);
            }
        }
    };

    return (
        <div id="actionbars">
            <div id="actionbar-wrapper">
                <ActionBar id="navigation-tools">
                    <ActionBarItem
                        name="home-view"
                        tooltip={asText(document.data.ViewerActionHomeTooltip)}
                        // position={({left, top}, currentEvent, currentTarget, node, place, desiredPlace, effect, offset) => {
                        //     console.log("Tooltip position:", left, top, currentEvent, currentTarget, node, place, desiredPlace, effect, offset)
                        //     return { left: currentTarget.offsetLeft, top: top + 5 };
                        // }}
                    >
                        <button
                            id="action-bar-reset-active-view"
                            style={{ position: "relative" }}
                            onClick={() => {
                                resetActiveView();
                                setShowCameraSubmenu(false);
                                setShowSectionPlanSubmenu(false);
                            }}
                        >
                            <FontAwesomeIcon icon={faHomeLgAlt} />
                        </button>
                    </ActionBarItem>
                    <ActionBarItem
                        id="zoom-to-model-or-item"
                        name="zoom-to-model-or-item"
                        tooltip={asText(document.data.ViewerActionZoomTooltip)}
                        // position={({left, top}, currentEvent, currentTarget, node, place, desiredPlace, effect, offset) => {
                        //     console.log("Tooltip position:", left, top, currentEvent, currentTarget, node, place, desiredPlace, effect, offset)
                        //     return { left: currentTarget.offsetLeft, top: top + 5 };
                        // }}
                    >
                        <button
                            id="action-bar-zoom-button"
                            style={{ position: "relative" }}
                            onClick={() => {
                                handleZoom();
                                setShowCameraSubmenu(false);
                                setShowSectionPlanSubmenu(false);
                            }}
                        >
                            <FontAwesomeIcon icon={faSearch} />
                            <FontAwesomeIcon
                                icon={faCube}
                                style={{
                                    position: "absolute",
                                    top: "10px",
                                    left: "25px",
                                    fontSize: "10px",
                                }}
                            />
                        </button>
                    </ActionBarItem>
                    <ActionBarItem
                        id="set-camera-perspective"
                        name="set-camera-perspective"
                        tooltip={showCameraSubmenu ? null : asText(document.data.ViewerActionPerspectiveTooltip)}
                        onClick={() => {
                            setShowCameraSubmenu((x) => !x);
                            setShowSectionPlanSubmenu(false);
                            setShowSelectionModeSubmenu(false);
                        }}
                    >
                        <button id="action-bar-camera-type-menu-button">
                            <div className="actionbar-group">
                                <FontAwesomeIcon icon={faVideo} />
                                {showCameraSubmenu && (
                                    <div className="actionbar-group-buttons" onClick={(e) => e.stopPropagation()}>
                                        <span>{asText(document.data.SelectCameraType)}</span>
                                        <ul>
                                            <li
                                                id="action-bar-camera-perspective-button"
                                                onClick={() => {
                                                    modelDispatch({
                                                        type: "SET_CAMERA",
                                                        payload: "perspective",
                                                    });
                                                    setShowCameraSubmenu(false);
                                                }}
                                            >
                                                {asText(document.data.Perspective)}
                                            </li>
                                            <li
                                                id="action-bar-camera-orthographic-button"
                                                onClick={() => {
                                                    modelDispatch({
                                                        type: "SET_CAMERA",
                                                        payload: "ortho",
                                                    });
                                                    setShowCameraSubmenu(false);
                                                }}
                                            >
                                                {asText(document.data.Orthographic)}
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </button>
                    </ActionBarItem>
                    {/* <ActionBarItem
                        name="toggle-selection-mode"
                        tooltip={asText(document.data.SelectionMode)}
                        onClick={() => {
                            setShowSelectionModeSubmenu((x) => !x);
                            setShowCameraSubmenu(false);
                            setShowSectionPlanSubmenu(false);
                        }}
                        position={(position) => ({
                            left: position.left + 120,
                            top: position.top,
                        })}
                    >
                        <button>
                            <div className="actionbar-group">
                                <FontAwesomeIcon icon={faMousePointer} />
                                {modelState.selectionMode === "multi" && (
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        style={{
                                            position: "absolute",
                                            top: "0px",
                                            left: "15px",
                                            fontSize: "14px",
                                        }}
                                    />
                                )}
                                {showSelectionModeSubmenu && (
                                    <div
                                        className="actionbar-group-buttons"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <span>{asText(document.data.SelectionMode)}</span>
                                        <ul>
                                            <li
                                                onClick={() => {
                                                    modelDispatch({
                                                        type:
                                                            "SET_SELECTION_MODE",
                                                        payload: "single",
                                                    });
                                                    setShowSelectionModeSubmenu(
                                                        false
                                                    );
                                                }}
                                            >
                                                {asText(document.data.SingleSelect)}
                                            </li>
                                            <li
                                                onClick={() => {
                                                    modelDispatch({
                                                        type:
                                                            "SET_SELECTION_MODE",
                                                        payload: "multi",
                                                    });
                                                    setShowSelectionModeSubmenu(
                                                        false
                                                    );
                                                }}
                                            >
                                                {asText(document.data.MultiSelect)}
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </button>
                    </ActionBarItem> */}

                    <ActionBarItem
                        name="grid-toggle"
                        tooltip={document.data.ViewerActionGridTooltip}
                        style={{
                            background: ifcGridToggle ? "rgba(223, 239, 244, 0.7)" : "unset",
                        }}
                    >
                        <button
                            id="action-bar-grid-toggle"
                            style={{ position: "relative" }}
                            onClick={() => {
                                setIfcGridToggle(!ifcGridToggle);
                            }}
                        >
                            <FontAwesomeIcon icon={faFrame} />
                        </button>
                    </ActionBarItem>

                    <ActionBarItem
                        name="space-toggle"
                        tooltip={document.data.ViewerActionSpaceTooltip}
                        style={{
                            background: ifcSpaceToggle ? "rgba(223, 239, 244, 0.7)" : "unset",
                        }}
                    >
                        <button
                            id="action-bar-space-toggle"
                            style={{ position: "relative" }}
                            onClick={() => {
                                setIfcSpaceToggle(!ifcSpaceToggle);
                            }}
                        >
                            <FontAwesomeIcon icon={faObjectsColumn} />
                        </button>
                    </ActionBarItem>
                </ActionBar>

                <ActionBar id="section-tools">
                    <ActionBarItem
                        id="add-section-plane"
                        name="add-section-plane"
                        tooltip={showSectionPlaneSubmenu ? null : asText(document.data.ViewerActionAddSectionTooltip)}
                        onClick={() => {
                            setShowSelectionModeSubmenu(false);
                            setShowCameraSubmenu(false);
                            setShowSectionPlanSubmenu((x) => !x);
                            ReactTooltip.rebuild();
                        }}
                    >
                        <button id="action-bar-section-menu-button">
                            <div className="actionbar-group">
                                <FontAwesomeIcon icon={faLayerPlus} />
                                {showSectionPlaneSubmenu && (
                                    <div className="actionbar-group-buttons" onClick={(e) => e.stopPropagation()}>
                                        <span>{asText(document.data.CreateASection)}</span>
                                        <ul>
                                            <li
                                                id="action-bar-section-create-horizontal-up-button"
                                                onClick={() => {
                                                    createSectionPlane({
                                                        id: `section-${Date.now()}-Horizontal`,
                                                        dir: [0, -1, 0],
                                                    });
                                                    setShowSectionPlanSubmenu(false);
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faLevelUp} />
                                                {asText(document.data.Horizontal)}
                                            </li>
                                            <li
                                                id="action-bar-section-create-vertical-back-button"
                                                onClick={() => {
                                                    createSectionPlane({
                                                        id: `section-${Date.now()}-Vertical`,
                                                        dir: [0, 0, 1],
                                                    });
                                                    setShowSectionPlanSubmenu(false);
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faLongArrowUp} />
                                                {asText(document.data.Vertical)}
                                            </li>
                                            <li
                                                id="action-bar-section-create-vertical-front-button"
                                                onClick={() => {
                                                    createSectionPlane({
                                                        id: `section-${Date.now()}-Vertical`,
                                                        dir: [0, 0, -1],
                                                    });
                                                    setShowSectionPlanSubmenu(false);
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faLongArrowDown} />
                                                {asText(document.data.Vertical)}
                                            </li>
                                            <li
                                                id="action-bar-section-create-vertical-left-button"
                                                onClick={() => {
                                                    createSectionPlane({
                                                        id: `section-${Date.now()}-Vertical`,
                                                        dir: [1, 0, 0],
                                                    });
                                                    setShowSectionPlanSubmenu(false);
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faLongArrowLeft} />
                                                {asText(document.data.Vertical)}
                                            </li>
                                            <li
                                                id="action-bar-section-create-vertical-right-button"
                                                onClick={() => {
                                                    createSectionPlane({
                                                        id: `section-${Date.now()}-Vertical`,
                                                        dir: [-1, 0, 0],
                                                    });
                                                    setShowSectionPlanSubmenu(false);
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faLongArrowRight} />
                                                {asText(document.data.Vertical)}
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </button>
                    </ActionBarItem>

                    <ActionBarItem
                        id="toggle-sections-viewer"
                        name="toggle-sections-viewer"
                        tooltip={asText(document.data.ViewerActionSectionManagementTooltip)}
                        onClick={() => {
                            modelDispatch({ type: "TOGGLE_SECTIONS_VIEWER" });
                            setShowCameraSubmenu(false);
                            setShowSectionPlanSubmenu(false);
                        }}
                    >
                        <button id="action-bar-section-manage-button">
                            <div className="actionbar-group" data-count={getSectionPlanes().length}>
                                <FontAwesomeIcon icon={faLayerGroup} />
                            </div>
                        </button>
                    </ActionBarItem>
                </ActionBar>

                <ActionBar id="viewer-tools">
                    <ActionBarItem
                        id="toggle-properties-viewer"
                        name="toggle-properties-viewer"
                        tooltip={asText(document.data.ViewerActionPropertiesTooltip)}
                        onClick={() => {
                            modelDispatch({ type: "TOGGLE_PROPERTY_VIEWER" });
                            setShowCameraSubmenu(false);
                            setShowSectionPlanSubmenu(false);
                        }}
                    >
                        <button id="action-bar-property-viewer-toggle-button" style={{ padding: "5px 22px" }}>
                            <FontAwesomeIcon icon={faClipboardList} />
                        </button>
                    </ActionBarItem>
                    <ActionBarItem
                        id="toggle-tv-search"
                        name="toggle-tv-search"
                        tooltip={asText(document.data.ViewerActionTvSearchTooltip)}
                        onClick={() => {
                            modelDispatch({ type: "TOGGLE_TV_SEARCH" });
                            setShowCameraSubmenu(false);
                            setShowSectionPlanSubmenu(false);
                        }}
                    >
                        <button id="action-bar-tv-search-toggle-button" style={{ padding: "5px 22px" }}>
                            <FontAwesomeIcon icon={faCompass} />
                        </button>
                    </ActionBarItem>
                    <ActionBarItem
                        id="toggle-export"
                        name="toggle-export"
                        tooltip={document.data.viewer_toolbar_export_qto}
                        onClick={() => {
                            modelDispatch({
                                type: "SET_EXPORT",
                                payload: { models: modelState.loaded.map((x) => x.id), isView: true },
                            });
                            setShowCameraSubmenu(false);
                            setShowSectionPlanSubmenu(false);
                        }}
                    >
                        <button id="action-bar-export-modal-button" style={{ padding: "5px 22px" }}>
                            <FontAwesomeIcon icon={faFileExport} />
                        </button>
                    </ActionBarItem>
                </ActionBar>

                <ActionBar id="viewer-settings">
                    <ActionBarItem
                        id="toggle-fullscreen"
                        name="toggle-fullscreen"
                        tooltip={asText(document.data.ViewerActionFullscreenTooltip)}
                        onClick={() => {
                            toggleFullscreen();
                            setShowCameraSubmenu(false);
                            setShowSectionPlanSubmenu(false);
                        }}
                    >
                        <button id="action-bar-full-screen-toggle-button" style={{ padding: "5px 22px" }}>
                            <FontAwesomeIcon icon={fullscreenStatus.current ? faCompressArrowsAlt : faExpandArrows} />
                        </button>
                    </ActionBarItem>
                    <ActionBarItem
                        id="toggle-viewer-configuration"
                        name="toggle-viewer-configuration"
                        tooltip={asText(document.data.ViewerActionConfigTooltip)}
                        onClick={() => {
                            modelDispatch({
                                type: "TOGGLE_VIEWER_CONFIGURATION",
                            });
                            setShowCameraSubmenu(false);
                            setShowSectionPlanSubmenu(false);
                        }}
                    >
                        <button id="action-bar-viewer-configuration-toggle-button">
                            <FontAwesomeIcon icon={faCog} />
                        </button>
                    </ActionBarItem>
                </ActionBar>
            </div>
        </div>
    );
};

export default ActionBars;
