import { Fragment, useEffect, useRef, useContext, useState } from "react";
import { generatePath, Link, useParams } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";
import { Edit, ArrowUp, ArrowDown } from "react-feather";
import axios from "axios";
import { Breadcrumb, Spinner, useAuthentication, useLanguage, usePrismic } from "@buildwise/ui";
//import { IfcViewerContextProvider } from "@dstudio/xeokit-react";
import { IfcViewerContextProvider } from "../../contexts/IfcViewerContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandArrows } from "@fortawesome/pro-regular-svg-icons";
import { asText } from "@prismicio/helpers";

import Sidebar from "../ModelViewer/Sidebar";
import Viewer from "../ModelViewer/Viewer";
import PopupProject from "../../components/PopupProject";

import { ModelViewerContext } from "../../contexts/ModelViewerContextProvider";
import { FilterContext } from "../../contexts/FilterContextProvider";

import { getDemoProject } from "../../adapters/AdminAdapter/AdminAdapter";

import { config } from "../../_configuration/configuration";

import "../../styles/model-viewer.css";

const AdminProjectViewer = () => {
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);
    const { dispatch: filterDispatch } = useContext(FilterContext);

    const [document] = usePrismic(config.prismic.documentType);
    const [project, setProject] = useState({ name: asText(document.data.BusyLoading) });
    const [isEdittingProject, setIsEdittingProject] = useState(false);
    const [isSavingEdit, setIsSavingEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const headerRef = useRef();
    const [viewerInView, setViewerInView] = useState(false);

    const params = useParams();
    const { id: projectId } = params;
    const { isAuthenticated } = useAuthentication();
    const originalFetch = useRef(window.fetch);
    const axiosInterceptor = useRef();
    const { language } = useLanguage();

    const ignoredEndpoints = [
        "api/v1/Projects",
        "api/v1/Filters/Operators/Number",
        "api/v1/Filters/Operators/Text",
        "api/v1/Filters/Operators/Boolean",
        "api/v1/Projects/SharedWithMe",
    ];

    useEffect(() => {
        window.fetch = async function (input, info) {
            // Don't intercept request regarding authentication
            if (input.toString().startsWith(config.auth.authority)) {
                return originalFetch.current.apply(this, [input, info]);
            }

            // Don't intercept request regarding prismic
            if (input.toString().indexOf("prismic.io") >= 0) {
                return originalFetch.current.apply(this, [input, info]);
            }

            if (!input.endsWith("?admin=true")) {
                const isIgnored = ignoredEndpoints.some((x) => input.endsWith(x));
                if (!isIgnored) input = `${input}?admin=true`;
            }

            return originalFetch.current.apply(this, [input, info]);
        };

        axiosInterceptor.current = axios.interceptors.request.use(
            (config) => {
                if (!config.url.endsWith("?admin=true")) {
                    const isIgnored = ignoredEndpoints.some((x) => config.url.endsWith(x));
                    if (!isIgnored) config.url = `${config.url}?admin=true`;
                }

                return config;
            },
            (error) => Promise.reject(error)
        );

        return () => {
            window.fetch = originalFetch.current;
            axios.interceptors.request.eject(axiosInterceptor.current);
        };
    }, []);

    // Show a dialog when the user refreshes the page, this does not stop the user from changing to another page, we'll use react-router's <Prompt> for this
    useBeforeunload((e) => {
        if (modelState.models.some((x) => x.upload && x.upload.progress < 100))
            return asText(document.data.UploadWillBeCancelled);

        delete e.returnValue;
    });

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    });

    useEffect(() => {
        if (isLoading) return;

        const timeout = setTimeout(
            () => headerRef.current && headerRef.current.scrollIntoView({ behavior: "smooth" }),
            500
        );

        return () => clearTimeout(timeout);
    }, [isLoading]);

    useEffect(() => {
        getDemoProject().then((project) => {
            if (project.id !== projectId) return;

            setProject(project);
            modelDispatch({
                type: "SELECT_PROJECT",
                payload: {
                    id: projectId,
                    isShared: false,
                },
            });
        });
    }, [projectId]);

    useEffect(() => {
        if (!project.id) return;

        fetch(`${config.api}api/v1/Projects/${projectId}/Models`, {
            method: "GET",
            mode: "cors",
            headers: {},
        })
            .then((response) => response.json())
            .then((json) => {
                modelDispatch({
                    type: "SET_MODELS",
                    payload: { project: projectId, models: json },
                });
                setIsLoading(false);
            })
            .catch((e) => console.warn("Failed to get models list:", e));

        fetch(`${config.api}api/v1/Projects/${projectId}/Filters`, {
            method: "GET",
            mode: "cors",
            headers: {},
        })
            .then((response) => response.json())
            .then((json) => {
                filterDispatch({
                    type: "SET_FILTERS",
                    payload: json,
                });
            })
            .catch((e) => console.warn("Failed to get filters list:", e));

        fetch(`${config.api}api/v1/Projects/${projectId}/ColorFilters`, {
            method: "GET",
            mode: "cors",
            headers: {},
        })
            .then((response) => response.json())
            .then((json) => {
                filterDispatch({
                    type: "SET_COLOR_FILTERS",
                    payload: json,
                });
            })
            .catch((e) => console.warn("Failed to get filters list:", e));
    }, [project]);

    useEffect(() => {
        if (project.name === asText(document.data.BusyLoading || !project)) return;

        const options = {
            headers: {
                "Content-Type": "application/json",
            },
            method: "GET",
        };

        fetch(`${config.api}api/v1/Projects/${projectId}/Views`, options)
            .then((response) => response.json())
            .then(parseViews)
            .catch((e) => console.warn("Failed to obtain views:", e));
    }, [project.id]);

    const parseViews = (views) => {
        const allViews = views.map((x) => {
            return { ...x, users: [] };
        });

        const availableViews = [];

        const visibleViews = modelState.isSharedProject
            ? allViews.filter((x) => availableViews.some((y) => y === x.id))
            : allViews;

        modelDispatch({ type: "SET_VIEWS", payload: visibleViews });
    };

    const updateProject = (response) => {
        setIsSavingEdit(true);

        const body = response.projectBody;
        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        };

        fetch(`${config.api}api/v1/Projects/${projectId}`, options).then((response) => {
            setIsSavingEdit(false);

            if (!response.ok) return;

            const updatedProject = {
                ...project,
                name: body.name,
                description: body.description,
            };

            setProject(updatedProject);
            setIsEdittingProject(false);
        });
    };

    const toggleFullscreen = () => {
        const elem = window.document.getElementById("viewer-content");
        const headerElem = window.document.getElementById("viewer-header");

        if (window.document.fullscreenElement === elem) {
            window.document.exitFullscreen();
            headerElem.scrollIntoView();
            return;
        }

        if (window.document.mozFullScreenElement === elem) {
            window.document.mozCancelFullScreen();
            headerElem.scrollIntoView();
            return;
        }

        if (window.document.msFullscreenElement === elem) {
            window.document.msExitFullscreen();
            headerElem.scrollIntoView();
            return;
        }

        if (window.document.webkitFullscreenElement === elem) {
            window.document.webkitExitFullscreen();
            headerElem.scrollIntoView();
            return;
        }

        window.scrollTo(0, 0);

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            /* IE/Edge */
            elem.msRequestFullscreen();
        }
    };

    const onScroll = (e) => {
        if (!headerRef.current) return;
        setViewerInView(window.scrollY === headerRef.current.offsetTop);
    };

    const handleScrollButton = () => {
        if (viewerInView) {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        } else {
            headerRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    return isLoading ? (
        <Spinner />
    ) : (
        <IfcViewerContextProvider>
            {/* TODO Replace this implementation with something custom */}
            {/* <Prompt
                when={modelState.models.some((x) => x.upload && x.upload.progress < 100)}
                message={asText(document.data.UploadWillBeCancelled)}
            /> */}

            {project?.name && (
                <Breadcrumb>
                    <Link id="admin-project-viewer-breadcrumb-project-link" to={generatePath(config.routes.project[language], { ...params })}>{project.name}</Link>
                </Breadcrumb>
            )}

            <div id="viewer-header" ref={headerRef}>
                <h1>{project && project.name}</h1>
                <div id="action-buttons">
                    {!modelState.isSharedProject && isAuthenticated && (
                        <Fragment>
                            <button
                                id="admin-project-viewer-edit-details-button"
                                className="button secondary"
                                onClick={() => setIsEdittingProject(true)}
                                style={{ marginRight: "15px" }}
                            >
                                <Edit style={{ marginRight: "14px" }} />
                                {asText(document.data.EditDetails)}
                            </button>
                            <div className="vertical-spacer"></div>
                        </Fragment>
                    )}

                    <button
                        id="admin-project-viewer-fullscreen-toggle-button"
                        className="button secondary"
                        onClick={() => toggleFullscreen()}
                        style={{ marginRight: "15px" }}
                    >
                        <FontAwesomeIcon icon={faExpandArrows} style={{ marginRight: "14px" }} />
                        {asText(document.data.FullScreen)}
                    </button>

                    <div className="vertical-spacer"></div>

                    <button id="admin-project-viewer-scroll-button" className="button secondary" onClick={() => handleScrollButton()}>
                        {viewerInView ? <ArrowUp /> : <ArrowDown />}
                    </button>
                </div>

                <div className="break"></div>

                {modelState.isSharedProject ? (
                    <p className="tag shared">{project.ownerEmail}</p>
                ) : (
                    <p className="tag">
                        {project.isShared ? asText(document.data.Shared) : asText(document.data.NotShared)}
                    </p>
                )}
            </div>

            <div id="viewer-page">
                <div id="viewer-content">
                    <Sidebar />
                    <Viewer />
                </div>
            </div>

            {isEdittingProject && (
                <PopupProject
                    title={asText(document.data.EditDetails)}
                    project={project}
                    onClose={() => {
                        setIsEdittingProject(false);
                        setIsSavingEdit(false);
                    }}
                    onSave={updateProject}
                    isSaving={isSavingEdit}
                />
            )}
        </IfcViewerContextProvider>
    );
};

export default AdminProjectViewer;
