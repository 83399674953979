import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Rnd } from "react-rnd";
import { capitalize, groupBy } from "lodash";
import { Spinner, Text, usePrismic } from "@buildwise/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faTimesSquare, faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { asText } from "@prismicio/helpers";

import Highlight from "../../../components/Highlighter";

import { getElementClassifications, getElementMaterials, getElementProperties } from "../../../adapters/ElementAdapter";

import { ModelViewerContext } from "../../../contexts/ModelViewerContextProvider";

import { config } from "../../../_configuration/configuration";
import {useIndexedModal,ModelIndex} from "../../../components/ModalHelper/ModalHelper";
const identificationOrder = [
    "Model",
    "IFC Entity",
    "IFC Type",
    "Name",
    "Description",
    "OverallHeight",
    "OverallWidth",
    "OperationType",
    "Tag",
];
const locationOrder = ["Site", "Building", "Floor"];

const sortByObject = (importOrder) =>
    importOrder.reduce((obj, item, index) => {
        return {
            ...obj,
            [item]: index,
        };
    }, {});

const toLocaleNumber = (value, locale = "nl-be") => {
    if (!isNaN(Number(value))) return Number(value).toLocaleString(locale);
    else return value;
};

const ElementProperties = () => {
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);
    const {zIndex} = useIndexedModal(ModelIndex.ElementProperties);

    const expanded = useRef([]);
    const [activeTab, setActiveTab] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState("");
    const [, forceRender] = useState(0);

    const searchTimeout = useRef();

    const { id: projectId } = useParams();
    const [document] = usePrismic(config.prismic.documentType);

    const defaultPosition = {
        x: Number(localStorage.getItem("properties-x")) || 20,
        y: Number(localStorage.getItem("properties-y")) || 60,
        width: localStorage.getItem("properties-width") || 450,
        height: localStorage.getItem("properties-height") || "80%",
    };

    const renderProperties = (data, expanded, filter) => {
        if (data === null) {
            setActiveTab(tabs.find((x) => x.name === "Identification"));
            return;
        }

        return data
            .sort((a, b) => a.name.localeCompare(b.name, "fr", { ignorePunctuation: true }))
            .map((set, index) => (
                <PropertySet
                    key={index}
                    data={set}
                    filter={filter}
                    collapsed={!expanded.some((x) => x === set.id)}
                    onToggle={(setName, expand) => toggleExpand(setName, expand)}
                />
            ));
    };

    const renderMaterials = (data, expanded, filter) => {
        if (data === null) {
            setActiveTab(tabs.find((x) => x.name === "Identification"));
            return;
        }

        return data.map((set, index) => (
            <Material
                key={index}
                data={set}
                filter={filter}
                collapsed={expanded}
                onToggle={(setName, expand) => toggleExpand(setName, expand)}
            />
        ));
    };

    const renderClassification = (data, expanded, filter) => {
        if (data === null) {
            setActiveTab(tabs.find((x) => x.name === "Identification"));
            return;
        }

        return data.map((set, index) => (
            <Classification
                key={index}
                data={set}
                filter={filter}
                collapsed={expanded}
                onToggle={(setName, expand) => toggleExpand(setName, expand)}
            />
        ));
    };

    const emptyTabs = [
        { name: "Identification", title: "IdentificationTab", data: null, render: renderProperties },
        { name: "Location", title: "LocationTab", data: null, render: renderProperties },
        { name: "Properties", title: "PropertiesTab", data: null, render: renderProperties },
        { name: "Quantities", title: "QuantitiesTab", data: null, render: renderProperties },
        { name: "Materials", title: "MaterialsTab", data: null, render: renderMaterials },
        { name: "Classification", title: "ClassificationsTab", data: null, render: renderClassification },
    ];

    const [tabs, setTabs] = useState(emptyTabs);

    useEffect(() => {
        forceRender((x) => x + 1);
    }, [tabs]);

    useEffect(() => {
        let { id: ifcGuid, modelId, isModel } = modelState.activePropertiesElement;

        if (!modelState.activePropertiesElement || Object.keys(modelState.activePropertiesElement).length === 0) {
            setTabs(emptyTabs);
            setActiveTab(null);
            return;
        }

        setIsLoading(true);

        if (isModel) {
            getElementProperties(projectId, modelId, "ModelInfo").then((result) => parseProperties(result, [], []));
            setIsLoading(false);
            return;
        }

        if (ifcGuid.startsWith(modelId)) {
            ifcGuid = ifcGuid.substr(modelId.length + 1);
        }

        Promise.all([
            getElementProperties(projectId, modelId, ifcGuid),
            getElementClassifications(projectId, modelId, ifcGuid),
            getElementMaterials(projectId, modelId, ifcGuid),
        ]).then(([propertySets, classifications, materials]) => {
            parseProperties(propertySets, classifications, materials);
            setIsLoading(false);
        });
    }, [modelState.activePropertiesElement]);

    const parseProperties = (propertySets, classifications, materials) => {
        const data = [...emptyTabs];

        for (let i = 0, len = propertySets.length; i < len; i++) {
            const propertySet = propertySets[i];
            const targetTabName = determineTab(propertySet);
            const targetTab = data.find((x) => x.name === targetTabName);

            if (!targetTab.data) targetTab.data = [];
            targetTab.data.push(propertySet);
        }

        if (classifications && classifications.length > 0) {
            const classificationsTab = data.find((x) => x.name === "Classification");
            if (!classificationsTab.data) classificationsTab.data = [];
            classificationsTab.data.push(classifications);
        }

        if (materials && materials.length > 0) {
            const materialsTab = data.find((x) => x.name === "Materials");
            if (!materialsTab.data) materialsTab.data = [];
            materialsTab.data.push(groupBy(materials, "setName"));
        }

        // Merge Identification and Attributes property sets
        const identificationTab = data.find((x) => x.name === "Identification");
        const attributesSet = identificationTab.data.find((x) => x.name === "Attributes");
        identificationTab.data = [identificationTab.data.find((x) => x.name === "Identification")];
        if (attributesSet && attributesSet.properties && attributesSet.properties.length > 0) {
            const identificationSet = identificationTab.data[0].properties;
            for (var i = 0, len = attributesSet.properties.length; i < len; i++) {
                const property = attributesSet.properties[i];
                if (identificationSet.find((x) => x.name === property.name)) continue;
                identificationSet.push(property);
            }
        }

        setTabs(data);
        setActiveTab(data.find((x) => x.name === (activeTab?.name || "Identification")));
    };

    const determineTab = (propertySet) => {
        if (propertySet.containsQuantities) return "Quantities";

        switch (propertySet.name) {
            case "Attributes":
            case "Identification":
                return "Identification";
            case "Location":
                return "Location";
            default:
                return "Properties";
        }
    };

    const toggleExpand = (set, collapsed) => {
        let data = [...expanded.current];
        if (collapsed) data.push(set);
        else data = data.filter((x) => x !== set);
        expanded.current = data;

        forceRender((x) => x + 1);
    };

    const storePosition = (x, y) => {
        localStorage.setItem("properties-x", x);
        localStorage.setItem("properties-y", y);
    };

    const storeSize = (width, height) => {
        localStorage.setItem("properties-width", width);
        localStorage.setItem("properties-height", height);
    };

    return (
        <Rnd
            default={defaultPosition}
            dragHandleClassName="handle"
            id="property-viewer"
            onDragStop={(e, d) => storePosition(d.x, d.y)}
            onMouseDown={() => modelDispatch({ type: "SET_ACTIVE_MODAL", payload: ModelIndex.ElementProperties })}
            onResizeStop={(e, direction, ref, delta, position) => storeSize(ref.style.width, ref.style.height)}
            bounds="#viewer-content"
            style={{ display: "flex", zIndex: zIndex}}
        >
            <div id="property-viewer-header" className="handle">
                <FontAwesomeIcon icon={faClipboardList} />
                <h2>{asText(document.data.ElementProperties)}</h2>
                <FontAwesomeIcon
                    icon={faTimesSquare}
                    className="close-button"
                    onClick={() => modelDispatch({ type: "TOGGLE_PROPERTY_VIEWER" })}
                />
            </div>
            <div id="property-viewer-tabs">
                {activeTab &&
                    tabs.map(
                        (tab, index) =>
                            tab.data &&
                            tab.data.length > 0 && (
                                <button
                                    id={`element-properties-${tab.name.toLowerCase()}-tab-button`}
                                    key={index}
                                    className={
                                        activeTab.name === tab.name ? "button secondary active" : "button secondary"
                                    }
                                    onClick={() => setActiveTab(tab)}
                                >
                                    {asText(document.data[tab.title])}
                                </button>
                            )
                    )}
            </div>
            {!modelState.activePropertiesElement || Object.keys(modelState.activePropertiesElement).length === 0 ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    <p style={{ margin: "0 auto" }}>
                        {modelState.selectionCount === 0
                            ? asText(document.data.NoElementSelected)
                            : asText(document.data.MultipleElementsSelected)}
                    </p>
                </div>
            ) : (
                <Fragment>
                    <div id="property-viewer-searchbox">
                        <Text
                            type="search"
                            value={search}
                            placeholder={asText(document.data.Search)}
                            onChange={(e) => {
                                setSearch(e.target.value);
                                if (searchTimeout.current) clearTimeout(searchTimeout.current);

                                searchTimeout.current = setTimeout(() => setFilter(e.target.value), 500);
                            }}
                        />
                    </div>

                    <div id="property-viewer-content">
                        {isLoading ? (
                            <Spinner />
                        ) : (
                            // Need to pass expanded and filter through the function call since the render function has no access to that data.
                            activeTab && activeTab.render(activeTab.data, expanded.current, filter)
                        )}
                    </div>
                </Fragment>
            )}
        </Rnd>
    );
};

export default ElementProperties;

const PropertySet = ({ data, collapsed, onToggle, filter }) => {
    const [canCollapse, setCanCollapse] = useState(true);

    useEffect(() => {
        setCanCollapse(data.name !== "Identification" && data.name !== "Location");
    }, [data]);

    useEffect(() => {
        if (filter.length < 3) return;

        const shouldExpand = data.properties.some(
            (x) =>
                (x.name && x.name.toLowerCase().indexOf(filter.toLowerCase()) > -1) ||
                (x.value && x.value.toLowerCase().indexOf(filter.toLowerCase()) > -1)
        );

        onToggle && onToggle(data.id, shouldExpand);
    }, [data, filter]);

    const getProperties = () => {
        if (data.name === "Identification") {
            const restProperties = data.properties
                .map((x) => x.name)
                .filter((x) => !identificationOrder.some((y) => y === x))
                .sort((a, b) => a.localeCompare(b, "fr", { ignorePunctuation: true }));
            const sortOrder = [...identificationOrder, ...restProperties];
            const order = sortByObject(sortOrder);

            return data.properties.sort((a, b) => order[a.name] - order[b.name]);
        }

        if (data.name === "Location") {
            const order = sortByObject(locationOrder);
            return data.properties.sort((a, b) => order[a.name] - order[b.name]);
        }

        return data.properties.sort((a, b) => a.name.localeCompare(b.name, "fr", { ignorePunctuation: true }));
    };

    return (
        <div className="property-set">
            {canCollapse && (
                <div
                    className={collapsed ? "property-set-header" : "property-set-header expanded"}
                    onClick={() => onToggle && onToggle(data.id, collapsed)}
                >
                    <h3>{data.name}</h3>
                    {onToggle &&
                        (collapsed ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />)}
                </div>
            )}

            {collapsed && canCollapse ? null : (
                <div className="property-set-data">
                    {getProperties().map((property, index) => {
                        if (!property.value) property.value = "-";

                        if (filter.length >= 3) {
                            if (
                                property.name.toLowerCase().indexOf(filter.toLowerCase()) < 0 &&
                                property.value.toLowerCase().indexOf(filter.toLowerCase()) < 0
                            )
                                return null;
                        }

                        return (
                            <p key={index}>
                                <strong>
                                    <Highlight text={property.name} term={filter} useSpan />
                                </strong>
                                <Highlight
                                    text={`${toLocaleNumber(property.value)} ${property.unit || ""}`}
                                    term={filter}
                                    useSpan
                                />
                            </p>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

const Material = ({ data, collapsed, onToggle, filter }) => {
    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        if (filter.length < 3) return;

        Object.keys(data).forEach((materialSet) => {
            const materials = data[materialSet];

            const shouldExpand = materials.some(
                (x) => x.materialName && x.materialName.toLowerCase().indexOf(filter.toLowerCase()) > -1
            );

            onToggle && onToggle(capitalize(materialSet), shouldExpand);
        });
    }, [data, filter]);

    return Object.keys(data).map((materialSet, index) => {
        const materials = data[materialSet];
        const identifier = materialSet !== "undefined" ? capitalize(materialSet) : asText(document.data.MaterialList);
        const isCollapsed = !collapsed.some((x) => x === identifier);

        return (
            <div className="property-set" key={index}>
                <div
                    className={isCollapsed ? "property-set-header" : "property-set-header expanded"}
                    onClick={() => onToggle && onToggle(identifier, isCollapsed)}
                >
                    <h3>{identifier}</h3>

                    {onToggle &&
                        (isCollapsed ? (
                            <FontAwesomeIcon icon={faChevronDown} />
                        ) : (
                            <FontAwesomeIcon icon={faChevronUp} />
                        ))}
                </div>

                {isCollapsed ? null : (
                    <div className="property-set-data">
                        {materials
                            .sort((a, b) => a.sequence - b.sequence)
                            .map((material, i) => {
                                if (!material.thickness) material.value = "-";

                                if (filter.length >= 3) {
                                    if (material.materialName.toLowerCase().indexOf(filter.toLowerCase()) < 0)
                                        return null;
                                }

                                return (
                                    <p key={i}>
                                        <strong>
                                            <Highlight text={material.materialName} term={filter} useSpan />
                                        </strong>
                                        {material.thickness ? (
                                            <span>{`${toLocaleNumber(material.thickness)} ${
                                                material.unit || ""
                                            }`}</span>
                                        ) : (
                                            <span>-</span>
                                        )}
                                    </p>
                                );
                            })}
                    </div>
                )}
            </div>
        );
    });
};

const Classification = ({ data, collapsed, onToggle, filter }) => {
    useEffect(() => {
        if (filter.length < 3) return;

        data.forEach((classification) => {
            const shouldExpand = Object.keys(classification).some(
                (key) => classification[key] && classification[key].toLowerCase().indexOf(filter.toLowerCase()) > -1
            );

            onToggle && onToggle(classification.class, shouldExpand);
        });
    }, [data, filter]);

    return data.map((classification, index) => {
        const isCollapsed = !collapsed.some((x) => x === classification.class);

        return (
            <div className="property-set" key={index}>
                <div
                    className={isCollapsed ? "property-set-header" : "property-set-header expanded"}
                    onClick={() => onToggle && onToggle(classification.class, isCollapsed)}
                >
                    <h3>{classification.class}</h3>

                    {onToggle &&
                        (isCollapsed ? (
                            <FontAwesomeIcon icon={faChevronDown} />
                        ) : (
                            <FontAwesomeIcon icon={faChevronUp} />
                        ))}
                </div>

                {isCollapsed ? null : (
                    <div className="property-set-data">
                        {Object.keys(classification).map((key, i) => {
                            if (key === "class") return null;

                            if (filter.length >= 3) {
                                if (classification[key].toLowerCase().indexOf(filter.toLowerCase()) < 0) return null;
                            }

                            return (
                                <p key={i}>
                                    <strong>
                                        <Highlight text={capitalize(key)} term={filter} useSpan />
                                    </strong>
                                    <Highlight text={classification[key] || "-"} term={filter} useSpan />
                                </p>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    });
};
