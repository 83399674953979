import { useEffect, useRef, useContext } from "react";
import { IfcViewerContext } from "../../contexts/IfcViewerContextProvider";
import differenceBy from "lodash/differenceBy";
import { Viewer, XKTLoaderPlugin, DirLight, AmbientLight, GLTFLoaderPlugin } from "@xeokit/xeokit-sdk";
//import {AmbientLight} from "@xeokit/xeokit-sdk"

const yUp = true;
const cfg = {
    canvasId: "viewer-canvas",
    defaults: {
        IfcProject: {
            visible: false,
            priority: 0,
            pickable: false,
        },
        IfcSpace: {
            colorize: [1, 0.08, 0.58],
            opacity: 0.2,
        },
    },
    highlight: {
        fill: true,
        fillColor: [0, 0, 0],
        fillAlpha: 0.3,
        edges: true,
        edgeColor: [0, 0, 0],
        edgeAlpha: 0.5,
        edgeWidth: 0.5,
    },
    xray: {
        fill: true,
        fillColor: [0, 0, 0],
        fillAlpha: 0.05,
        edges: true,
        edgeColor: [0, 0, 0],
        edgeAlpha: 0.2,
        edgeWidth: 1,
    },
    selected: {
        fill: true,
        fillColor: [0, 0.5294, 0.7176],
        fillAlpha: 0.5,
        edges: false,
        edgeColor: [0, 0, 0],
        edgeAlpha: 0,
        edgeWidth: 0,
    },
    pointer: {
        id: "camera-pivot-marker",
        follow: true,
        display: true,
    },
};
const IfcViewer = (props) => {
    const { state, dispatch } = useContext(IfcViewerContext);
    const { navCube, models } = state;

    const viewer = useRef(null);
    const lastEntity = useRef(null);
    const loaderPlugins = useRef({});
    const canvasRef = useRef();

    useEffect(() => {
        setupViewer();
        setupDefaults();
        setupInputEvents();
        if (canvasRef.current) {
            canvasRef.current.addEventListener("wheel", scrollIntercept);
        }
        // Destroy the viewer when the component is unmounted
        return () => viewer.current && viewer.current.destroy();
    }, []);

    const scrollIntercept = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    useEffect(() => {
        const toAdd = differenceBy(props.models, models, "src");
        const toRemove = differenceBy(models, props.models, "src");
        const toUpdate = props.models.filter((model) => {
            const prevModelState = models.find((prevModel) => prevModel.src === model.src);

            return Boolean(prevModelState) && model.colors !== prevModelState.colors;
        });

        toAdd.map(loadModel);
        toUpdate.forEach(setColor);
        toRemove.forEach(unloadModel);

        dispatch({
            type: "SET_MODELS",
            payload: [
                ...props.models.map((x) => {
                    return { ...x };
                }),
            ],
        });
    }, [props.models]);

    // Setup basic input events
    const setupInputEvents = () => {
        const { scene, cameraControl } = viewer.current;
        const { pointer } = cfg;
        const onIfcElementClicked = props.onIfcElementClicked;
        if (pointer) {
            cameraControl.navMode = "orbit";
            cameraControl.followPointer = Boolean(pointer.follow);

            if (Boolean(pointer.display)) {
                const pivotElement = document.createElement("div");
                pivotElement.id = pointer.id; //(`<div id="${pointer.id}"></div>`).firstChild;
                document.body.appendChild(pivotElement);
                cameraControl.pivotElement = pivotElement;
            }
        }

        scene.input.on("mousemove", (coords) => {
            try {
                const hit = scene.pick({ canvasPos: coords });

                if (!hit) {
                    if (lastEntity.current) {
                        lastEntity.current.highlighted = false;
                        lastEntity.current = null;
                    }

                    return;
                }

                if (!lastEntity.current || hit.entity.id !== lastEntity.current.id) {
                    if (lastEntity.current) {
                        lastEntity.current.highlighted = false;
                    }

                    if (!hit.entity.isObject || !hit.entity.pickable) {
                        return;
                    }

                    lastEntity.current = hit.entity;
                    hit.entity.highlighted = true;
                }
            } catch (err) {}
        });

        scene.input.on("mouseclicked", (coords) => {
            const hit = scene.pick({ canvasPos: coords });
            let entity = null;

            if (hit) {
                if (!hit.entity.isObject || !hit.entity.pickable) {
                    return;
                }

                const hitEntity = scene.objects[hit.entity.id];
                entity = {
                    ifcGuid: hitEntity.id,
                    model: hitEntity.model.id,
                    selected: hitEntity.selected,
                };
            }

            onIfcElementClicked && onIfcElementClicked(entity);

            if (!entity) {
                dispatch({
                    type: "SET_SELECTED_SURFACE",
                    payload: null,
                });
            } else {
                dispatch({
                    type: "SET_SELECTED_SURFACE",
                    payload: { pos: entity.worldPos, normal: entity.worldNormal },
                });
            }
        });
    };

    // Setup default colors for highlight, select and xray
    const setupDefaults = () => {
        const {
            highlightMaterial: matHighlight,
            selectedMaterial: matSelected,
            xrayMaterial: matXRay,
        } = viewer.current.scene;
        let { highlight, selected, xray } = cfg;
        highlight = highlight || {};
        selected = selected || {};
        xray = xray || {};

        // Configure default behaviour for highlighting objects
        matHighlight.fill = highlight.fill === undefined ? matHighlight.fill : highlight.fill;
        matHighlight.fillColor = highlight.fillColor || matHighlight.fillColor;
        matHighlight.fillAlpha = highlight.fillAlpha || matHighlight.fillAlpha;
        matHighlight.edges = highlight.edges === undefined ? matHighlight.edges : highlight.edges;
        matHighlight.edgeColor = highlight.edgeColor || matHighlight.edgeColor;
        matHighlight.edgeAlpha = highlight.edgeAlpha || matHighlight.edgeAlpha;
        matHighlight.edgeWidth = highlight.edgeWidth || matHighlight.edgeWidth;

        // Configure default behaviour for selected objects
        matSelected.fill = selected.fill === undefined ? matSelected.fill : selected.fill;
        matSelected.fillColor = selected.fillColor || matSelected.fillColor;
        matSelected.fillAlpha = selected.fillAlpha || matSelected.fillAlpha;
        matSelected.edges = selected.edges === undefined ? matSelected.edges : selected.edges;
        matSelected.edgeColor = selected.edgeColor || matSelected.edgeColor;
        matSelected.edgeAlpha = selected.edgeAlpha || matSelected.edgeAlpha;
        matSelected.edgeWidth = selected.edgeWidth || matSelected.edgeWidth;

        // Configure default behaviour for xraying objects
        matXRay.fill = xray.fill === undefined ? matXRay.fill : xray.fill;
        matXRay.fillColor = xray.fillColor || matXRay.fillColor;
        matXRay.fillAlpha = xray.fillAlpha || matXRay.fillAlpha;
        matXRay.edges = xray.edges === undefined ? matXRay.edges : xray.edges;
        matXRay.edgeColor = xray.edgeColor || matXRay.edgeColor;
        matXRay.edgeAlpha = xray.edgeAlpha || matXRay.edgeAlpha;
        matXRay.edgeWidth = xray.edgeWidth || matXRay.edgeWidth;
    };

    const setupViewer = () => {
        const { canvasId, preserveDrawingBuffer, transparent } = cfg;

        if (canvasId === undefined || canvasId === null) {
            throw new Error("`IfcModelViewer` requires a canvas id to be set.");
        }

        viewer.current = new Viewer({
            canvasId: canvasId,
            preserveDrawingBuffer: preserveDrawingBuffer !== false,
            transparent: transparent !== false,
        });

        viewer.current.scene.clearLights();

        new AmbientLight(viewer.current.scene, {
            color: [0.7, 0.7, 0.7],
            intensity: 0.4,
        });

        new DirLight(viewer.current.scene, {
            dir: [0.8, -0.6, -0.8],
            color: [1.0, 1.0, 1.0],
            intensity: 0.6,
            space: "view",
        });

        new DirLight(viewer.current.scene, {
            dir: [-0.8, -0.4, -0.4],
            color: [1.0, 1.0, 1.0],
            intensity: 0.6,
            space: "view",
        });

        new DirLight(viewer.current.scene, {
            dir: [0.2, -0.8, 0.8],
            color: [0.7, 0.7, 0.7],
            intensity: 0.6,
            space: "view",
        });

        dispatch({
            type: "SET_VIEWER",
            payload: viewer.current,
        });

        const { camera } = viewer.current;

        if (!yUp) {
            camera.worldAxis = [1, 0, 0, 0, 0, 1, 0, -1, 0];
        }
    };

    // Loads the passed model into the viewer
    const loadModel = (modelToAdd) => {
        //const { cfg } = props;

        const modelExtension = modelToAdd.extension || modelToAdd.src.split(".").pop();
        const loader = getLoader(modelExtension);

        const model = loader.load({
            ...modelToAdd,
            saoEnabled: true,
            objectDefaults: cfg.defaults || {},
        });
        modelToAdd.model = model;

        model.on("loaded", (_) => {
            viewer.current.cameraFlight.flyTo(viewer.current.scene.aabb);
            if (modelToAdd.colors) setColor(modelToAdd, modelToAdd.colors);
            modelToAdd.model = model;
            props.onModelLoaded && props.onModelLoaded(modelToAdd);
        });
    };

    // Unloads the passed model from the viewer
    const unloadModel = (modelToRemove) => {
        const model = viewer.current.scene.models[modelToRemove.id];
        if (!model) {
            return;
        }

        model.destroy();
        viewer.current.cameraFlight.flyTo(viewer.current.scene.aabb);
    };

    // Updates the colors of the passed model
    const setColor = (model) => {
        const { scene } = model.model;
        const { metaObjectsByType } = scene.viewer.metaScene;
        let { colors, grayscale } = props.cfg;
        colors = colors || {};
        grayscale = grayscale || {};

        for (const type in metaObjectsByType) {
            const ifcGuids = Object.keys(metaObjectsByType[type]);
            let entities = [];

            for (let i = 0; i < ifcGuids.length; i++) {
                const entity = metaObjectsByType[type][ifcGuids[i]];

                if (entity.metaModel.id === model.id) {
                    entities.push(ifcGuids[i]);
                }
            }

            if (model.colors === "preset") {
                const colorDefinition = colors[type] || IfcModelColors[type];

                if (colorDefinition !== undefined) {
                    scene.setObjectsColorized(entities, colorDefinition.colorize);
                } else {
                    console.warn(`Detected missing color definition for ${type}`);
                }
            } else if (model.colors === "grayscale") {
                const grayscaleDefinition = grayscale[type] || IfcModelColorsGrayscale[type];

                if (grayscaleDefinition !== undefined) {
                    scene.setObjectsColorized(entities, grayscaleDefinition.colorize);
                } else {
                    console.warn(`Detected missing grayscale definition for ${type}`);
                }
            } else if (model.colors === "original") {
                scene.setObjectsColorized(entities, null);
            }
        }
    };
    // Gets the correct Xeokit LoaderPlugin for a model using the model's extension
    const getLoader = (extension) => {
        if (!loaderPlugins.current[extension]) {
            switch (extension) {
                case "xkt":
                    loaderPlugins.current[extension] = new XKTLoaderPlugin(viewer.current);
                    break;

                case "gltf":
                    loaderPlugins.current[extension] = new GLTFLoaderPlugin(viewer.current);
                    break;

                default:
                    throw new Error(
                        `Unsupported model extension: ${extension}. Valid extensions are: ['.gltf', '.xkt']`
                    );
            }
        }

        return loaderPlugins.current[extension];
    };

    useEffect(() => {
        // eslint-disable-next-line no-mixed-operators
        if (navCube.visibility === models.length > 0) {
            return;
        }

        dispatch({
            type: "SET_NAV_CUBE_VISIBILITY",
            payload: models.length > 0,
        });
    }, [models]);

    return <canvas id={cfg.canvasId} ref={canvasRef}></canvas>;
};

export default IfcViewer;

const IfcModelColors = {
    // Priority 0

    IfcRoof: {
        colorize: [0.837255, 0.203922, 0.270588],
        priority: 0,
    },
    IfcSlab: {
        colorize: [0.637255, 0.603922, 0.670588],
        priority: 0,
    },
    IfcWall: {
        colorize: [0.537255, 0.337255, 0.237255],
        priority: 0,
    },
    IfcWallStandardCase: {
        colorize: [0.537255, 0.337255, 0.237255],
        priority: 0,
    },
    IfcCovering: {
        colorize: [0.8470588235, 0.427450980392, 0],
        priority: 0,
    },

    // Priority 1

    IfcDoor: {
        colorize: [0.637255, 0.603922, 0.670588],
        priority: 1,
    },

    // Priority 2

    IfcStair: {
        colorize: [0.637255, 0.603922, 0.670588],
        priority: 2,
    },
    IfcStairFlight: {
        colorize: [0.637255, 0.603922, 0.670588],
        priority: 2,
    },
    IfcProxy: {
        colorize: [0.137255, 0.403922, 0.870588],
        priority: 2,
    },
    IfcRamp: {
        colorize: [0.8470588235, 0.427450980392, 0],
        priority: 2,
    },

    // Priority 3

    IfcColumn: {
        colorize: [0.137255, 0.403922, 0.870588],
        priority: 3,
    },
    IfcBeam: {
        colorize: [0.137255, 0.403922, 0.870588],
        priority: 3,
    },
    IfcCurtainWall: {
        colorize: [0.137255, 0.403922, 0.870588],
        priority: 3,
    },
    IfcPlate: {
        colorize: [0.8470588235, 0.427450980392, 0, 0.5],
        opacity: 0.5,
        priority: 3,
    },
    IfcTransportElement: {
        colorize: [0.8470588235, 0.427450980392, 0],
        priority: 3,
    },
    IfcFooting: {
        colorize: [0.8470588235, 0.427450980392, 0],
        priority: 3,
    },

    // Priority 4

    IfcRailing: {
        colorize: [0.137255, 0.403922, 0.870588],
        priority: 4,
    },
    IfcFurnishingElement: {
        colorize: [0.137255, 0.403922, 0.870588],
        priority: 4,
    },
    IfcFurniture: {
        colorize: [0.8470588235, 0.427450980392, 0],
        priority: 4,
    },
    IfcSystemFurnitureElement: {
        colorize: [0.8470588235, 0.427450980392, 0],
        priority: 4,
    },

    // Priority 5

    IfcFlowSegment: {
        colorize: [0.137255, 0.403922, 0.870588],
        priority: 5,
    },
    IfcFlowitting: {
        colorize: [0.137255, 0.403922, 0.870588],
        priority: 5,
    },
    IfcFlowTerminal: {
        colorize: [0.137255, 0.403922, 0.870588],
        priority: 5,
    },
    IfcFlowController: {
        colorize: [0.8470588235, 0.427450980392, 0],
        priority: 5,
    },
    IfcFlowFitting: {
        colorize: [0.8470588235, 0.427450980392, 0],
        priority: 5,
    },
    IfcDuctSegment: {
        colorize: [0.8470588235, 0.427450980392, 0],
        priority: 5,
    },
    IfcDistributionFlowElement: {
        colorize: [0.8470588235, 0.427450980392, 0],
        priority: 5,
    },
    IfcDuctFitting: {
        colorize: [0.8470588235, 0.427450980392, 0],
        priority: 5,
    },
    IfcLightFixture: {
        colorize: [0.8470588235, 0.8470588235, 0.870588],
        priority: 5,
    },

    // Priority 6

    IfcAirTerminal: {
        colorize: [0.8470588235, 0.427450980392, 0],
        priority: 6,
    },

    IfcOpeningElement: {
        colorize: [0.137255, 0.403922, 0.870588],
        pickable: false,
        visible: false,
        priority: 6,
    },
    IfcSpace: {
        colorize: [0.137255, 0.803922, 0.870588],
        pickable: false,
        visible: false,
        opacity: 0.5,
        priority: 6,
    },

    IfcWindow: {
        colorize: [0.137255, 0.403922, 0.870588],
        pickable: false,
        opacity: 0.4,
        priority: 6, // FIXME: transparent objects need to be last in order to avoid strange wireframe effect
    },

    //

    IfcBuildingElementProxy: {
        colorize: [0.5, 0.5, 0.5],
    },

    IfcBuildingElementPart: {
        colorize: [228 / 255, 187 / 255, 123 / 255],
    },

    IfcSite: {
        colorize: [0.137255, 0.403922, 0.870588],
    },

    IfcMember: {
        colorize: [0.8470588235, 0.427450980392, 0],
    },

    DEFAULT: {
        colorize: [0.5, 0.5, 0.5],
        priority: 10,
    },
};

const IfcModelColorsGrayscale = {
    // Priority 0

    IfcRoof: {
        colorize: [0.5, 0.5, 0.5],
        priority: 0,
    },
    IfcSlab: {
        colorize: [0.5, 0.5, 0.5],
        priority: 0,
    },
    IfcWall: {
        colorize: [0.5, 0.5, 0.5],
        priority: 0,
    },
    IfcWallStandardCase: {
        colorize: [0.5, 0.5, 0.5],
        priority: 0,
    },
    IfcCovering: {
        colorize: [0.5, 0.5, 0.5],
        priority: 0,
    },

    // Priority 1

    IfcDoor: {
        colorize: [0.5, 0.5, 0.5],
        priority: 1,
    },

    // Priority 2

    IfcStair: {
        colorize: [0.5, 0.5, 0.5],
        priority: 2,
    },
    IfcStairFlight: {
        colorize: [0.5, 0.5, 0.5],
        priority: 2,
    },
    IfcProxy: {
        colorize: [0.5, 0.5, 0.5],
        priority: 2,
    },
    IfcRamp: {
        colorize: [0.5, 0.5, 0.5],
        priority: 2,
    },

    // Priority 3

    IfcColumn: {
        colorize: [0.5, 0.5, 0.5],
        priority: 3,
    },
    IfcBeam: {
        colorize: [0.5, 0.5, 0.5],
        priority: 3,
    },
    IfcCurtainWall: {
        colorize: [0.5, 0.5, 0.5],
        priority: 3,
    },
    IfcPlate: {
        colorize: [0.5, 0.5, 0.5],
        opacity: 0.5,
        priority: 3,
    },
    IfcTransportElement: {
        colorize: [0.5, 0.5, 0.5],
        priority: 3,
    },
    IfcFooting: {
        colorize: [0.5, 0.5, 0.5],
        priority: 3,
    },

    // Priority 4

    IfcRailing: {
        colorize: [0.5, 0.5, 0.5],
        priority: 4,
    },
    IfcFurnishingElement: {
        colorize: [0.5, 0.5, 0.5],
        priority: 4,
    },
    IfcFurniture: {
        colorize: [0.5, 0.5, 0.5],
        priority: 4,
    },
    IfcSystemFurnitureElement: {
        colorize: [0.5, 0.5, 0.5],
        priority: 4,
    },

    // Priority 5

    IfcFlowSegment: {
        colorize: [0.5, 0.5, 0.5],
        priority: 5,
    },
    IfcFlowitting: {
        colorize: [0.5, 0.5, 0.5],
        priority: 5,
    },
    IfcFlowTerminal: {
        colorize: [0.5, 0.5, 0.5],
        priority: 5,
    },
    IfcFlowController: {
        colorize: [0.5, 0.5, 0.5],
        priority: 5,
    },
    IfcFlowFitting: {
        colorize: [0.5, 0.5, 0.5],
        priority: 5,
    },
    IfcDuctSegment: {
        colorize: [0.5, 0.5, 0.5],
        priority: 5,
    },
    IfcDistributionFlowElement: {
        colorize: [0.5, 0.5, 0.5],
        priority: 5,
    },
    IfcDuctFitting: {
        colorize: [0.5, 0.5, 0.5],
        priority: 5,
    },
    IfcLightFixture: {
        colorize: [0.5, 0.5, 0.5],
        priority: 5,
    },

    // Priority 6

    IfcAirTerminal: {
        colorize: [0.5, 0.5, 0.5],
        priority: 6,
    },

    IfcOpeningElement: {
        colorize: [0.5, 0.5, 0.5],
        pickable: false,
        visible: false,
        priority: 6,
    },
    IfcSpace: {
        colorize: [0.5, 0.5, 0.5],
        pickable: false,
        visible: false,
        opacity: 0.5,
        priority: 6,
    },

    IfcWindow: {
        colorize: [0.5, 0.5, 0.5],
        pickable: false,
        opacity: 0.4,
        priority: 6, // FIXME: transparent objects need to be last in order to avoid strange wireframe effect
    },

    //

    IfcBuildingElementProxy: {
        colorize: [0.5, 0.5, 0.5],
    },

    IfcBuildingElementPart: {
        colorize: [0.5, 0.5, 0.5],
    },

    IfcSite: {
        colorize: [0.5, 0.5, 0.5],
    },

    IfcMember: {
        colorize: [0.5, 0.5, 0.5],
    },

    DEFAULT: {
        colorize: [0.5, 0.5, 0.5],
        priority: 10,
    },
};
