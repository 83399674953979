import { useState, useContext } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Text, usePrismic } from "@buildwise/ui";
import { PrismicRichText } from "@prismicio/react";
import { asText } from "@prismicio/helpers";

import { ModelViewerContext } from "../../../contexts/ModelViewerContextProvider";

import { config } from "../../../_configuration/configuration";

const CreateView = (props) => {
    const { state: modelState } = useContext(ModelViewerContext);

    const [name, setName] = useState("");
    const [canSave, setCanSave] = useState(false);

    const [document] = usePrismic(config.prismic.documentType);

    const validateInput = (input) => {
        if (input.length <= 0) return false;
        const existingView = modelState.views.find((x) => x.name.trim().toLowerCase() === input.trim().toLowerCase());
        setCanSave(existingView === null || existingView === undefined);
        return existingView === null || existingView === undefined;
    };

    const onSave = () => {
        if (!validateInput(name)) return;

        props.onSave && props.onSave({ name: name, emails: [] });
    };

    return (
        <Modal size="small" onClose={() => props.onClose()} open={true} backdrop>
            <ModalHeader closeButton>
                <ModalTitle>{asText(document.data.AddViewTitle)}</ModalTitle>
            </ModalHeader>

            <ModalBody>
                <div>
                    <PrismicRichText field={document.data.AddViewInfo} linkResolver={() => "/"} />
                </div>

                <div style={{ marginTop: "30px" }}>
                    <strong style={{ margin: "0 0 8px 16px" }}>{asText(document.data.ViewName)}</strong>
                    <Text
                        type="text"
                        placeholder={asText(document.data.ViewNamePlaceholder)}
                        required={name.trim().length === 0}
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                            validateInput(e.target.value);
                        }}
                        onValidate={validateInput}
                        validationError={asText(document.data.ViewAlreadyExistsWarning)}
                    />
                </div>
            </ModalBody>

            <ModalFooter>
                <Button id="create-view-cancel-button" variant="tertiary" onClick={() => props.onClose()}>
                    {asText(document.data.Cancel)}
                </Button>
                <Button id="create-view-save-button" onClick={() => onSave()} disabled={!canSave}>
                    {asText(document.data.Save)}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CreateView;
