import { useEffect, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import { PrismicRichText } from "@prismicio/react";
import { asText } from "@prismicio/helpers";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, usePrismic } from "@buildwise/ui";

import { AppContext } from "../../contexts/AppContextProvider";
import { config } from "../../_configuration/configuration";

const ErrorPage = () => {
    const { dispatch: appDispatch } = useContext(AppContext);
    const entrypointLocation = useRef(null);

    const location = useLocation();
    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        if (entrypointLocation.current) appDispatch({ type: "SET_ERROR", payload: null });
        entrypointLocation.current = location;
    }, [location]);

    useEffect(() => {
        window.document.body.classList.toggle("noscroll");
        return () => window.document.body.classList.toggle("noscroll");
    }, []);

    const onConfirm = () => {
        appDispatch({ type: "SET_ERROR", payload: null });
        window.location.reload();
    };

    return (
        <Modal backdrop open={true}>
            <ModalHeader>
                <ModalTitle>{asText(document.data.ErrorPageTitle)}</ModalTitle>
            </ModalHeader>

            <ModalBody>
                <PrismicRichText field={document.data.ErrorPageText} linkResolver={() => "/"} />
            </ModalBody>
            <ModalFooter>
                <Button id="error-page-confirm-button" variant="primary" onClick={() => onConfirm()}>
                    {asText(document.data.ErrorPageContinue)}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ErrorPage;
