import { useContext, useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useAuthentication, useLanguage, usePrismic } from "@buildwise/ui";
import { asText } from "@prismicio/helpers";

import Warning from "../../components/Warning";
import ProjectInfo from "../../components/ProjectInfo/ProjectInfo";
import NewProjectTile from "../../components/ProjectInfo/NewProjectTile";

import { ModelViewerContext } from "../../contexts/ModelViewerContextProvider";

import { config } from "../../_configuration/configuration";

const Projects = () => {
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);

    const [warning, setWarning] = useState({ visible: false });

    const navigate = useNavigate();
    const params = useParams();
    const { isAuthenticated } = useAuthentication();
    const { language } = useLanguage();
    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        modelDispatch({ type: "RESET" });

        if (!isAuthenticated && modelState.projects.length > 0) {
            navigate(generatePath(config.routes.project[language], { ...params, id: modelState.projects[0].id }));
        }
    }, [isAuthenticated, modelState.projects]);

    const deleteProject = (project, confirmed = false, result = false) => {
        if (!confirmed) {
            setWarning({
                visible: true,
                prompt: true,
                title: asText(document.data.RemoveProjectTitle),
                text: asText(document.data.RemoveNameDesc).replace("{{name}}", project.name),
                callback: (bool) => deleteProject(project, true, bool),
            });

            return;
        }

        setWarning({ visible: false });

        if (!result) return;

        const options = {
            headers: {},
            method: "DELETE",
        };

        fetch(`${config.api}api/v1/Projects/${project.id}`, options).then((response) => {
            if (response.ok) {
                modelDispatch({
                    type: "REMOVE_PROJECT",
                    payload: project.id,
                });
            }
        });
    };

    return (
        <div className="content">
            <div className="content_header">
                <h1>{asText(document.data.MyProjects)}</h1>
            </div>

            <div>
                <h2>{asText(document.data.MyProjects)}</h2>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "stretch",
                        marginTop: "4px",
                    }}
                >
                    {modelState.projects.length > 0 &&
                        modelState.projects.map((project, index) => (
                            <ProjectInfo
                                key={index}
                                project={project}
                                onOpen={() =>
                                    navigate(
                                        generatePath(config.routes.project[language], { ...params, id: project.id })
                                    )
                                }
                                onDelete={() => deleteProject(project)}
                            />
                        ))}
                    <NewProjectTile />
                </div>
            </div>

            <div style={{ marginTop: "36px" }}>
                <h2>{asText(document.data.ProjectsSharedWithMe)}</h2>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "stretch",
                        marginTop: "4px",
                    }}
                >
                    {modelState.sharedProjects.length === 0 ? (
                        <p>{asText(document.data.NoSharedProjectsAvailable)}</p>
                    ) : (
                        modelState.sharedProjects.map((project, index) => (
                            <ProjectInfo
                                key={index}
                                project={project}
                                onOpen={() =>
                                    navigate(
                                        generatePath(config.routes.project[language], { ...params, id: project.id })
                                    )
                                }
                                isSharedProject={true}
                            />
                        ))
                    )}
                </div>
            </div>

            {warning.visible && (
                <Warning promp={warning.prompt} title={warning.title} text={warning.text} callBack={warning.callback} />
            )}
        </div>
    );
};

export default Projects;
