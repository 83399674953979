import { useEffect, useRef, useState } from "react";
import { Button, IconButton, usePrismic } from "@buildwise/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown, faAngleDoubleUp, faCheck } from "@fortawesome/pro-regular-svg-icons";

import ChromeFields from "react-color/lib/components/chrome/ChromeFields";
import ChromePointer from "react-color/lib/components/chrome/ChromePointer";
import ChromePointerCircle from "react-color/lib/components/chrome/ChromePointerCircle";
import { ColorWrap, Saturation, Hue, Alpha, Checkboard } from "react-color/lib/components/common";

import styles from "./ColorPicker.module.css";

const defaultSwatches = [
    { hex: "#FF6900", rgb: { r: 255, g: 105, b: 0, a: 1 } },
    { hex: "#FCB900", rgb: { r: 252, g: 185, b: 0, a: 1 } },
    { hex: "#7BDCB5", rgb: { r: 123, g: 220, b: 181, a: 1 } },
    { hex: "#00D084", rgb: { r: 0, g: 208, b: 132, a: 1 } },
    { hex: "#8ED1FC", rgb: { r: 142, g: 209, b: 252, a: 1 } },
    { hex: "#0693E3", rgb: { r: 6, g: 147, b: 227, a: 1 } },
    { hex: "#ABB8C3", rgb: { r: 171, g: 184, b: 195, a: 1 } },
    { hex: "#EB144C", rgb: { r: 235, g: 20, b: 76, a: 1 } },
    { hex: "#F78DA7", rgb: { r: 247, g: 141, b: 167, a: 1 } },
    { hex: "#9900EF", rgb: { r: 153, g: 0, b: 239, a: 1 } },
];

const ColorPicker = ({ rgb, onChange }) => {
    const [visible, setVisible] = useState(false);
    const [color, setColor] = useState({ rgb: { ...rgb } });
    const swatchRef = useRef();

    useEffect(() => {
        onChange(color.rgb);
    }, [color]);

    return (
        <>
            <div className={styles.swatch} onClick={() => setVisible((x) => !x)} ref={swatchRef}>
                <div
                    className={styles.color}
                    style={{
                        background: `linear-gradient(135deg, rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a}) 50%, rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, 1) 50%)`,
                    }}
                />
            </div>
            {visible ? (
                <Popover
                    color={color.rgb}
                    swatchRef={swatchRef}
                    setVisible={setVisible}
                    onChange={setColor}
                    disableAlpha={false}
                    defaultView="hex"
                />
            ) : null}
        </>
    );
};

const Popover = ColorWrap(({ onChange, disableAlpha, defaultView, rgb, hsl, hsv, hex, swatchRef, setVisible }) => {
    const [style, setStyle] = useState(null);
    const [position, setPosition] = useState("bottom");
    const [advancedVisible, setAdvancedVisible] = useState(false);
    const popoverRef = useRef();

    const [document] = usePrismic("bimio_viewer");

    const popoverHeight = 453;

    useEffect(() => {
        if (!swatchRef.current || !popoverRef.current) return;

        const offset = swatchRef.current.getBoundingClientRect();
        const left = offset.left - popoverRef.current.clientWidth + swatchRef.current.clientWidth;
        const top = offset.top + swatchRef.current.clientHeight + 4;
        const bottom = window.innerHeight - offset.bottom + swatchRef.current.clientHeight + 4;

        const sideLeft = offset.left - popoverRef.current.clientWidth - 10;
        const sideBottom =
            window.innerHeight -
            offset.bottom -
            popoverRef.current.clientHeight / 2 +
            swatchRef.current.clientHeight / 2;

        if (top + popoverHeight < window.innerHeight) {
            setPosition("bottom");
            setStyle({ top, left });
        } else if (bottom + popoverHeight < window.innerHeight) {
            setPosition("top");
            setStyle({ bottom, left });
        } else {
            setPosition("side");
            setStyle({ bottom: sideBottom, left: sideLeft });
        }
    }, [popoverRef.current]);

    useEffect(() => {
        if (position !== "side") return;
        const offset = swatchRef.current.getBoundingClientRect();
        const left = offset.left - popoverRef.current.clientWidth - 10;
        const bottom =
            window.innerHeight -
            offset.bottom -
            popoverRef.current.clientHeight / 2 +
            swatchRef.current.clientHeight / 2;

        setStyle({ bottom, left });
    });

    const toggleAdvanced = (event) => {
        setAdvancedVisible((x) => !x);
        event.currentTarget.blur();
    };

    return (
        <div className={styles.popover}>
            <div className={styles.cover} onClick={() => setVisible(false)} />
            <div className={styles.picker} id="color-picker" ref={popoverRef} style={style}>
                <div className={styles.container}>
                    {position === "bottom" && (
                        <>
                            <div className={styles["container-arrow-one"]}></div>
                            <div className={styles["container-arrow-two"]}></div>
                        </>
                    )}
                    <div className={styles.content}>
                        {defaultSwatches.map((swatch, index) => (
                            <span key={index}>
                                <div
                                    title={swatch.hex}
                                    tabIndex={index}
                                    className={styles.colortile}
                                    style={{
                                        background: `rgba(${swatch.rgb.r}, ${swatch.rgb.g}, ${swatch.rgb.b}, ${swatch.rgb.a})`,
                                    }}
                                    onClick={() => {
                                        onChange(swatch.rgb);
                                        setVisible(false);
                                    }}
                                ></div>
                            </span>
                        ))}

                        <div style={{ clear: "both" }}></div>

                        <div style={{ display: "flex" }}>
                            <Button
                                id="color-picker-advanced-toggle-button"
                                variant="tertiary"
                                block
                                onClick={(event) => toggleAdvanced(event)}
                                endIcon={
                                    <FontAwesomeIcon icon={advancedVisible ? faAngleDoubleUp : faAngleDoubleDown} />
                                }
                            >
                                {advancedVisible
                                    ? document.data["color_filters_color_picker_less_options"]
                                    : document.data["color_filters_color_picker_more_options"]}
                            </Button>
                        </div>

                        {advancedVisible && (
                            <div className={styles.advanced}>
                                <div className={styles.saturation}>
                                    <Saturation hsl={hsl} hsv={hsv} pointer={ChromePointerCircle} onChange={onChange} />
                                </div>

                                <div className={styles.body}>
                                    <div className={styles["controls-container"]}>
                                        <div className={styles["color-control"]}>
                                            <div className={styles["swatch-control"]}>
                                                <div
                                                    className={styles["active-swatch"]}
                                                    style={{
                                                        background: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`,
                                                    }}
                                                />
                                                <Checkboard />
                                            </div>
                                        </div>

                                        <div className={styles.toggles}>
                                            <div className={styles.hue}>
                                                <Hue hsl={hsl} pointer={ChromePointer} onChange={onChange} />
                                            </div>

                                            <div className={styles.alpha}>
                                                <Alpha
                                                    rgb={rgb}
                                                    hsl={hsl}
                                                    pointer={ChromePointer}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <ChromeFields
                                        rgb={rgb}
                                        hsl={hsl}
                                        hex={hex}
                                        view={defaultView}
                                        onChange={onChange}
                                        disableAlpha={disableAlpha}
                                    />

                                    <div style={{ display: "flex", marginTop: 16 }}>
                                        <IconButton
                                            variant="primary"
                                            style={{ margin: "auto" }}
                                            onClick={() => setVisible(false)}
                                        >
                                            <FontAwesomeIcon icon={faCheck} />
                                        </IconButton>
                                    </div>
                                </div>

                                <div style={{ clear: "both" }}></div>
                            </div>
                        )}
                    </div>
                    {position === "top" && (
                        <>
                            <div className={styles["container-arrow-three"]}></div>
                            <div className={styles["container-arrow-four"]}></div>
                        </>
                    )}
                    {position === "side" && (
                        <>
                            <div className={styles["container-arrow-five"]}></div>
                            <div className={styles["container-arrow-six"]}></div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
});

export default ColorPicker;
