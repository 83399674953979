import { localhost } from "./localhost";
import { development } from "./development";
import { staging } from "./staging";
import { production } from "./production";

const getEnvConfig = (env) => {
    switch (env) {
        case "development":
            return development;
        case "staging":
            return staging;
        case "production":
            return production;
        case "localhost":
        default:
            return localhost;
    }
};

export const config = {
    ...getEnvConfig(process?.env.REACT_APP_STAGE ?? "localhost"),
    prismic: {
        documentType: "bimio_viewer",
        aboutApplicationItemKey: "navigation_help_contact_about_subitem",
        aboutApplicationUrlKey: "navigation_help_contact_about_url",
    },
    routes: {
        landing: {
            "nl-be": "/:lang",
            "fr-be": "/:lang",
        },
        projects: {
            "nl-be": "/:lang/projects",
            "fr-be": "/:lang/projects",
        },
        project: {
            "nl-be": "/:lang/projects/:id",
            "fr-be": "/:lang/projects/:id",
        },
        admin: {
            "nl-be": "/:lang/admin/projects",
            "fr-be": "/:lang/admin/projects",
        },
        adminProject: {
            "nl-be": "/:lang/admin/projects/:id",
            "fr-be": "/:lang/admin/projects/:id",
        },
    },
};
