import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import { BuildwiseApplication, BuildwiseProvider } from "@buildwise/ui";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

import AppRoutes from "./Routes";

import { AppContextProvider } from "./contexts/AppContextProvider";
import { ModelViewerContextProvider } from "./contexts/ModelViewerContextProvider";
import { FilterContextProvider } from "./contexts/FilterContextProvider";

import { config } from "./_configuration/configuration";

import "./styles/global.css";
import "./styles/shared.css";

if (config.gtm) {
    TagManager.initialize(config.gtm);
}

if (config.sentry) {
    Sentry.init({
        dsn: config.sentry.dsn,
        environment: config.sentry.env,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: config.sentry.sampleRate,
        ignoreErrors: ["BrowserAuthError", "InteractionRequiredAuthError", "ClientAuthError"],
        beforeBreadcrumb: (breadcrumb, hint) => {
            if (breadcrumb.category === "ui.click") {
                const { target } = hint.event;
                if (target.innerText) {
                    breadcrumb.message = `${breadcrumb.message} - ${target.innerText}`;
                }
            }

            return breadcrumb;
        },
    });

    Sentry.configureScope((scope) => {
        scope.setUser({ id: "Demo user" });
    });
}

const instance = new BuildwiseApplication({
    authentication: config.auth,
    prismic: config.prismic,
    feedbackEndpoint: config.feedback,
    cookies: {
        name: "bimio-cookie-consent",
        callback: (necessary, performance, functional, targeting, date) => {
            const gtmConsent = {
                performance: performance ? "granted" : "denied",
                functional: functional ? "granted" : "denied",
                targeting: targeting ? "granted" : "denied",
            };
            TagManager.dataLayer({ dataLayer: { ...gtmConsent, event: "consent_update" } });
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <BuildwiseProvider instance={instance}>
            <AppContextProvider>
                <ModelViewerContextProvider>
                    <FilterContextProvider>
                        <BrowserRouter>
                            <AppRoutes />
                        </BrowserRouter>
                    </FilterContextProvider>
                </ModelViewerContextProvider>
            </AppContextProvider>
        </BuildwiseProvider>
        
    </React.StrictMode>,
    document.getElementById("root")
);
