import { useEffect, useState, useContext, useLayoutEffect } from "react";
import { generatePath, useLocation, useParams } from "react-router-dom";
import { Plus } from "react-feather";
import ReactTooltip from "react-tooltip";
import { Button, useAuthentication, useLanguage, usePrismic } from "@buildwise/ui";
import { asText } from "@prismicio/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faTrash } from "@fortawesome/pro-regular-svg-icons";

import Filters from "./Filters";
import ColorFilters from "./ColorFilters";
import Tree from "../../../../components/Tree";

import { FilterContext } from "../../../../contexts/FilterContextProvider";
import { ModelViewerContext } from "../../../../contexts/ModelViewerContextProvider";

import styles from "./FilterManagement.module.css";
import { config } from "../../../../_configuration/configuration";

const FilterManagement = (props) => {
    const { state: modelState } = useContext(ModelViewerContext);
    const { state: filterState, dispatch: filterDispatch } = useContext(FilterContext);

    const [activeTab, setActiveTab] = useState(0);

    const [filters, setFilters] = useState();
    const [colorFilters, setColorFilters] = useState();

    const [activeFilter, setActiveFilter] = useState();
    const [classes, setClasses] = useState([]);
    const [properties, setProperties] = useState([]);

    const params = useParams();
    const { id: projectId } = params;
    const { pathname } = useLocation();
    const { isAuthenticated } = useAuthentication();
    const { language } = useLanguage();
    const [document] = usePrismic(config.prismic.documentType);

    const isAdminPage = pathname.startsWith(generatePath(config.routes.admin[language], { ...params }));

    useEffect(() => {
        window.document.body.classList.toggle("noscroll");
        return () => window.document.body.classList.toggle("noscroll");
    }, []);

    useEffect(() => {
        getIfcClasses();
    }, []);

    useLayoutEffect(() => {
        setActiveFilter();
        setActiveTab(modelState.filterType);
    }, [modelState.filterType]);

    useLayoutEffect(() => {
        const filters = [];
        let stateFilters = JSON.parse(JSON.stringify(filterState.filters));

        for (let i = 0, len = stateFilters.length; i < len; i++) {
            const filter = { ...stateFilters[i], isColorFilter: false };
            let groupName;
            let canRemove = true;
            if (filter.isPublished) {
                groupName = asText(document.data.PublishedFilters);
                canRemove = false;
            } else {
                groupName = asText(document.data.CustomFilters);
            }

            let group = filters.find((x) => x.name === groupName);
            if (!group) {
                group = {
                    name: groupName,
                    isGroup: true,
                    children: [],
                    expanded: true,
                    canRemove,
                };

                filters.push(group);
            }

            group.children.push(filter);
        }

        setFilters(filters);
    }, [filterState.filters]);

    useEffect(() => {
        const filters = [];
        let stateFilters = JSON.parse(JSON.stringify(filterState.colorFilters));

        for (let i = 0, len = stateFilters.length; i < len; i++) {
            const filter = { ...stateFilters[i], isColorFilter: true };
            let groupName;
            let canRemove = true;
            if (filter.isPublished) {
                groupName = asText(document.data.filter_management_published_color_filters_group);
                canRemove = false;
            } else {
                groupName = asText(document.data.filter_management_user_color_filters_group);
            }

            let group = filters.find((x) => x.name === groupName);
            if (!group) {
                group = {
                    name: groupName,
                    isGroup: true,
                    children: [],
                    expanded: true,
                    canRemove,
                };

                filters.push(group);
            }

            filter.parts.forEach((x) => (x.colorMode = "Manual"));
            group.children.push(filter);
        }

        setColorFilters(filters);
    }, [filterState.colorFilters]);

    const getIfcClasses = () => {
        const activeModels = modelState.loaded.map((m) => m.id);
        let body = { models: activeModels };

        const options_classes = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(activeModels),
        };

        fetch(`${config.api}api/v1/Projects/${projectId}/Filters/FilterClasses`, options_classes)
            .then((response) => response.json())
            .then((json) => setClasses(json))
            .catch((err) => console.warn("Error attempting to get filter classes:", err));

        const options_properties = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        };

        fetch(`${config.api}api/v1/Projects/${projectId}/Filters/FilterProperties`, options_properties)
            .then((response) => response.json())
            .then((json) => setProperties(json))
            .catch((err) => console.warn("Error attempting to get filter properties:", err));
    };

    const createFilter = () => {
        let filter;

        if (activeTab === 0) {
            filter = {
                name: asText(document.data.UnnamedFilter),
                companyWide: true,
                parts: [
                    {
                        order: 0,
                        action: "Include",
                        class: "",
                        operand: "",
                        operator: "",
                        value: "",
                        isEditing: true,
                    },
                ],
            };
        } else if (activeTab === 1) {
            filter = {
                name: asText(document.data.UnnamedFilter),
                companyWide: true,
                parts: [
                    {
                        order: 0,
                        colorMode: "Manual",
                        class: "",
                        operand: "",
                        operator: "",
                        value: "",
                        color: { r: 241, g: 112, b: 19, a: 1 },
                        isEditing: true,
                    },
                ],
                isColorFilter: true,
            };
        }

        setActiveFilter(filter);
    };

    const createCopy = (item) => {
        const name = `${
            item.isPublished ? document.data[item.name] ?? `Missing Prismic Key '${item.name}'` : item.name
        } - ${asText(document.data.Copy)}`;
        const copy = { ...item, name };
        delete copy.id;
        delete copy.parent;
        copy.isPublished = false;

        setActiveFilter({ ...copy });
    };

    const removeFilter = ({ id }) => {
        const options = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        };

        if (activeTab === 0) {
            fetch(`${config.api}api/v1/Filters/${id}`, options);
            const filters = filterState.filters.filter((x) => x.id !== id);
            filterDispatch({
                type: "SET_FILTERS",
                payload: filters,
            });
        } else if (activeTab === 1) {
            fetch(`${config.api}api/v1/ColorFilters/${id}`, options);
            const filters = filterState.colorFilters.filter((x) => x.id !== id);
            filterDispatch({
                type: "SET_COLOR_FILTERS",
                payload: filters,
            });
        }

        if (activeFilter && activeFilter.id === id) setActiveFilter();
    };

    const renderItem = (item) => {
        const classes = [styles.filterName];
        if (activeFilter?.id && activeFilter.id === item.id) classes.push(styles.selected);

        return (
            <span
                className={classes.join(" ")}
                onClick={(e) => {
                    if (item.isGroup) return;

                    setActiveFilter({ ...item });
                }}
            >
                {item.isPublished ? document.data[item.name] ?? `Missing Prismic Key '${item.name}'` : item.name}
            </span>
        );
    };

    const renderAction = (item) => {
        if (item.isGroup || !isAuthenticated) return null;

        return (
            <>
                <button
                    id="filter-management-filter-create-copy-button"
                    onClick={() => createCopy(item)}
                    data-for={`export-tooltips-${item.id}`}
                    data-tip={asText(document.data.Copy)}
                >
                    <FontAwesomeIcon icon={faCopy} />
                </button>

                <button
                    id="filter-management-filter-remove-button"
                    onClick={() => removeFilter(item)}
                    className={item.parent.canRemove || isAdminPage ? "" : "disabled"}
                    data-for={`export-tooltips-${item.id}`}
                    data-tip={asText(document.data.Delete)}
                >
                    <FontAwesomeIcon icon={faTrash} />
                </button>

                <ReactTooltip
                    id={`export-tooltips-${item.id}`}
                    type="light"
                    effect="solid"
                    border={true}
                    borderColor={"#8c969b"}
                    place={"top"}
                    getContent={(dataTip) => <span style={{ whiteSpace: "nowrap" }}>{dataTip}</span>}
                />
            </>
        );
    };

    const renderFilter = () => {
        const Component = modelState.filterType === 0 ? Filters : ColorFilters;

        return <Component filter={activeFilter} classes={classes} properties={properties} />;
    };

    return (
        <div className="overlay">
            <div id={props.id} className={styles.modal}>
                <div className={styles.left}>
                    <h2>{activeTab === 0 ? asText(document.data.Filters) : asText(document.data.ColorFilters)}</h2>

                    <div className={styles.sideContent}>
                        <div className={styles.tree}>
                            <Tree
                                indentation={15}
                                items={activeTab === 0 ? filters : colorFilters}
                                render={(item) => renderItem(item)}
                                actions={(item) => renderAction(item)}
                            />
                        </div>
                    </div>

                    <div className={styles.action}>
                        {!isAuthenticated ? (
                            <div
                                style={{ width: "100%", cursor: "pointer" }}
                                data-for="create-filter-button"
                                data-tip={asText(document.data.UserFeature)}
                            >
                                <Button id="filter-management-filter-add-button" block disabled startIcon={<Plus />}>
                                    {asText(document.data.AddFilter)}
                                </Button>
                            </div>
                        ) : (
                            <Button id="filter-management-filter-create-button" block onClick={() => createFilter()} startIcon={<Plus />}>
                                {activeTab === 0
                                    ? asText(document.data.AddFilter)
                                    : asText(document.data.filter_management_add_color_filter_button)}
                            </Button>
                        )}

                        <ReactTooltip
                            id={"create-filter-button"}
                            type="light"
                            effect="solid"
                            border={true}
                            borderColor={"#8c969b"}
                            place={"top"}
                            getContent={(dataTip) => <span>{dataTip}</span>}
                        />
                    </div>
                </div>

                {renderFilter()}
            </div>
        </div>
    );
};

export default FilterManagement;
