import { useContext } from "react";
import { IfcViewerContext } from "../../../contexts/IfcViewerContextProvider";
import { math } from "@xeokit/xeokit-sdk";

const areasZUp = [
    { label: "front", dir: [0, 1, 0], up: [0, 0, 1] },
    { label: "back", dir: [0, -1, 0], up: [0, 0, 1] },
    { label: "right", dir: [-1, 0, 0], up: [0, 0, 1] },
    { label: "left", dir: [1, 0, 0], up: [0, 0, 1] },
    { label: "top", dir: [0, 0, -1], up: [0, 1, 0] },
    { label: "bottom", dir: [0, 0, 1], up: [0, -1, 0] },
    { label: "front-top", dir: [0, 1, -1], up: [0, 1, 1] },
    { label: "left-top", dir: [1, 0, -1], up: [1, 0, 1] },
    { label: "back-top", dir: [0, -1, -1], up: [0, -1, 1] },
    { label: "right-top", dir: [-1, 0, -1], up: [-1, 0, 1] },
    { label: "front-bottom", dir: [0, 1, 1], up: [0, -1, 1] },
    { label: "left-bottom", dir: [1, 0, 1], up: [-1, 0, 1] },
    { label: "back-bottom", dir: [0, -1, 1], up: [0, 1, 1] },
    { label: "right-bottom", dir: [-1, 0, 1], up: [1, 0, 1] },
    { label: "front-left", dir: [1, 1, 0], up: [0, 0, 1] },
    { label: "front-right", dir: [-1, 1, 0], up: [0, 0, 1] },
    { label: "back-right", dir: [-1, -1, 0], up: [0, 0, 1] },
    { label: "back-left", dir: [1, -1, 0], up: [0, 0, 1] },
    { label: "front-bottom-left", dir: [1, 1, 1], up: [-1, -1, 1] },
    { label: "back-bottom-left", dir: [1, -1, 1], up: [-1, 1, 1] },
    { label: "front-top-left", dir: [1, 1, -1], up: [1, 1, 1] },
    { label: "back-bottom-right", dir: [-1, -1, 1], up: [1, 1, 1] },
    { label: "back-top-right", dir: [-1, -1, -1], up: [-1, -1, 1] },
    { label: "front-bottom-right", dir: [-1, 1, 1], up: [1, -1, 1] },
    { label: "back-top-left", dir: [1, -1, -1], up: [1, -1, 1] },
    { label: "front-top-right", dir: [-1, 1, -1], up: [-1, 1, 1] }
];

const areasYUp = [
    { label: "front", dir: [0, 0, -1], up: [0, 1, 0] },
    { label: "back", dir: [0, 0, 1], up: [0, 1, 0] },
    { label: "right", dir: [-1, 0, 0], up: [0, 1, 0] },
    { label: "left", dir: [1, 0, 0], up: [0, 1, 0] },
    { label: "top", dir: [0, -1, 0], up: [0, 0, -1] },
    { label: "bottom", dir: [0, 1, 0], up: [0, 0, 1] },
    { label: "front-top", dir: [0, -1, -1], up: [0, 1, -1] },
    { label: "left-top", dir: [1, -1, 0], up: [1, 1, 0] },
    { label: "back-top", dir: [0, -1, 1], up: [0, 1, 1] },
    { label: "right-top", dir: [-1, -1, 0], up: [-1, 1, 0] },
    { label: "front-bottom", dir: [0, 1, -1], up: [0, 1, 1] },
    { label: "left-bottom", dir: [1, 1, 0], up: [-1, 1, 0] },
    { label: "back-bottom", dir: [0, 1, 1], up: [0, 1, -1] },
    { label: "right-bottom", dir: [-1, 1, 0], up: [1, 1, 0] },
    { label: "front-left", dir: [1, 0, -1], up: [0, 1, 0] },
    { label: "front-right", dir: [-1, 0, -1], up: [0, 1, 0] },
    { label: "back-right", dir: [-1, 0, 1], up: [0, 1, 0] },
    { label: "back-left", dir: [1, 0, 1], up: [0, 1, 0] },
    { label: "front-bottom-left", dir: [1, 1, -1], up: [-1, 1, 1] },
    { label: "back-bottom-left", dir: [1, 1, 1], up: [-1, 1, -1] },
    { label: "front-top-left", dir: [1, -1, -1], up: [1, 1, -1] },
    { label: "back-bottom-right", dir: [-1, 1, 1], up: [1, 1, -1] },
    { label: "back-top-right", dir: [-1, -1, 1], up: [-1, 1, 1] },
    { label: "front-bottom-right", dir: [-1, 1, -1], up: [1, 1, 1] },
    { label: "back-top-left", dir: [1, -1, 1], up: [1, 1, 1] },
    { label: "front-top-right", dir: [-1, -1, -1], up: [-1, 1, -1] }
];


const useModelViewer = () => {
    const { state } = useContext(IfcViewerContext);
    const { viewer } = state;

    const getViewer = () => {
        if (!viewer) return null;

        return viewer;
    };

    const startLoadingAnimation = () => {
        if (!viewer || !viewer.scene) return;

        viewer.scene.canvas.spinner.processes++;
    };

    const stopLoadingAnimation = () => {
        if (!viewer || !viewer.scene) return;

        viewer.scene.canvas.spinner.processes--;
    };

    const getSnapshot = (cfg = {}) => {
        if (!viewer || !viewer.scene) return;

        return viewer.getSnapshot(cfg);
    };

    const getObjects = () => {
        if (!viewer || !viewer.scene) return;

        return viewer.scene.objectIds;
    };

    const getHighlightedObjects = () => {
        if (!viewer || !viewer.scene) return;

        return viewer.scene.highlightedObjectIds;
    };

    const setHighlightedObjects = (ids, highlighted) => {
        if (!viewer || !viewer.scene) return;

        viewer.scene.setObjectsHighlighted(ids, highlighted);
    };

    const getSelectedObjects = () => {
        if (!viewer || !viewer.scene) return;

        return viewer.scene.selectedObjectIds;
    };

    const setSelectedObjects = (ids, selected) => {
        if (!viewer || !viewer.scene) return;

        viewer.scene.setObjectsSelected(ids, selected);
    };

    const getVisibleObjects = () => {
        if (!viewer || !viewer.scene) return;

        return viewer.scene.visibleObjectIds;
    };

    const setVisibleObjects = (ids, visible) => {
        if (!viewer || !viewer.scene) return;

        viewer.scene.setObjectsVisible(ids, visible);
    };

    const getXRayedObjects = () => {
        if (!viewer || !viewer.scene) return;

        return viewer.scene.xrayedObjectIds;
    };

    const setXRayedObjects = (ids, xrayed) => {
        if (!viewer || !viewer.scene) return;

        viewer.scene.setObjectsXRayed(ids, xrayed);
    };

    const setObjectsColor = (ids, color) => {
        if (!viewer || !viewer.scene) return;

        viewer.scene.setObjectsColorized(ids, color);
    };

    const setObjectsPickable = (ids, pickable) => {
        if (!viewer || !viewer.scene) return;

        viewer.scene.setObjectsPickable(ids, pickable);
    };

    const setObjectsOpacity = (ids, opacity) => {
        if (!viewer || !viewer.scene) return;

        viewer.scene.setObjectsOpacity(ids, opacity);
    };

    const zoomToFit = (ids) => {
        if (!viewer || !viewer.scene) return;

        const aabb = ids ? viewer.scene.getAABB(ids) : viewer.scene.aabb;
        viewer.cameraFlight.flyTo(aabb);
    };

    const setCameraAngle = (angle) => {
        if (!viewer || !viewer.scene) return;

        const { zUp } = viewer.camera;
        const areas = Boolean(zUp) ? areasZUp : areasYUp;
        const area = areas.find((x) => x.label === angle);

        if (area) flyTo(area.dir, area.up);
    };

    const flyTo = (dir, up) => {
        let center = math.vec3();
        const aabb = viewer.scene.getAABB(viewer.scene.visibleObjectIds);
        const diag = math.getAABB3Diag(aabb);

        math.getAABB3Center(aabb, center);

        const dist = Math.abs(diag / Math.tan(55.0 / 2));
        viewer.cameraControl.pivotPos = center;

        viewer.cameraFlight.flyTo(
            {
                look: center,
                eye: [
                    center[0] - dist * dir[0],
                    center[1] - dist * dir[1],
                    center[2] - dist * dir[2],
                ],
                up: up || [0, 1, 0],
                orthoScale: diag * 1.3,
                duration: 0.5,
            },
            null
        );
    };

    const getSAO = () => {
        if (!viewer || !viewer.scene) return;

        if (!viewer.scene.sao.supported) {
            console.warn("SAO is not supported by this browser or GPU.");
            return;
        }

        const { sao, camera } = viewer.scene;

        return { sao, camera };
    };

    const enableSAO = () => {
        if (!viewer || !viewer.scene) return;

        if (!viewer.scene.sao.supported) {
            console.warn("SAO is not supported by this browser or GPU.");
            return;
        }

        const { sao, camera } = viewer.scene;
        sao.enabled = true; // Only works if supported (see above)
        sao.intensity = 0.25;
        sao.bias = 0.5;
        sao.scale = 1000.0;
        sao.minResolution = 0.0;
        sao.kernelRadius = 100;

        camera.perspective.near = 0.1;
        camera.perspective.far = 2000.0;
    };

    const disableSAO = () => {
        if (!viewer || !viewer.scene) return;

        const { sao } = viewer.scene;
        sao.enabled = false;
    };

    return {
        startLoadingAnimation,
        stopLoadingAnimation,
        getSnapshot,
        getObjects,
        getHighlightedObjects,
        setHighlightedObjects,
        getSelectedObjects,
        setSelectedObjects,
        getVisibleObjects,
        setVisibleObjects,
        getXRayedObjects,
        setXRayedObjects,
        setObjectsColor,
        setObjectsPickable,
        setObjectsOpacity,
        zoomToFit,
        setCameraAngle,
        getSAO,
        enableSAO,
        disableSAO,
        getViewer,
    };
};

export default useModelViewer;
