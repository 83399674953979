import { Fragment, useContext, useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { isNumber } from "lodash";
import { Button, IconButton, useAuthentication, usePrismic } from "@buildwise/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faShareAlt, faSync } from "@fortawesome/pro-regular-svg-icons";
import { asText } from "@prismicio/helpers";
import useModelViewer from "./Hooks/useModelViewer";
import CreateView from "./Modals/CreateView";
import ShareProject from "./Modals/ShareProject";
import useSectionPlanes from "./Hooks/useSectionPlanes";
import { ModelViewerContext } from "../../contexts/ModelViewerContextProvider";
import { CameraMemento } from "@xeokit/xeokit-sdk";
import { config } from "../../_configuration/configuration";

const isObject = (obj) => obj != null && typeof obj === "object";

// Compare if arrays contain the same values
const areEqual = (arr1, arr2) => {
    const distinct = (value, index, self) => self.indexOf(value) === index;

    const sortedArr1 = arr1.filter(distinct).sort();
    const sortedArr2 = arr2.filter(distinct).sort();

    if (sortedArr1.length !== sortedArr2.length) return false;

    for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) return false;
    }

    return true;
};

// Compare if objects contain the same data
const areEqualDeep = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = obj1[key];
        const val2 = obj2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !areEqualDeep(val1, val2)) || (!areObjects && val1 !== val2)) {
            if (isNumber(val1) && isNumber(val2) && Math.abs(val1 - val2) < 0.00000000001) {
                return true;
            }

            return false;
        }
    }

    return true;
};

const ActiveView = () => {
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);

    const [activeView, setActiveView] = useState();
    const [isCreatingNewView, setIsCreatingNewView] = useState(false);
    const [isSharing, setIsSharing] = useState(false);

    const { id: projectId } = useParams();
    const location = useLocation();
    const isAdminPage = location.pathname.startsWith("/admin");

    const { getXRayedObjects, getVisibleObjects, getObjects, getViewer, getSnapshot } = useModelViewer();
    const { getSectionPlanes } = useSectionPlanes();

    const [viewer, setViewer] = useState({});

    const cameraChangeTracker = useRef();
    const objectsChangeTracker = useRef();

    const [hasModelChanges, setHasModelChanges] = useState(false);
    const [hasFilterChanges, setHasFilterChanges] = useState(false);
    const [hasCameraChanges, setHasCameraChanges] = useState(false);
    const [hasObjectChanges, setHasObjectChanges] = useState(false);
    const [hasSectionChanges, setHasSectionChanges] = useState(false);

    const isResetting = useRef(false);

    const { isAuthenticated } = useAuthentication();
    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        return () => {
            clearInterval(cameraChangeTracker.current);
            clearInterval(objectsChangeTracker.current);
        };
    }, []);

    useEffect(() => {
        const viewer = getViewer();
        if (!viewer) return;

        setViewer(viewer);
    }, [getViewer()]);

    useEffect(() => {
        const activeView = modelState.views.find((x) => x.isActive);
        if (!activeView) {
            setActiveView(null);
            return;
        }

        activeView.viewer = JSON.parse(activeView.viewerBlob);
        setActiveView(activeView);
    }, [modelState.views]);

    useEffect(() => {
        setHasModelChanges(false);
        setHasFilterChanges(false);
        setHasCameraChanges(false);
        setHasObjectChanges(false);
        setHasSectionChanges(false);

        isResetting.current = modelState.viewLoading;

        if (!activeView || isResetting.current) return;

        clearInterval(cameraChangeTracker.current);
        clearInterval(objectsChangeTracker.current);
        cameraChangeTracker.current = setInterval(() => checkCameraPosition(activeView.viewer?.camera ?? {}), 200);
        objectsChangeTracker.current = setInterval(checkObjects, 200);
    }, [activeView, modelState.viewLoading]);

    useEffect(() => {
        clearInterval(cameraChangeTracker.current);
        clearInterval(objectsChangeTracker.current);
        cameraChangeTracker.current = null;
        objectsChangeTracker.current = null;

        if (!isResetting.current) {
            if (!activeView || modelState.viewLoading) return;

            if (!cameraChangeTracker.current)
                cameraChangeTracker.current = setInterval(
                    () => checkCameraPosition(activeView.viewer?.camera ?? {}, isResetting.current),
                    200
                );

            if (!objectsChangeTracker.current) objectsChangeTracker.current = setInterval(checkObjects, 200);
        }
    }, [isResetting.current]);

    // Detect changes in active models
    useEffect(() => {
        if (!activeView || modelState.viewLoading) return;

        const activeModels = modelState.models.filter((x) => x.loaded).map((x) => x.id);
        const activeViewModels = activeView.viewModels.map((x) => x.id) || [];

        setHasModelChanges(!areEqual(activeModels, activeViewModels));
    }, [modelState.models, modelState.viewLoading]);

    // Detect changes in active filter(s)
    useEffect(() => {
        if (!activeView || modelState.viewLoading) return;

        const activeFilters = modelState.activeFilters.map((x) => x.sets.map((y) => y.id)).flat();
        const activeViewFilters = activeView.modelFilterSets.map((x) => x.id) || [];

        setHasFilterChanges(!areEqual(activeFilters, activeViewFilters));
    }, [modelState.activeFilters, modelState.activeViewFilters]);

    // Detect changes in camera position
    const checkCameraPosition = (storedCamera, isResetting) => {
        const cameraMemento = new CameraMemento();
        cameraMemento.saveCamera(viewer);

        const cameraChanged = !areEqualDeep(cameraMemento, storedCamera);

        if (isResetting) setHasCameraChanges(false);
        else setHasCameraChanges(cameraChanged);
    };

    // Detect changes in objects state
    const checkObjects = () => {
        const objectsMemento = {
            hidden: getObjects().filter((obj) => getVisibleObjects().indexOf(obj) === -1),
            xray: getXRayedObjects(),
        };

        const objectsChanged = !areEqualDeep(objectsMemento, activeView.viewer?.entities ?? {});

        setHasObjectChanges(objectsChanged);
    };

    // Detect changes in sections
    useEffect(() => {
        if (!activeView || modelState.viewLoading) return;

        const sections = getSectionPlanes().map((section) => {
            return {
                id: section.id,
                active: section.active,
                dir: section.dir,
                pos: section.pos,
                dist: section.dist,
            };
        });

        const sectionsChanged = !areEqualDeep(sections, activeView.viewer?.sections ?? {});

        setHasSectionChanges(sectionsChanged);
    }, [getSectionPlanes()]);

    const onCreateNewView = (data) => {
        setIsCreatingNewView(false);

        const baseOptions = {
            method: "POST",
            mode: "cors",
            headers: {},
        };

        const cameraMemento = new CameraMemento();
        cameraMemento.saveCamera(getViewer());

        const blob = JSON.stringify({
            camera: cameraMemento,
            entities: {
                hidden: getObjects().filter((obj) => getVisibleObjects().indexOf(obj) === -1),
                xray: getXRayedObjects(),
            },
            sections: getSectionPlanes().map((section) => {
                return {
                    id: section.id,
                    active: section.active,
                    dir: section.dir,
                    pos: section.pos,
                    dist: section.dist,
                };
            }),
        });

        const imageBlob = getSnapshot({
            format: "png",
            width: "300",
            height: "300",
        });
        const file = DataURIToBlob(imageBlob);

        let formData = new FormData();

        formData.append("Name", data.name);
        formData.append("Description", data.description);
        formData.append("ViewerBlob", blob);
        const models = modelState.models.filter((x) => x.loaded).map((x) => x.id);
        for (let i = 0; i < models.length; i++) formData.append("ModelIds", models[i]);
        if (modelState.activeFilter) {
            formData.append("FilterIds", modelState.activeFilter);
        }
        formData.append("ImageMimeType", "image/png");
        formData.append("File", file, "snapshot.png");

        fetch(`${config.api}api/v1/Projects/${projectId}/Views`, {
            ...baseOptions,
            body: formData,
        })
            .then(handleNewView)
            // .then((view) => inviteUsers(view, data))
            .catch((err) => console.warn("Failed to save view:", err));
    };

    const DataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(",");
        const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
        return new Blob([ia], { type: mimeString });
    };

    const handleNewView = async (response, data) => {
        const json = await response.json();
        if (!response.ok) throw new Error("Failed to save");

        modelDispatch({
            type: "ADD_VIEW",
            payload: { ...json, isActive: true, users: [] },
        });

        return json;
    };

    const onUpdateActiveView = () => {
        const baseOptions = {
            method: "PUT",
            mode: "cors",
            headers: {},
        };

        const cameraMemento = new CameraMemento();
        cameraMemento.saveCamera(viewer);

        const blob = JSON.stringify({
            camera: cameraMemento,
            entities: {
                hidden: getObjects().filter((obj) => getVisibleObjects().indexOf(obj) === -1),
                xray: getXRayedObjects(),
            },
            sections: getSectionPlanes().map((section) => {
                return {
                    id: section.id,
                    active: section.active,
                    dir: section.dir,
                    pos: section.pos,
                    dist: section.dist,
                };
            }),
        });

        isResetting.current = true;

        const imageBlob = getSnapshot({
            format: "png",
            width: "300",
            height: "300",
        });
        const file = DataURIToBlob(imageBlob);

        let formData = new FormData();

        formData.append("Name", activeView.name);
        formData.append("Description", activeView.description);
        formData.append("ViewerBlob", blob);
        const models = modelState.models.filter((x) => x.loaded).map((x) => x.id);
        for (let i = 0; i < models.length; i++) formData.append("ModelIds", models[i]);
        if (modelState.activeFilter) {
            formData.append("FilterIds", modelState.activeFilter);
        }
        formData.append("ImageMimeType", "image/png");
        formData.append("File", file, "snapshot.png");

        fetch(`${config.api}api/v1/Projects/${projectId}/Views/${activeView.id}`, {
            ...baseOptions,
            body: formData,
        })
            .then((response) => handleUpdatedView(response, blob))
            .catch((err) => console.warn("Failed to update view:", err));
    };

    const onResetView = () => {
        isResetting.current = true;

        clearInterval(cameraChangeTracker.current);
        clearInterval(objectsChangeTracker.current);

        modelDispatch({
            type: "SET_ACTIVE_VIEW",
            payload: activeView.id,
        });
    };

    const handleUpdatedView = (response, blob) => {
        if (!response.ok) return;

        modelDispatch({
            type: "UPDATE_VIEW",
            payload: {
                ...activeView,
                modelIds: modelState.models.filter((x) => x.loaded).map((x) => x.id),
                modelFilterSets: modelState.activeFilter ? [{ filterId: modelState.activeFilter }] : [],
                viewerBlob: blob,
                viewer: null,
            },
        });
    };

    // Rebuild tooltips with every re-render to make sure all tooltips are availble for conditionally rendered elements.
    ReactTooltip.rebuild();

    return (
        (activeView || modelState.loaded.length > 0) && (
            <div id="view-info" className={activeView ? "" : "no-line"}>
                {!isAuthenticated ? (
                    <div
                        style={{ width: "100%", cursor: "pointer" }}
                        data-for="active-view-tooltips"
                        data-tip={asText(document.data.UserFeature)}
                    >
                        <Button id="active-view-new-view-create-button" disabled>
                            {asText(document.data.CreateNewView)}
                        </Button>
                    </div>
                ) : activeView ? (
                    <Fragment>
                        <div style={{ marginRight: "8px", userSelect: "none" }}>
                            <h2>
                                {activeView.name}
                                {!modelState.isSharedProject &&
                                    (hasModelChanges ||
                                        hasFilterChanges ||
                                        hasCameraChanges ||
                                        hasObjectChanges ||
                                        hasSectionChanges) &&
                                    "*"}
                            </h2>
                            <p className="tag">{asText(document.data.ActiveView)}</p>
                        </div>
                        {(hasModelChanges ||
                            hasFilterChanges ||
                            hasCameraChanges ||
                            hasObjectChanges ||
                            hasSectionChanges) &&
                            !isResetting.current && (
                                <Fragment>
                                    <div
                                        data-for="active-view-tooltips"
                                        data-tip={asText(document.data.RefreshActiveView)}
                                        style={{ marginRight: "8px" }}
                                    >
                                        <Button
                                            id="active-view-reset-button"
                                            variant="tertiary"
                                            onClick={() => onResetView()}
                                        >
                                            <FontAwesomeIcon icon={faSync} size="lg" />
                                        </Button>
                                    </div>
                                    <div
                                        data-for="active-view-tooltips"
                                        data-tip={asText(document.data.UpdateActiveView)}
                                        style={{ marginRight: "8px" }}
                                    >
                                        <Button
                                            id="active-view-active-view-update-button"
                                            variant="tertiary"
                                            onClick={() => onUpdateActiveView()}
                                        >
                                            <FontAwesomeIcon icon={faEdit} size="lg" />
                                        </Button>
                                    </div>
                                </Fragment>
                            )}

                        {!isAdminPage && !modelState.isSharedProject && (
                            <div
                                data-for="active-view-tooltips"
                                data-tip={asText(document.data.ShareActiveView)}
                                style={{ marginRight: "8px" }}
                            >
                                <IconButton variant="tertiary" onClick={() => setIsSharing(true)}>
                                    <FontAwesomeIcon icon={faShareAlt} size="lg" />
                                </IconButton>
                            </div>
                        )}
                    </Fragment>
                ) : (
                    <Fragment>
                        <Button id="active-view-is-creating-new-view-button" onClick={() => setIsCreatingNewView(true)}>
                            {asText(document.data.CreateNewView)}
                        </Button>
                    </Fragment>
                )}

                <ReactTooltip
                    id={"active-view-tooltips"}
                    type="light"
                    effect="solid"
                    border={true}
                    borderColor={"#8c969b"}
                    place={"top"}
                    getContent={(dataTip) => <span>{dataTip}</span>}
                />

                {isCreatingNewView && (
                    <CreateView onSave={(data) => onCreateNewView(data)} onClose={() => setIsCreatingNewView(false)} />
                )}

                {isSharing && <ShareProject view={activeView} onClose={() => setIsSharing(false)} />}
            </div>
        )
    );
};

export default ActiveView;
