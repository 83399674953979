import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Panel, PanelHeader, PanelBody, PanelFooter } from "@buildwise/ui";
import { PrismicRichText } from "@prismicio/react";

import styles from "./Tooltip.module.css";

export const Tooltip = ({ continuous, index, isLastStep, step, primaryProps, skipProps, tooltipProps }) => {
    const [yOffset, setYOffset] = useState(0);
    const panelRef = useRef(null);

    useEffect(() => {
        if (!panelRef.current) return;

        const images = panelRef.current.getElementsByTagName("img");
        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            image.addEventListener("load", () => {
                image.setAttribute("width", image.width);
                image.setAttribute("height", image.height);

                window.scrollBy({ top: -1, left: 0, behavior: "smooth" });
                setTimeout(() => window.scrollBy({ top: 1, left: 0, behavior: "smooth" }), 0.1);
            });
        }

        if (step.target === "#viewer-canvas") {
            const y = panelRef.current.offsetHeight + 50;
            setYOffset(y);
        }
    }, [index]);

    const content = useMemo(
        () => step.content && <PrismicRichText field={step.content} linkResolver={() => "/"} />,
        [step.content]
    );

    return (
        <div {...tooltipProps}>
            <div ref={panelRef}>
                <Panel
                    style={{
                        minWidth: "400px",
                        maxWidth: "600px",
                        marginBottom: "0px",
                        transform: `translate(0px, ${yOffset}px)`,
                    }}
                >
                    {step.title && <PanelHeader>{step.title}</PanelHeader>}
                    <PanelBody className={styles.tooltip}>{content}</PanelBody>
                    <PanelFooter style={{ display: "flex" }}>
                        {!isLastStep && (
                            <Button variant="secondary" {...skipProps}>
                                {step.locale.skip}
                            </Button>
                        )}

                        <Button style={{ marginLeft: "auto" }} variant="primary" {...primaryProps}>
                            {continuous && !isLastStep ? step.locale.next : step.locale.close}
                        </Button>
                    </PanelFooter>
                </Panel>
            </div>
        </div>
    );
};
