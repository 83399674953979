import { createContext, useReducer } from "react";

const FilterContext = createContext();

const initialState = {
    filters: [],
    operands: {
        text: [],
        number: [],
        boolean: [],
    },
    filterAction: 0, // 0 = hide / 1 = Xray
};

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_FILTERS": {
            return { ...state, filters: action.payload };
        }

        case "SET_COLOR_FILTERS": {
            return { ...state, colorFilters: action.payload };
        }

        case "SET_OPERANDS": {
            const operands = { ...state.operands };
            for (let i = 0, len = action.payload.length; i < len; i++) {
                const operand = action.payload[i];
                const targets = operand.target.split(",").map((x) => x.trim().toLowerCase());
                for (let j = 0, targetLen = targets.length; j < targetLen; j++) {
                    const target = targets[j];
                    if (operands[target].includes(operand.symbol)) continue;
                    operands[target].push(operand.symbol);
                }
            }

            return {
                ...state,
                operands,
            };
        }

        case "SET_FILTER_ACTION": {
            return { ...state, filterAction: action.payload };
        }

        default:
            console.error(`Invalid action '${action.type}' supplied to FilterContext.`);
            return;
    }
};

const FilterContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <FilterContext.Provider value={{ state, dispatch }}>{props.children}</FilterContext.Provider>;
};

export { FilterContextProvider, FilterContext };
