import { useContext } from "react";
import { IfcViewerContext } from "../../../contexts/IfcViewerContextProvider";

const useSectionPlanes = () => {
    const { state, dispatch } = useContext(IfcViewerContext);
    const { sectionPlanes, plugins, viewer } = state;
    const { sectionPlanes: sectionPlanesPlugin } = plugins;

    const getSectionPlanes = () => {
        return sectionPlanes;
    };

    const createSectionPlane = (params = {}) => {
        if (!sectionPlanesPluginLoaded()) {
            return;
        }

        const { aabb, camera } = state.viewer.scene;
        const { selectedSurface } = state;

        const aabbCenter = [
            (aabb[3] - aabb[0]) / 2 + aabb[0],
            (aabb[4] - aabb[1]) / 2 + aabb[1],
            (aabb[5] - aabb[2]) / 2 + aabb[2],
        ];

        params.pos = params.pos || aabbCenter;

        if (selectedSurface && params.onSurface === true) {
            params.pos = selectedSurface.pos;
            params.dir = [
                selectedSurface.normal[0] * (camera.yUp ? -1 : 1),
                selectedSurface.normal[1] * (camera.yUp ? -1 : 1),
                selectedSurface.normal[2] * (camera.yUp ? -1 : 1),
            ];
        }

        const id =
            sectionPlanes.length > 0
                ? sectionPlanes[sectionPlanes.length - 1].id + 1
                : 1;
        const newSection = sectionPlanesPlugin.createSectionPlane({
            id,
            ...params,
        });

        if (params.enableControls !== false) {
            sectionPlanesPlugin.showControl(newSection.id);
        }

        dispatch({
            type: "ADD_SECTION_PLANE",
            payload: newSection,
        });
    };

    const flipSectionPlane = ({ id }) => {
        if (!sectionPlanesPluginLoaded()) {
            return;
        }

        const section = viewer.scene.sectionPlanes[id];
        const activeControls = sectionPlanesPlugin.getShownControl() === id;
        sectionPlanesPlugin.destroySectionPlane(id);

        const flippedSection = sectionPlanesPlugin.createSectionPlane({
            id: section.id,
            pos: section.pos,
            dir: [
                section.dir[0] * -1,
                section.dir[1] * -1,
                section.dir[2] * -1,
            ],
        });
        flippedSection.active = section.active;

        if (activeControls) {
            sectionPlanesPlugin.showControl(flippedSection.id);
        }

        const planes = [...sectionPlanes];
        const plane = planes.find((x) => x.id === section.id);
        plane.dir = flippedSection.dir;

        dispatch({
            type: "SET_SECTION_PLANES",
            payload: planes,
        });
    };

    const toggleSectionPlane = ({ id }) => {
        if (!sectionPlanesPluginLoaded()) {
            return;
        }

        const section = viewer.scene.sectionPlanes[id];
        section.active = !section.active;

        const planes = [...sectionPlanes];
        const plane = planes.find((x) => x.id === section.id);
        plane.active = section.active;

        dispatch({
            type: "SET_SECTION_PLANES",
            payload: planes,
        });
    };

    const getActiveSectionPlaneControl = () => {
        if (!sectionPlanesPluginLoaded()) {
            return;
        }

        return sectionPlanesPlugin.getShownControl();
    };

    const toggleSectionPlaneControl = ({ id }) => {
        if (!sectionPlanesPluginLoaded()) {
            return;
        }

        const activeControlId = sectionPlanesPlugin.getShownControl();
        sectionPlanesPlugin.hideControl();

        if (id !== activeControlId) {
            sectionPlanesPlugin.showControl(id);
        }

        dispatch({
            type: "REFRESH_SECTION_PLANES"
        });
    };

    const removeSectionPlane = ({ id }) => {
        if (!sectionPlanesPluginLoaded()) {
            return;
        }

        sectionPlanesPlugin.destroySectionPlane(id);

        dispatch({
            type: "REMOVE_SECTION_PLANE",
            payload: id,
        });
    };

    const sectionPlanesPluginLoaded = () => {
        if (sectionPlanesPlugin !== undefined && sectionPlanesPlugin !== null) {
            return true;
        }

        console.warn(
            "Attempted to interact with SectionPlanesPlugin but the IfcSectionPlanes component wasn't loaded."
        );

        return false;
    };

    return {
        getSectionPlanes,
        createSectionPlane,
        flipSectionPlane,
        toggleSectionPlane,
        getActiveSectionPlaneControl,
        toggleSectionPlaneControl,
        removeSectionPlane,
    };
};

export default useSectionPlanes;
