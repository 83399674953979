import { createContext, useReducer } from "react";

const AppContext = createContext();

const initialState = {
    // Entire App
    error: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_ERROR": {
            return {
                ...state,
                error: action.payload,
            };
        }

        default:
            console.error(`Invalid action '${action.type}' supplied to AppContext.`);
            return;
    }
};

const AppContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <AppContext.Provider value={{ state, dispatch }}>{props.children}</AppContext.Provider>;
};

export { AppContextProvider, AppContext };
