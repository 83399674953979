import { useContext, useEffect } from "react";
import { IfcViewerContext } from "../../contexts/IfcViewerContextProvider";
import { SectionPlanesPlugin } from "@xeokit/xeokit-sdk";

const IfcSectionPlanes = (props) => {
    const { state, dispatch } = useContext(IfcViewerContext);
    const { viewer, plugins } = state;
    const { sectionPlanes: sectionPlanesPlugin } = plugins;
    const { canvasId, overviewVisible } = props.cfg;

    // Setup the SectionPlanesPlugin if the viewer has been setup and there is no instance of SectionPlanesPlugin
    useEffect(() => {
        if (viewer === null || sectionPlanesPlugin !== null) {
            return;
        }

        if (canvasId === undefined || canvasId === null) {
            throw new Error("`IfcSectionPlanes` requires a canvas id to be set.");
        }

        dispatch({
            type: "SET_SECTION_PLANES_PLUGIN",
            payload: new SectionPlanesPlugin(viewer, {
                overviewCanvasId: canvasId,
                overviewVisible: overviewVisible,
            }),
        });
    }, [viewer]);

    return <canvas id={canvasId} style={{ display: overviewVisible !== true ? "none" : "block" }} />;
};

export default IfcSectionPlanes;
