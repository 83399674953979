import { useContext, useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Button, Content, ContentBody, ContentHeader, Spinner, useLanguage, usePrismic } from "@buildwise/ui";

import ProjectInfo from "../../components/ProjectInfo/ProjectInfo";

import { ModelViewerContext } from "../../contexts/ModelViewerContextProvider";

import { getDemoProject, Project } from "../../adapters/AdminAdapter/AdminAdapter";
import { config } from "../../_configuration/configuration";

const Manage = () => {
    const { dispatch: modelDispatch } = useContext(ModelViewerContext);

    const [demoProject, setDemoProject] = useState<Project | null>();
    const [isFetchingDemoProject, setIsFetchingDemoProject] = useState(true);

    const params = useParams();
    const navigate = useNavigate();
    const [document] = usePrismic("bimio_viewer");
    const { language } = useLanguage();

    useEffect(() => {
        modelDispatch({ type: "RESET" });

        getDemoProject().then((project) => {
            setDemoProject(project);
            setIsFetchingDemoProject(false);
        });
    }, []);

    const createDemoProject = () => {
        setIsFetchingDemoProject(true);

        const options = {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({ name: "Demo Project" }),
        };

        fetch(`${config.api}api/v1/Projects?admin=true`, options).then((response) => {
            getDemoProject().then((project) => {
                setDemoProject(project);
                setIsFetchingDemoProject(false);
            });
        });
    };

    return (
        <Content>
            <ContentHeader>
                <h1>{document!.data.manage_demo_project_title}</h1>
            </ContentHeader>
            <ContentBody>
                {isFetchingDemoProject ? (
                    <Spinner />
                ) : demoProject ? (
                    <ProjectInfo
                        project={demoProject}
                        onOpen={() =>
                            navigate(
                                generatePath(config.routes.adminProject[language], { ...params, id: demoProject.id })
                            )
                        }
                    />
                ) : (
                    <>
                        <p style={{ marginBottom: 20 }}>{document!.data.manage_demo_project_not_found}</p>
                        <Button id="manage-demo-project-create-button" onClick={() => createDemoProject()}>
                            {document!.data.manage_demo_project_create_button}
                        </Button>
                    </>
                )}
            </ContentBody>
        </Content>
    );
};

export default Manage;
