import { Fragment, useEffect, useContext, useState } from "react";
import { usePrismic } from "@buildwise/ui";
import { asText } from "@prismicio/helpers";

import ModelInfo from "./ModelInfo";
import ModelUpload from "./ModelUpload";

import { ModelViewerContext } from "../../../../contexts/ModelViewerContextProvider";

import { config } from "../../../../_configuration/configuration";
import { parseJSON } from "date-fns";

const Models = () => {
    const { state: modelState } = useContext(ModelViewerContext);
    const [models, setModels] = useState([]);

    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        let models = [...modelState.models];

        // Hide models that aren't shared through the view(s)
        // if (modelState.isSharedProject) {
        //     const modelIds = modelState.views
        //         .map((x) => x.viewModels.map((y) => y.id))
        //         .flat();

        //     models = modelState.models.filter((x) =>
        //         modelIds.some((y) => y === x.id)
        //     );
        // }

        setModels(
            models
                .map((x) => ({ ...x, uploadDate: parseJSON(x.uploadDate) }))
                .filter((m) => Boolean(m.name))
                .sort((a, b) => a.order - b.order || a.uploadDate - b.uploadDate)
        );
    }, [modelState.models, modelState.isSharedProject]);

    return (
        <Fragment>
            <div id="tab-content" className="no-header">
                {models.length > 0 ? (
                    models.map((model) => <ModelInfo key={model.order} model={model} />)
                ) : (
                    <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
                        <p style={{ margin: "auto" }}>{asText(document.data.model_list_empty)}</p>
                    </div>
                )}
            </div>

            {!modelState.isSharedProject && (
                <div id="bottom-menu">
                    <ModelUpload />
                </div>
            )}
        </Fragment>
    );
};

export default Models;
