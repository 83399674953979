export const localhost = {
    api: "https://toolsmodels.azurewebsites.net/",
    sentry: null,
    gtm: null,
    auth: {
        clientId: "256cd153-7731-434f-ac1e-e955e40f962b",
        authority: "https://login-staging.bbri.be/wtcbidencestaging.onmicrosoft.com/b2c_1a_signup_signin/",
        knownAuthorities: ["https://login-staging.bbri.be/"],
        redirectUri: "http://localhost:3000",
        postLogoutRedirectUri: "http://localhost:3000",
        cacheLocation: "localStorage",
        scopes: ["https://wtcbidencestaging.onmicrosoft.com/BIMio/BIMio.Access", "openid", "profile", "offline_access"],
    },
    feedback: "https://toolsmodels.azurewebsites.net/api/v1/Feedback",
};
