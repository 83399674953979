import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import * as Icon from "react-feather";
import { useParams } from "react-router";
import ReactTooltip from "react-tooltip";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeTree } from "react-vtree";
import {
    faEye,
    faEyeSlash,
    faLowVision,
    faClipboardList,
    faVectorSquare,
    faCog,
} from "@fortawesome/pro-regular-svg-icons";
import { Checkbox, IconButton, Select, Text, usePrismic } from "@buildwise/ui";
import useModelViewer from "../../Hooks/useModelViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faPaintBrush } from "@fortawesome/pro-duotone-svg-icons";
import { asText } from "@prismicio/helpers";

import { ModelViewerContext } from "../../../../contexts/ModelViewerContextProvider";
import { FilterContext } from "../../../../contexts/FilterContextProvider";

import { config } from "../../../../_configuration/configuration";

import styles from "./ModelTree.module.css";
import "../../../../styles/model-viewer.css";

const filterTree = (tree, text) => {
    if (!tree) return null;
    if (tree.children.length > 0) {
        const subtree = {
            ...tree,
            shouldOpen: true,
            children: tree.children.map((child) => filterTree(child, text)).filter((child) => !!child),
        };

        const result = subtree.children.length ? subtree : null;
        if (!result) {
            if (tree.name?.toLowerCase().includes(text?.toLowerCase()) || tree.id === text) {
                return { ...tree, shouldOpen: false };
            }
        }

        return result;
    }

    return tree.name?.toLowerCase().includes(text?.toLowerCase()) || tree.id === text
        ? { ...tree, shouldOpen: true }
        : null;
};

const findNode = (arr, id, toOpen) => {
    if (!arr) return false;

    const split = id.split("_");

    if (split.length !== 2) return false;

    const nodeId = split[1];
    const modelId = split[0];

    for (let i = 0; i < arr.length; i++) {
        const node = arr[i];
        const found = node.id === nodeId && node.modelId === modelId ? node : findNode(node.children, id, toOpen);

        if (found) {
            toOpen.push(node.isClassGroup ? node.id : `${node.modelId}_${node.id}`);
            return found;
        }
    }
};

const allChildrenInSet = (item, set) => {
    if (!item.children) return true;

    for (let i = 0; i < item.children.length; i++) {
        const child = item.children[i];
        const isVisible = set.find((x) => x === child.id);
        if (isVisible) return false;
        if (child.children && child.children.length > 0) if (!allChildrenInSet(child, set)) return false;
    }

    return true;
};

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideAlerter = (ref, onClickAway) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickAway();
            }
        };
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
};

/**
 * Component that alerts if you click outside of it
 */
const OutsideAlerter = (props) => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props.onClickAway);

    return <div ref={wrapperRef}>{props.children}</div>;
};

const ModelTree = () => {
    const { id: projectId } = useParams();

    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);
    const { state: filterState, dispatch: filterDispatch } = useContext(FilterContext);

    const [activeTree, setActiveTree] = useState("spatialStructureTrees");
    const [tree, setTree] = useState([]);

    const [filters, setFilters] = useState([]);
    const [colorFilters, setColorFilters] = useState([]);
    const [activeModelFilter, setActiveModelFilter] = useState(null);
    const [activeColorFilter, setActiveColorFilter] = useState(null);
    const [selectedModelFilter, setSelectedModelFilter] = useState([]);
    const [selectedColorFilter, setSelectedColorFilter] = useState([]);
    const [xrayOrHideStatus, setXrayOrHideStatus] = useState(false);

    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState("");
    const searchTimeout = useRef();

    const clickedElement = useRef(null);
    const [openElement, setOpenElement] = useState(null);
    const openElementRef = useRef(openElement);
    openElementRef.current = openElement;
    const shouldScroll = useRef(true);
    const treeRef = useRef();

    const [prismicDocument] = usePrismic("bimio_viewer");
    const [ifcDefDocument] = usePrismic("ifc_definitions");
    const ifcDefDocumentRef = useRef(ifcDefDocument);
    ifcDefDocumentRef.current = ifcDefDocument;
    const [, forceRender] = useState(0);

    const {
        getVisibleObjects,
        setVisibleObjects,
        getHighlightedObjects,
        setHighlightedObjects,
        getSelectedObjects,
        setSelectedObjects,
        getXRayedObjects,
        setXRayedObjects,
        zoomToFit,
    } = useModelViewer();

    const filteredRootNode = useCallback(
        filterTree(
            modelState[activeTree] || {
                children: [{ name: "", id: "", nestingLevel: 0, children: [], type: "empty" }],
            },
            filter
        ) ||
            (filter && {
                children: [
                    {
                        name: asText(prismicDocument.data.model_tree_search_no_matches),
                        children: [],
                        id: "",
                        nestingLevel: 0,
                        type: "error",
                    },
                ],
            }),
        [activeTree, modelState.spatialStructureTrees, modelState.mergedClassTree, modelState.selectedElement, filter]
    );

    const treeWalker = useCallback(
        function* treeWalker() {
            for (let i = 0; i < filteredRootNode.children.length; i++) {
                yield getNodeData(filteredRootNode.children[i], 0);
            }

            while (true) {
                const parent = yield;

                for (let i = 0; i < parent.node.children.length; i++) {
                    yield getNodeData(parent.node.children[i], parent.nestingLevel + 1);
                }
            }
        },

        [filteredRootNode, modelState.selectedElement, activeTree]
    );

    const getName = (name) => {
        if (!name) return name;
        if (!ifcDefDocumentRef.current || !ifcDefDocumentRef.current.data) return name;
        let obj = ifcDefDocumentRef.current.data[name];
        if (obj) {
            return obj + " (" + name + ")";
        }
        return name;
    };

    const getNodeData = useCallback(
        (node, nestingLevel) => ({
            data: {
                ...node,
                defaultHeight: 31,
                id: node.isClassGroup ? `${node.id}` : `${node.modelId}_${node.id}`,
                elementId: node.id,
                isLeaf: node.children.length === 0,
                isOpenByDefault: false,
                nestingLevel,
                renderers: {
                    item: renderTreeItem,
                    actions: renderActions,
                },
                selectedNode: modelState.selectedElement,
            },
            nestingLevel,
            node,
        }),
        [modelState.selectedElement]
    );

    useEffect(() => {
        setActiveModelFilter(modelState.activeFilter);
        setActiveColorFilter(modelState.activeColorFilter);
    }, []);

    useEffect(() => {
        if (!tree || tree.length === 0) return;
        if (modelState.selectedElement)
            scrollToNode(`${modelState.selectedElement.modelId}_${modelState.selectedElement.id}`); //findAndExpand(expandTree);

        clickedElement.current = null;

        forceRender((x) => x + 1);
    }, [tree, modelState.selectedElement]);

    useEffect(() => {
        setTree(modelState[activeTree]);
    }, [activeTree, modelState[activeTree]]);

    useEffect(() => {
        setFilters(filterState.filters);
    }, [filterState.filters]);

    useEffect(() => {
        setColorFilters(filterState.colorFilters);
    }, [filterState.colorFilters]);

    useEffect(() => {
        setXrayOrHideStatus(filterState.filterAction === 1);
    }, [filterState.filterAction]);

    useEffect(() => {
        if (modelState.activeFilter === activeModelFilter) return;
        setActiveModelFilter(modelState.activeFilter);
    }, [modelState.activeFilter]);

    useEffect(() => {
        if (modelState.activeColorFilter === activeColorFilter) return;
        setActiveColorFilter(modelState.activeColorFilter);
    }, [modelState.activeColorFilter]);

    useEffect(() => {
        if (!filters || filters.length === 0) return;
        if (!modelState.activeFilter) return;

        const activeFilter = filters
            .map((filter) => ({
                label: filter.isPublished
                    ? prismicDocument.data[filter.name] ?? `Missing Prismic Key '${filter.name}'`
                    : filter.name,
                value: filter.id,
            }))
            .find((x) => x.value === modelState.activeFilter);

        if (!activeFilter) return;
        applyFilter([activeFilter]);
        setSelectedModelFilter([activeFilter]);
    }, [filters, modelState.activeFilter]);

    useEffect(() => {
        if (!colorFilters || colorFilters.length === 0) return;
        if (!modelState.activeColorFilter) return;

        const activeColor = colorFilters
            .map((filter) => ({
                label: filter.isPublished
                    ? prismicDocument.data[filter.name] ?? `Missing Prismic Key '${filter.name}'`
                    : filter.name,
                value: filter.id,
            }))
            .find((x) => x.value === modelState.activeColorFilter);

        if (!activeColor) return;
        applyColorFilter([activeColor]);
        setSelectedColorFilter([activeColor]);
    }, [colorFilters, modelState.activeColorFilter]);

    useEffect(() => {
        if (!treeRef.current) return;

        const expand = {};
        if (filter.length > 0) {
            for (let i = 0; i < filteredRootNode.children.length; i++) {
                expand[
                    activeTree === "spatialStructureTrees"
                        ? `${filteredRootNode.children[i].modelId}_${filteredRootNode.children[i].id}`
                        : filteredRootNode.children[i].id
                ] = {
                    open: true,
                    subtreeCallback(node, ownerNode) {
                        node.isOpen = node.data.shouldOpen;
                    },
                };
            }
        }

        treeRef.current?.recomputeTree({
            refreshNodes: true,
            ...expand,
        });
    }, [filteredRootNode]);

    const scrollToNode = (id) => {
        const opennessStateChanges = [];
        const found = findNode(filteredRootNode.children, id, opennessStateChanges);
        if (!found) return;

        const stateChange = {};
        for (let i = 0; i < opennessStateChanges.length; i++) {
            stateChange[opennessStateChanges[i]] = true;
        }

        treeRef.current.recomputeTree({
            ...stateChange,
        });

        let run = 0;
        const t = setInterval(() => {
            treeRef.current.scrollToItem(id);
            if (run++ >= 5) clearInterval(t);
        }, 50);
    };

    const applyFilter = (filter) => {
        filter = filter[0];

        if (!filter) {
            modelDispatch({
                type: "FILTER_GUIDS",
                payload: null,
            });

            modelDispatch({
                type: "ACTIVE_FILTER",
                payload: null,
            });

            return;
        }

        const { value: id } = filter;
        if (modelState.activeFilter === id) return;

        const activeModels = modelState.loaded.map((m) => m.id);
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(activeModels),
        };

        fetch(`${config.api}api/v1/Projects/${projectId}/Filters/${id}/Apply`, options)
            .then((response) => response.json())
            .then((json) => {
                modelDispatch({
                    type: "FILTER_GUIDS",
                    payload: json,
                });

                if (modelState.activeFilter !== id)
                    modelDispatch({
                        type: "ACTIVE_FILTER",
                        payload: id,
                    });
            })
            .catch((err) => console.warn("Error attempting to apply filter:", err));
    };

    const applyColorFilter = (filter) => {
        filter = filter[0];

        if (!filter) {
            modelDispatch({
                type: "COLOR_FILTER_GUIDS",
                payload: null,
            });

            modelDispatch({
                type: "ACTIVE_COLOR_FILTER",
                payload: null,
            });

            return;
        }

        const { value: id } = filter;
        if (modelState.activeColorFilter === id) return;

        const activeModels = modelState.loaded.map((m) => m.id);
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(activeModels),
        };

        fetch(`${config.api}api/v1/Projects/${projectId}/ColorFilters/${id}/Apply`, options)
            .then((response) => response.json())
            .then((json) => {
                modelDispatch({
                    type: "COLOR_FILTER_GUIDS",
                    payload: json,
                });

                if (modelState.activeColorFilter !== id)
                    modelDispatch({
                        type: "ACTIVE_COLOR_FILTER",
                        payload: id,
                    });
            })
            .catch((err) => console.warn("Error attempting to apply color filter:", err));
    };

    const renderTreeItem = (item) => {
        const isBold = item.id === `${item.selectedNode?.modelId}_${item.selectedNode?.id}`;

        if (item.isModel || item.isClassGroup)
            return (
                <span className={isBold ? "bold-tree-item" : ""}>
                    {getName(item.name) || asText(prismicDocument.data.UnnamedType)}
                </span>
            );

        if (item.type === "error") {
            return <span id={"ifcviewer-tree-item-search-error"}>{item.name}</span>;
        }

        return (
            <span
                id={item.id}
                className={isBold ? "ifcviewer-tree-item bold-tree-item" : "ifcviewer-tree-item"}
                onClick={() => {
                    setSelectedObjects(getSelectedObjects(), false);
                    setSelectedObjects([item.elementId], true);
                    shouldScroll.current = false;
                    modelDispatch({
                        type: "CHANGE_SELECTED_ELEMENT",
                        payload: { id: item.elementId, modelId: item.modelId },
                    });
                }}
                onMouseEnter={() => {
                    setHighlightedObjects(getHighlightedObjects(), false);
                    setHighlightedObjects([item.elementId], true);
                }}
                onMouseLeave={() => setHighlightedObjects(getHighlightedObjects(), false)}
                onDoubleClick={() => {
                    zoomToFit([item.elementId]);
                }}
                data-tip={`${item.name || prismicDocument.data.UnnamedInstance}<br />${item.elementId}`}
            >
                {getName(item.name) || prismicDocument.data.UnnamedInstance}
            </span>
        );
    };

    const toggleElementVisibility = (item, bool) => {
        const toChange = [item.elementId];
        getAllChildren(item, toChange);
        setVisibleObjects(toChange, bool);
        forceRender((x) => x + 1);
    };

    const toggleElementXRayed = (item, bool) => {
        const toChange = [item.elementId];
        getAllChildren(item, toChange);
        setXRayedObjects(toChange, bool);
        forceRender((x) => x + 1);
    };

    const isolateElement = (item) => {
        const toIsolate = [item.elementId];
        getAllChildren(item, toIsolate);
        setVisibleObjects(getVisibleObjects(), false);
        setVisibleObjects(toIsolate, true);
        forceRender((x) => x + 1);
    };

    const getAllChildren = (item, set) => {
        if (item.children && item.children.length > 0) {
            for (let i = 0; i < item.children.length; i++) {
                const child = item.children[i];
                set.push(child.id);
                getAllChildren(child, set);
            }
        }
    };

    const renderActions = (item) => {
        if (item.type === "error") return null;

        let isVisible = getVisibleObjects().find((x) => x === item.elementId);
        if (!isVisible) {
            isVisible = !allChildrenInSet(item, getVisibleObjects());
        }

        let isXRayed = getXRayedObjects().find((x) => x === item.elementId);
        if (!isXRayed) {
            isXRayed = !allChildrenInSet(item, getXRayedObjects());
        }

        return (
            <Fragment>
                <div className="action-button-dropdown">
                    <div
                        className="action-button"
                        onClick={(e) => {
                            setTimeout(() => {
                                let bottom = e.clientY + 136;
                                if (bottom > window.innerHeight) {
                                    e.clientY -= 136;
                                }
                                setOpenElement({ item, mousePos: { x: e.clientX, y: e.clientY } });
                            }, 200); //feels weird when the dialog opens too fast
                        }}
                    >
                        <p>
                            {isVisible ? (
                                isXRayed ? (
                                    <FontAwesomeIcon icon={faLowVision} />
                                ) : (
                                    <FontAwesomeIcon icon={faEye} />
                                )
                            ) : (
                                <FontAwesomeIcon icon={faEyeSlash} />
                            )}
                        </p>
                    </div>
                    {item === openElementRef.current?.item
                        ? ReactDOM.createPortal(
                              <div
                                  style={{
                                      display: "block",
                                      position: "fixed",
                                      top: openElementRef.current.mousePos.y,
                                      left: openElementRef.current.mousePos.x,
                                  }}
                                  className="action-content"
                              >
                                  <OutsideAlerter
                                      onClickAway={() => {
                                          setOpenElement(null);
                                      }}
                                  >
                                      <ul>
                                          <li onClick={() => toggleElementVisibility(item, !isVisible)}>
                                              <p>
                                                  {isVisible ? (
                                                      <FontAwesomeIcon icon={faEyeSlash} />
                                                  ) : (
                                                      <FontAwesomeIcon icon={faEye} />
                                                  )}
                                                  {isVisible
                                                      ? asText(prismicDocument.data.HideElement)
                                                      : asText(prismicDocument.data.ShowElement)}
                                              </p>
                                          </li>
                                          <li onClick={() => toggleElementXRayed(item, !isXRayed)}>
                                              <p>
                                                  {!isXRayed ? (
                                                      <FontAwesomeIcon icon={faLowVision} />
                                                  ) : (
                                                      <FontAwesomeIcon icon={faEye} />
                                                  )}
                                                  {!isXRayed
                                                      ? asText(prismicDocument.data.XrayToggleOn)
                                                      : asText(prismicDocument.data.XrayToggleOff)}
                                              </p>
                                          </li>
                                          <li id="break" />
                                          <li onClick={() => isolateElement(item)}>
                                              <p>
                                                  <FontAwesomeIcon icon={faVectorSquare} />
                                                  {asText(prismicDocument.data.Isolate)}
                                              </p>
                                          </li>
                                      </ul>
                                  </OutsideAlerter>
                              </div>,
                              document.getElementById("portalholder")
                          )
                        : null}
                </div>
                <button
                    id="model-tree-element-properties-show-button"
                    onClick={(e) =>
                        modelDispatch({
                            type: "SHOW_ELEMENT_PROPERTIES",
                            payload: item,
                        })
                    }
                    className={
                        (item.elementId && item.elementId.indexOf("_Ifc") > -1) || item.isClass || item.isType
                            ? "disabled"
                            : ""
                    }
                >
                    <FontAwesomeIcon icon={faClipboardList} />
                </button>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <div id="tab-content-filters-holder">
                <div id="tab-content-filters" className="no-header">
                    <div style={{ marginRight: "16px", width: "100%" }}>
                        <div style={{ display: "flex" }}>
                            <FontAwesomeIcon
                                icon={faFilter}
                                style={{ marginRight: 15, marginTop: 12, fontSize: 25, color: "rgba(0, 135, 183, 1)" }}
                            />
                            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                <Select
                                    id="model-tree-filter-select"
                                    placeholder={asText(prismicDocument.data.SelectFilter)}
                                    options={filters.map((x) => ({
                                        label: x.isPublished
                                            ? prismicDocument.data[x.name] ?? `Missing Prismic Key '${x.name}'`
                                            : x.name,
                                        value: x.id,
                                    }))}
                                    selection={selectedModelFilter}
                                    onChangeSelection={(item) => applyFilter(item)}
                                    clearable
                                    className={styles.fixedWidthSelect}
                                />
                                <div style={{ height: "10px" }} />
                                <Checkbox
                                    label={asText(prismicDocument.data.XrayInsteadOfHideForFilters)}
                                    checked={xrayOrHideStatus}
                                    onChange={(e) =>
                                        filterDispatch({
                                            type: "SET_FILTER_ACTION",
                                            payload: e.currentTarget.checked ? 1 : 0,
                                        })
                                    }
                                    disabled={!activeModelFilter}
                                />
                            </div>
                        </div>
                    </div>
                    {!modelState.isSharedProject && (
                        <div style={{ marginTop: "5px" }}>
                            <IconButton
                                variant="primary"
                                onClick={() =>
                                    modelDispatch({
                                        type: "TOGGLE_FILTER_MODAL",
                                        payload: 0,
                                    })
                                }
                                disabled={modelState.loaded.length === 0}
                            >
                                <FontAwesomeIcon icon={faCog} />
                            </IconButton>
                        </div>
                    )}
                </div>
                <div style={{ borderTop: "1px solid rgba(0, 135, 183, 1)" }}></div>
                <div id="tab-content-filters" className="no-header">
                    <div style={{ marginRight: "16px", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <FontAwesomeIcon
                                icon={faPaintBrush}
                                style={{ marginRight: 15, fontSize: 25, color: "rgba(0, 135, 183, 1)" }}
                            />
                            <Select
                                id="model-tree-color-filter-select"
                                placeholder={asText(prismicDocument.data.model_tree_select_color_filter)}
                                options={
                                    colorFilters
                                        ? colorFilters.map((x) => ({
                                              label: x.isPublished
                                                  ? prismicDocument.data[x.name] ?? `Missing Prismic Key '${x.name}'`
                                                  : x.name,
                                              value: x.id,
                                          }))
                                        : []
                                }
                                selection={selectedColorFilter}
                                onChangeSelection={(item) => applyColorFilter(item)}
                                clearable
                                className={styles.fixedWidthSelect}
                            />
                        </div>
                        <div style={{ height: "15px" }} />
                    </div>
                    {!modelState.isSharedProject && (
                        <div style={{ marginTop: "5px" }}>
                            <IconButton
                                variant="primary"
                                onClick={() =>
                                    modelDispatch({
                                        type: "TOGGLE_FILTER_MODAL",
                                        payload: 1,
                                    })
                                }
                                disabled={modelState.loaded.length === 0}
                            >
                                <FontAwesomeIcon icon={faCog} />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
            <div id="tab-subtabs">
                <button
                    id="model-tree-spatial-structure-button"
                    className={activeTree === "spatialStructureTrees" ? "button secondary active" : "button secondary"}
                    onClick={() => setActiveTree("spatialStructureTrees")}
                >
                    {asText(prismicDocument.data.SpacialStructure)}
                </button>
                <button
                    id="model-tree-class-tree-button"
                    className={activeTree === "mergedClassTree" ? "button secondary active" : "button secondary"}
                    onClick={() => setActiveTree("mergedClassTree")}
                >
                    {asText(prismicDocument.data.Classes)}
                </button>
            </div>

            <div id="tab-content-search">
                <Text
                    type="search"
                    value={search}
                    placeholder={asText(prismicDocument.data.Search)}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        if (searchTimeout.current) clearTimeout(searchTimeout.current);

                        searchTimeout.current = setTimeout(() => setFilter(e.target.value), 500);
                    }}
                />
            </div>

            <div id="tab-content" style={{ overflowY: "unset" }}>
                <div id="model-tree-top"></div>
                <AutoSizer>
                    {({ height, width }) => (
                        <FixedSizeTree
                            className="model-tree"
                            treeWalker={treeWalker}
                            height={height - 20}
                            width={width + 6}
                            ref={treeRef}
                            itemSize={28}
                            overscanCount={5}
                            async={true}
                        >
                            {TreeNode}
                        </FixedSizeTree>
                    )}
                </AutoSizer>

                <ReactTooltip
                    html={true}
                    className={"tree-item-tooltip"}
                    delayShow={500}
                    delayHide={250}
                    place="right"
                    effect="solid"
                    overridePosition={({ top }) => ({ left: 420, top: top })}
                />
            </div>
        </Fragment>
    );
};

export default ModelTree;

const TreeNode = (props) => {
    const { data, isOpen, style, setOpen } = props;
    const { isLeaf, id, elementId, name, type, isClassGroup, nestingLevel, renderers } = data;

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [id]);

    return type === "empty" ? null : type === "error" ? (
        <div
            className="tree-item"
            style={{
                ...style,
                display: "flex",
                boxSizing: "border-box",
                padding: "3px 0",
                paddingLeft: `${nestingLevel * 8}px`,
                height: "unset",
            }}
        >
            <div
                className="tree-item-error-value"
                onClick={(e) => e.stopPropagation()}
                style={{ textOverflow: "unset", whiteSpace: "unset", margin: 5 }}
            >
                {renderers.item(data)}
            </div>
        </div>
    ) : (
        <div
            className="tree-item"
            style={{
                ...style,
                display: "flex",
                boxSizing: "border-box",
                padding: "3px 0",
                paddingLeft: `${nestingLevel * 8}px`,
            }}
            onClick={() => setOpen(!isOpen)}
            data-tip={isClassGroup ? "" : `${name || type}<br />${elementId}`}
        >
            <div className="tree-item-expander">
                {!isLeaf ? isOpen ? <Icon.ChevronDown /> : <Icon.ChevronRight /> : null}
            </div>
            <div className="tree-item-value" onClick={(e) => e.stopPropagation()}>
                {renderers.item(data)}
            </div>
            <div className="tree-item-actions" onClick={(e) => e.stopPropagation()}>
                {renderers.actions(data)}
            </div>
        </div>
    );
};
