import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Checkbox, useAuthentication, usePrismic } from "@buildwise/ui";
import useModelViewer from "../../Hooks/useModelViewer";
import { IfcViewerContext } from "../../../../contexts/IfcViewerContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEdit, faFileExport, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { asText } from "@prismicio/helpers";

import RenameModel from "../../Modals/RenameModel";
import Warning from "../../../../components/Warning";
import useSharedModelFunctions from "../../Hooks/useSharedModelFunctions";

import { ModelViewerContext } from "../../../../contexts/ModelViewerContextProvider";

import { config } from "../../../../_configuration/configuration";

const ModelInfo = (props) => {
    const { state: ifcState } = useContext(IfcViewerContext);
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);

    const [active, setActive] = useState(false);
    const [isRenaming, setIsRenaming] = useState(false);

    const [warning, setWarning] = useState({ visible: false });
    const statusPolling = useRef(null);
    const conversionPolling = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const { startLoadingAnimation, stopLoadingAnimation } = useModelViewer();
    const { loadModel } = useSharedModelFunctions();

    const { id: projectId } = useParams();
    const { isAuthenticated } = useAuthentication();
    const [document] = usePrismic(config.prismic.documentType);

    const { id, name, conversionStatus, upload, conversionProgress } = props.model;

    useEffect(() => {
        return () => {
            clearInterval(statusPolling.current);
            clearInterval(conversionPolling.current);
        };
    }, []);

    useEffect(() => {
        const isFetching = modelState.fetching.find((x) => x.projectId === projectId && x.id === id);
        setIsLoading(isFetching);
        if (isFetching) setActive(true);
    }, [modelState.fetching]);

    useEffect(() => {
        const loaded = modelState.loaded.some((x) => x.id === id);
        const isFetching = modelState.fetching.find((x) => x.projectId === projectId && x.id === id);

        if (loaded && isLoading) setIsLoading(false);
        else if (!loaded && isLoading) setIsLoading(true);
        else if (!isFetching) setActive(loaded);
    }, [props.model, isLoading]);

    useEffect(() => {
        if (isAuthenticated) return;
        if (modelState.demoModelsLoadedOnce) return;

        toggleLoadedStatus();
        modelDispatch({ type: "DEMO_MODELS_LOADED" });
    }, []);

    useEffect(() => {
        const activeView = modelState.views.find((x) => x.isActive);
        if (!activeView) return;

        if (activeView.viewModels && activeView.viewModels.some((x) => x.id === id) && !active) {
            setIsLoading(true);
        }
    }, [modelState.views]);

    useEffect(() => {
        switch (props.model.conversionStatus) {
            case "Scheduled":
                if (statusPolling.current === null) {
                    statusPolling.current = setInterval(() => checkIfStillPending(), 2000);
                }

                return;

            case "Converting":
            case "UploadToSql":
                if (conversionPolling.current === null) {
                    clearInterval(statusPolling.current);
                    conversionPolling.current = setInterval(() => getConversionProgress(), 2000);
                }

                return;

            case "Completed":
            case "Failed":
                clearInterval(statusPolling.current);
                clearInterval(conversionPolling.current);

                return;

            default:
                return;
        }
    }, [props.model.conversionStatus]);

    const checkIfStillPending = () => {
        fetch(`${config.api}api/v1/Projects/${projectId}/Models/${id}`, {
            method: "GET",
            mode: "cors",
            headers: {},
        })
            .then((response) => response.json())
            .then((json) => modelDispatch({ type: "UPDATE_MODEL", payload: json }))
            .catch((err) => console.warn(err));
    };

    const getConversionProgress = () => {
        fetch(`${config.api}api/v1/Projects/${projectId}/Models/${id}`, {
            method: "GET",
            mode: "cors",
            headers: {},
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.totalUploadPercentage <= 100) {
                    modelDispatch({
                        type: "UPDATE_MODEL_CONVERSION_PROGRESS",
                        payload: { id, progress: data.totalUploadPercentage },
                    });

                    if (data.totalUploadPercentage === 100 || data.conversionStatus === "Failed") checkIfStillPending();
                } else {
                    checkIfStillPending();
                }
            })
            .catch((err) => console.warn(err));
    };

    const startLoadingModel = async () => {
        startLoadingAnimation();
        loadModel(projectId, id, name);
    };

    const onDownloadModel = () => {
        const options = {
            method: "GET",
            headers: {},
        };

        fetch(`${config.api}api/v1/Projects/${projectId}/Models/${id}/Download`, options)
            .then((response) => triggerDownload(response, `${name}.ifc`))
            .catch((err) => console.warn("Error attempting to download model:", err));
    };

    const onExportData = () => {
        modelDispatch({ type: "SET_EXPORT", payload: { models: [id], isView: false } });
    };

    const triggerDownload = async (response, filename) => {
        const data = await response.blob();
        const url = window.URL.createObjectURL(data);
        const elemA = window.document.createElement("a");
        elemA.href = url;
        elemA.download = filename;
        elemA.click();

        window.URL.revokeObjectURL(url);
    };

    const deleteModel = (confirmed = false, result = false) => {
        if (!confirmed) {
            setWarning({
                visible: true,
                prompt: true,
                title: asText(document.data.RemoveModelQuestion),
                text: asText(document.data.RemoveNameDesc).replace("{{name}}", name),
                callback: (bool) => deleteModel(true, bool),
            });

            return;
        }

        setWarning({ visible: false });

        if (!result) return;

        let options = {
            method: "DELETE",
            headers: {},
        };

        fetch(`${config.api}api/v1/Projects/${projectId}/Models/${id}`, options)
            .then((response) => {
                if (response.ok) {
                    modelDispatch({ type: "UNLOAD_MODEL", payload: id });
                    modelDispatch({ type: "REMOVE_MODEL", payload: id });

                    options = {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    };

                    fetch(`${config.api}api/v1/Projects/${projectId}/Views`, options)
                        .then((response) => response.json())
                        .then(parseViews)
                        .catch((e) => console.warn("Failed to obtain views:", e));
                }
            })
            .catch((err) => console.warn(err));
    };

    const parseViews = (views) => {
        const options = {
            method: "GET",
            headers: {},
        };

        fetch(`${config.api}api/v1/Projects/${projectId}/SharedViews`, options)
            .then((response) => response.json())
            .then((sharedViews) => {
                const allViews = views.map((x) => {
                    return { ...x, users: [] };
                });

                const availableViews = [];

                for (let i = 0; i < sharedViews.length; i++) {
                    const sharedView = sharedViews[i];
                    const view = allViews.find((x) => x.id === sharedView.viewId);

                    if (!view) continue;

                    for (let j = 0; j < sharedView.invitees.length; j++) {
                        const invitee = sharedView.invitees[j];
                        availableViews.push(sharedView.viewId);
                        view.users.push(invitee.email);
                    }
                }

                const visibleViews = modelState.isSharedProject
                    ? allViews.filter((x) => availableViews.some((y) => y === x.id))
                    : allViews;

                modelDispatch({ type: "SET_VIEWS", payload: visibleViews });
                modelDispatch({
                    type: "SET_PROJECT_SHARED",
                    payload: { id: projectId, status: sharedViews.length > 0 },
                });
            })
            .catch((e) => console.warn("Failed to obtain views:", e));
    };

    const cancelUpload = () => {
        upload.cancel();
        modelDispatch({ type: "REMOVE_MODEL", payload: id });
    };

    const toggleLoadedStatus = () => {
        if (isLoading) return;

        const newStatus = !active;
        setActive(newStatus);
        if (newStatus) {
            startLoadingModel();
            setIsLoading(true);
        } else {
            const loadingModel = ifcState.models.find((x) => x.id === id);
            if (loadingModel) {
                stopLoadingAnimation();
            }

            setIsLoading(false);
            modelDispatch({ type: "UNLOAD_MODEL", payload: id });
        }
    };

    return (
        <div className={active ? "infobox active" : conversionStatus === "Failed" ? "infobox failed" : "infobox"}>
            <div className="infobox-header">
                <h3>{name}</h3>
                {!modelState.isSharedProject && isAuthenticated && (
                    <div className="infobox-actions">
                        {conversionStatus === "Completed" && (
                            <Fragment>
                                <FontAwesomeIcon
                                    id="model-info-action-rename"
                                    className="action-button"
                                    icon={faEdit}
                                    onClick={() => setIsRenaming(true)}
                                    data-for="action-button"
                                    data-tip={asText(document.data.RenameModel)}
                                />

                                <FontAwesomeIcon
                                    id="model-info-action-export-data"
                                    className="action-button"
                                    icon={faFileExport}
                                    onClick={() => onExportData()}
                                    data-for="action-button"
                                    data-tip={asText(document.data.ExportModelData)}
                                />

                                <FontAwesomeIcon
                                    id="model-info-action-download"
                                    className="action-button"
                                    icon={faDownload}
                                    onClick={() => onDownloadModel()}
                                    data-for="action-button"
                                    data-tip={asText(document.data.DownloadModel)}
                                    size="2x"
                                />
                            </Fragment>
                        )}

                        <FontAwesomeIcon
                            id="model-info-action-delete"
                            className="action-button"
                            icon={faTrashAlt}
                            onClick={() => deleteModel()}
                            data-for="action-button"
                            data-tip={asText(document.data.DeleteModel)}
                        />

                        <ReactTooltip
                            id={"action-button"}
                            type="light"
                            effect="solid"
                            border={true}
                            borderColor={"#8c969b"}
                            place={"top"}
                            getContent={(dataTip) => <span>{dataTip}</span>}
                        />
                    </div>
                )}
            </div>

            {conversionStatus === "Completed" && (
                <Fragment>
                    <div className={active ? "activation-toggle active" : "activation-toggle"}>
                        <Checkbox
                            label={
                                active
                                    ? isLoading
                                        ? asText(document.data.BusyLoading)
                                        : asText(document.data.Active)
                                    : asText(document.data.Activate)
                            }
                            checked={active}
                            indeterminate={isLoading ? "indeterminate" : ""}
                            onChange={() => toggleLoadedStatus()}
                            disabled={isLoading}
                        />
                    </div>

                    {conversionProgress < 100 && (
                        <Fragment>
                            <p>{asText(document.data.BusyExtractingData)}</p>

                            <div className="progress-bar">
                                <span
                                    className="progress-bar-fill"
                                    style={{
                                        width: `${conversionProgress}%`,
                                    }}
                                ></span>
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            )}

            {conversionStatus !== "Completed" && (
                <div style={{ marginTop: "16px" }}>
                    {upload && (
                        <Fragment>
                            <p>
                                {asText(document.data.BusyUploading)}{" "}
                                <span style={{ cursor: "pointer" }} onClick={() => cancelUpload()}>
                                    [{asText(document.data.Cancel)}]
                                </span>
                            </p>

                            <div className="progress-bar">
                                <span
                                    className="progress-bar-fill"
                                    style={{
                                        width: `${upload.progress.toFixed(2)}%`,
                                    }}
                                ></span>
                            </div>
                        </Fragment>
                    )}

                    {(conversionStatus === "Scheduled" ||
                        (conversionStatus === "Converting" && conversionProgress === 0)) && (
                        <p>{asText(document.data.WaitingForConversion)}</p>
                    )}

                    {(conversionStatus === "Converting" || conversionStatus === "UploadToSql") &&
                        conversionProgress > 0 &&
                        conversionProgress < 100 && (
                            <Fragment>
                                <p>{asText(document.data.BusyConverting)}</p>

                                <div className="progress-bar">
                                    <span
                                        className="progress-bar-fill"
                                        style={{
                                            width: `${conversionProgress}%`,
                                        }}
                                    ></span>
                                </div>
                            </Fragment>
                        )}

                    {(conversionStatus === "Converting" || conversionStatus === "UploadToSql") &&
                        conversionProgress === 100 && <p>{asText(document.data.FinalizingConversion)}</p>}

                    {conversionStatus === "Failed" && <p>{asText(document.data.ConversionFailed)}</p>}
                </div>
            )}

            {warning.visible && (
                <Warning promp={warning.prompt} title={warning.title} text={warning.text} callBack={warning.callback} />
            )}

            {isRenaming && <RenameModel model={props.model} onClose={() => setIsRenaming(false)} />}
        </div>
    );
};

export default ModelInfo;
