import uniq from "lodash";

interface UserState {
    ProjectsData: ProjectState[];
}

interface ProjectState {
    projectId: string;
    activeModels: string[];
    activeFilter: string;
    activeView: number;
}

export const getProjectState = (projectId: string): ProjectState => {
    return (
        getUserState().ProjectsData.find((x) => x.projectId === projectId) ?? ({ projectId: projectId } as ProjectState)
    );
};

export const saveProjectState = (projectId: string, state: ProjectState): void => {
    const userState = getUserState();
    state.activeModels = uniq(state.activeModels).value();
    userState.ProjectsData = userState.ProjectsData.filter((x) => x.projectId !== projectId);
    userState.ProjectsData.push(state);

    localStorage.setItem("user-state", JSON.stringify(userState));
};

const getUserState = (): UserState => {
    const storageData = localStorage.getItem("user-state");
    if (!storageData) {
        const newUserState = { ProjectsData: [] } as UserState;
        localStorage.setItem("user-state", JSON.stringify(newUserState));

        return newUserState;
    }

    return JSON.parse(storageData) as UserState;
};
