import { useContext, useEffect, useRef, useState } from "react";
import { Link, generatePath, useLocation, useNavigate, useParams, Outlet } from "react-router-dom";
import Helmet from "react-helmet";
import { Layers, Plus } from "react-feather";
import ReactTooltip from "react-tooltip";

import {
    ApplicationTitle,
    ApplicationMenuItem,
    Breadcrumb,
    Header,
    Page,
    Spinner,
    useAuthentication,
    useLanguage,
    usePrismic,
} from "@buildwise/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs } from "@fortawesome/pro-duotone-svg-icons";
import { asText } from "@prismicio/helpers";

import ErrorBoundary from "./components/ErrorBoundary";
import PopupProject from "./components/PopupProject";
import Version from "./components/Version";

import { FilterContext } from "./contexts/FilterContextProvider";
import { ModelViewerContext } from "./contexts/ModelViewerContextProvider";

import { config } from "./_configuration/configuration";

import logo from "./assets/logo.svg";

const getRoutePath = (location, params) => {
    const { pathname } = location;

    if (!Object.keys(params).length) {
        return pathname;
    }

    let path = pathname;
    Object.entries(params).forEach(([paramName, paramValue]) => {
        if (paramValue) {
            path = path.replace(paramValue, `:${paramName}`);
        }
    });
    return path;
};

const getLanguage = (locale) => {
    return locale.split("-")[0];
};

const App = () => {
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext) || {};
    const { dispatch: filterDispatch } = useContext(FilterContext);

    const [loading, setLoading] = useState(true);
    const [routesMap, setRoutesMap] = useState(null);
    const [newProject, setNewProject] = useState({ visible: false });
    const [isSavingProject, setIsSavingProject] = useState(false);
    const [nameExists, setNameExists] = useState(false);
    const routeLanguageRef = useRef();
    const projectsRef = useRef();

    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const { isAuthenticated, isAdmin, user } = useAuthentication();
    const { language: locale, setLanguage } = useLanguage();
    const [document, { state: prismicState }] = usePrismic(config.prismic.documentType);


    useEffect(() => {
        ReactTooltip.rebuild();
    });

    useEffect(() => {
        const map = { routes: {}, languages: {} };
        for (let i = 0; i < Object.keys(config.routes).length; i++) {
            const key = Object.keys(config.routes)[i];
            const route = config.routes[key];

            map.routes[route["nl-be"]] = route["fr-be"];
            map.routes[route["fr-be"]] = route["nl-be"];

            if (!map.languages[route["nl-be"]]) map.languages[route["nl-be"]] = [];
            map.languages[route["nl-be"]].push("nl-be");

            if (!map.languages[route["fr-be"]]) map.languages[route["fr-be"]] = [];
            map.languages[route["fr-be"]].push("fr-be");
        }

        setRoutesMap(map);
    }, []);

    useEffect(() => {
        if (!Boolean(params.lang)) {
            const storedLanguage = window.localStorage.getItem("locale") ?? "nl-be";
            window.localStorage.setItem("locale", storedLanguage);
            setLanguage(storedLanguage);
            navigate(`/${locale.split("-")[0]}`);
            routeLanguageRef.current = locale.split("-")[0];

            return;
        }
    }, [params]);

    useEffect(() => {
        if (!params.lang) return;
        if (routeLanguageRef.current === params.lang) return;

        const storedLanguage = window.localStorage.getItem("locale") ?? "nl-be";
        const currentRoute = getRoutePath(location, params);
        const targetLanguage = `${params.lang}-be`;

        if (["nl-be", "fr-be"].indexOf(targetLanguage) === -1) {
            navigate(generatePath(currentRoute, { ...params, lang: storedLanguage.split("-")[0] }));
            return;
        }

        routeLanguageRef.current = params.lang;
        setLanguage(targetLanguage);
    }, [params, routesMap]);

    useEffect(() => {
        if (!routesMap) return;

        const targetLang = getLanguage(locale);
        const currentRoute = getRoutePath(location, params);
        let target;
        if (currentRoute === "/") {
            target = `/${targetLang}`;
        } else {
            let targetRoute = currentRoute;
            const currentRouteLang = routesMap.languages[currentRoute];
            if (!currentRouteLang.includes(locale)) targetRoute = routesMap.routes[currentRoute];
            target = generatePath(targetRoute, { ...params, lang: targetLang });
        }

        window.localStorage.setItem("locale", locale);
        navigate(target);
    }, [locale, routesMap]);

    const saveNewProject = (body) => {
        const createProjectOptions = {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(body.projectBody),
        };

        setIsSavingProject(true);
        setNameExists(false);
        projectsRef.current = [];

        fetch(`${config.api}api/v1/Projects`, createProjectOptions).then((response) => {
            if (!response.ok) {
                setIsSavingProject(false);
                setNameExists(true);
                return;
            }

            return response.json().then((data) => {
                const projects = [...modelState.projects];
                projects.push(data);
                projectsRef.current = projects;

                setIsSavingProject(false);
                setNewProject(false);

                modelDispatch({ type: "SET_PROJECTS", payload: projectsRef.current });
                modelDispatch({ type: "RESET" });

                navigate(
                    generatePath(config.routes.project[locale], {
                        ...params,
                        id: data.id,
                    })
                );
            });
        });
    };

    useEffect(() => {
        setLoading(true);

        const options = {
            headers: {
                "Content-Type": "application/json",
            },
            method: "GET",
        };

        const promises = [];

        promises.push(
            fetch(`${config.api}api/v1/Projects`, options)
                .then(handleProjectsList)
                .catch((err) => console.warn("Error attempting to get a list of model viewer projects:", err))
        );

        promises.push(
            fetch(`${config.api}api/v1/Filters/Operators/Number`, options)
                .then((response) => response.json())
                .then((json) => {
                    filterDispatch({
                        type: "SET_OPERANDS",
                        payload: json,
                    });
                })
                .catch((e) => console.warn("Failed to get number operands:", e))
        );

        promises.push(
            fetch(`${config.api}api/v1/Filters/Operators/Text`, options)
                .then((response) => response.json())
                .then((json) => {
                    filterDispatch({
                        type: "SET_OPERANDS",
                        payload: json,
                    });
                })
                .catch((e) => console.warn("Failed to get text operands:", e))
        );

        promises.push(
            fetch(`${config.api}api/v1/Filters/Operators/Boolean`, options)
                .then((response) => response.json())
                .then((json) => {
                    filterDispatch({
                        type: "SET_OPERANDS",
                        payload: json,
                    });
                })
                .catch((e) => console.warn("Failed to get boolean operands:", e))
        );

        if (isAuthenticated) {
            promises.push(
                fetch(`${config.api}api/v1/Projects/SharedWithMe`, options)
                    .then(handleSharedProjectsList)
                    .catch((err) => console.warn("Error attempting to get a list of model viewer projects:", err))
            );
        }

        Promise.all(promises).then(() => setLoading(false));
    }, [isAuthenticated, user]);

    const handleProjectsList = async (response) => {
        if (response.ok) {
            const json = await response.json();
            const projectsList = json
                .map((x) => {
                    x.creationDate = new Date(x.creationDate);

                    return x;
                })
                .sort((a, b) => a.creationDate - b.creationDate);

            modelDispatch({
                type: "SET_PROJECTS",
                payload: projectsList,
            });

            return { done: true };
        }
    };

    const handleSharedProjectsList = async (response) => {
        if (response.ok) {
            const json = await response.json();
            modelDispatch({
                type: "SET_SHARED_PROJECTS",
                payload: json,
            });
        }
    };

    if (prismicState !== "loaded") return null;

    return (
        <Page feedback>
            <Helmet>
                <html lang={locale.split("-")[0]}></html>
                <title>{document.data.application_title}</title>
            </Helmet>

            <Header homeAction={() => navigate(generatePath(config.routes.landing[locale], { ...params }))}>
                <ApplicationTitle icon={<img src={logo} alt="BIMio" />} />
                <ApplicationMenuItem
                    id={"app-nav-my-projects"}
                    icon={<Layers />}
                    label={asText(document.data.MyProjects)}
                    onClick={() =>
                        isAuthenticated ? navigate(generatePath(config.routes.landing[locale], { ...params })) : null
                    }
                    className={isAuthenticated ? null : "transparent"}
                    data-for={"tooltip"}
                    data-tip={document.data.navigation_item_disabled_user_feature}
                    data-tip-disable={isAuthenticated}
                />

                <ApplicationMenuItem
                    id={"app-nav-new-project"}
                    icon={<Plus />}
                    label={asText(document.data.NewProject)}
                    onClick={() =>
                        isAuthenticated &&
                        setNewProject({
                            visible: true,
                            type: "Models",
                        })
                    }
                    className={isAuthenticated || location.pathname.indexOf("admin") > -1 ? null : "transparent"}
                    data-for="tooltip"
                    data-tip={document.data.navigation_item_disabled_user_feature}
                    data-tip-disable={isAuthenticated}
                />

                {isAuthenticated && isAdmin && (
                    <ApplicationMenuItem
                        id={"app-nav-admin"}
                        icon={<FontAwesomeIcon icon={faCogs} />}
                        label={asText(document.data.application_navigation_administration_area)}
                        onClick={() =>
                            isAuthenticated ? navigate(generatePath(config.routes.admin[locale], { ...params })) : null
                        }
                    />
                )}
            </Header>

            <Breadcrumb>
                {isAuthenticated ? (
                    <Link id="breadcrumb-app-home" to={`/${getLanguage(locale)}`}>
                        {asText(document.data.MyProjects)}
                    </Link>
                ) : (
                    <Link id="breadcrumb-app-home" to={`/${getLanguage(locale)}`}>
                        BIMio
                    </Link>
                )}
            </Breadcrumb>

            <ErrorBoundary>
                {loading ? <Spinner /> : <Outlet />}
                <Version />
            </ErrorBoundary>

            {isAuthenticated && newProject.visible && (
                <PopupProject
                    visible={newProject.visible}
                    title={asText(document.data.NewProject)}
                    onClose={() => setNewProject({ visible: false })}
                    onSave={saveNewProject}
                    isSaving={isSavingProject}
                    nameExists={nameExists}
                />
            )}

            <ReactTooltip
                id={"tooltip"}
                type="light"
                effect="solid"
                border={true}
                borderColor={"#8c969b"}
                place={"top"}
                getContent={(dataTip) => <span>{dataTip}</span>}
            />

            <div id="portalholder"></div>
            
        </Page>
    );
};

export default App;
