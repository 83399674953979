import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Text, usePrismic } from "@buildwise/ui";
import { asText } from "@prismicio/helpers";

import { ModelViewerContext } from "../../../contexts/ModelViewerContextProvider";

import { config } from "../../../_configuration/configuration";

const RenameModel = (props) => {
    const { dispatch: modelDispatch } = useContext(ModelViewerContext);
    const [canSave, setCanSave] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [name, setName] = useState("");

    const { id: projectId } = useParams();
    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        setName(props.model.name);
    }, [props]);

    useEffect(() => {
        setCanSave(name.trim() !== "" && !isSaving);
    }, [name, isSaving]);

    const renameModel = () => {
        setIsSaving(true);

        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ name: name }),
        };

        fetch(`${config.api}api/v1/Projects/${projectId}/Models/${props.model.id}`, options)
            .then((response) => {
                setIsSaving(false);
                if (response.ok) {
                    const model = { ...props.model };
                    model.name = name;
                    modelDispatch({ type: "UPDATE_MODEL", payload: model });
                    props.onClose();
                }
            })
            .catch((err) => console.warn("Error attempting to rename model:", err));
    };

    return (
        <Modal size="small" onClose={() => props.onClose()} open={true} backdrop>
            <ModalHeader closeButton>
                <ModalTitle>{asText(document.data.EditName).replace("{{name}}", props.model.name)}</ModalTitle>
            </ModalHeader>

            <ModalBody>
                <div>
                    <strong style={{ margin: "0 0 8px 16px" }}>{asText(document.data.ModelName)}</strong>
                    <Text
                        required={name.trim().length === 0}
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
            </ModalBody>

            <ModalFooter>
                <Button id="rename-model-cancel-button" variant="tertiary" onClick={() => props.onClose()}>
                    {asText(document.data.Cancel)}
                </Button>
                <Button id="rename-model-rename-button" onClick={() => renameModel()} disabled={!canSave}>
                    {isSaving ? asText(document.data.BusySaving) : asText(document.data.Save)}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default RenameModel;
