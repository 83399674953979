import { Fragment, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { Upload } from "react-feather";
import ReactTooltip from "react-tooltip";
import http from "axios";
import { Button, useAuthentication, usePrismic } from "@buildwise/ui";
import { asText } from "@prismicio/helpers";

import { ModelViewerContext } from "../../../../contexts/ModelViewerContextProvider";

import { config } from "../../../../_configuration/configuration";

const ModelUpload = () => {
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);
    const modelUploadRef = useRef();

    const { id: projectId } = useParams();
    const { instance, isAuthenticated } = useAuthentication();
    const [document] = usePrismic(config.prismic.documentType);

    const getAccessToken = async () => {
        const token = await instance.acquireTokenSilent({
            scopes: config.auth.scopes,
            account: instance.getActiveAccount(),
        });

        return token.accessToken;
    };

    const handleFileUpload = () => {
        const { models } = modelState;
        const { files } = modelUploadRef.current;

        for (let i = 0; i < files.length; i++) {
            const CancelToken = http.CancelToken;
            const source = CancelToken.source();
            const sortedModels = models.sort((a, b) => a.order - b.order);
            const filename = files[i].name.substring(0, files[i].name.lastIndexOf(".")) || files[i].name;
            const order = sortedModels.length > 0 ? sortedModels[sortedModels.length - 1].order + 1 + i : 1 + i;
            const formData = new FormData();
            formData.append("Name", filename);
            formData.append("File", files[i]);
            formData.append("Order", order);

            const model = {
                id: `${Date.now()}-${i}`,
                name: filename,
                conversionStatus: "UPLOADING",
                order: order,
                upload: {
                    progress: 0,
                    cancel: () => source.cancel("Operation canceled by the user."),
                },
            };

            modelDispatch({ type: "UPLOAD_MODEL", payload: model });

            getAccessToken().then((token) => {
                http.post(`${config.api}api/v1/Projects/${projectId}/Models`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    onUploadProgress: (e) => {
                        modelDispatch({
                            type: "UPDATE_UPLOAD_PROGRESS",
                            payload: { id: model.id, uploadEvent: e },
                        });
                    },
                    cancelToken: source.token,
                })
                    .then((response) =>
                        modelDispatch({
                            type: "FINISH_UPLOAD",
                            payload: {
                                id: model.id,
                                model: response.data,
                            },
                        })
                    )
                    .catch((e) => {
                        if (!http.isCancel(e)) console.warn("Failed to upload IFC model(s)", e);
                    });
            });
        }

        modelUploadRef.current.value = "";
    };

    return (
        <Fragment>
            <input
                id="model-file-input"
                type="file"
                accept=".ifc, .ifcxml, .ifczip"
                multiple
                ref={modelUploadRef}
                style={{ display: "none" }}
                onChange={() => handleFileUpload()}
            />
            <div id="upload-button-holder" style={{ width: "100%" }}>
                {!isAuthenticated ? (
                    <div
                        style={{ width: "100%", cursor: "pointer" }}
                        data-for="model-upload-button"
                        data-tip={asText(document.data.UserFeature)}
                    >
                        <Button id="model-upload-disabled-model-add-button" block disabled startIcon={<Upload />}>
                            {asText(document.data.AddModel)}
                        </Button>
                    </div>
                ) : (
                    <Button
                        id="model-upload-model-add-button"
                        block
                        onClick={() => modelUploadRef.current.click()}
                        startIcon={<Upload />}
                    >
                        {asText(document.data.AddModel)}
                    </Button>
                )}
            </div>
            <ReactTooltip
                id={"model-upload-button"}
                type="light"
                effect="solid"
                border={true}
                borderColor={"#8c969b"}
                place={"top"}
                getContent={(dataTip) => <span>{dataTip}</span>}
            />
        </Fragment>
    );
};

export default ModelUpload;
