import { config } from "../../_configuration/configuration";

interface PropertySet {
    name: string;
    containsQuantities: boolean;
    properties: Property[];
}

interface Property {
    name: string;
    value: string;
    valueType: string;
    unit: string;
    unitType: string;
}

interface Classification {
    class: string;
    name: string;
    location: string;
    reference: string;
}

interface Material {
    materialName: string;
    setName: string;
    thickness: number;
}

export const getElementProperties = async (
    projectId: string,
    modelId: string,
    ifcGuid: string
): Promise<PropertySet[] | null> => {
    const result = await getElementData(projectId, modelId, ifcGuid, "Properties");
    if (!result) return null;
    return result as PropertySet[];
};

export const getElementMaterials = async (
    projectId: string,
    modelId: string,
    ifcGuid: string
): Promise<Material[] | null> => {
    const result = await getElementData(projectId, modelId, ifcGuid, "Materials");
    if (!result) return null;
    return result as Material[];
};

export const getElementClassifications = async (
    projectId: string,
    modelId: string,
    ifcGuid: string
): Promise<Classification[] | null> => {
    const result = await getElementData(projectId, modelId, ifcGuid, "Classifications");
    if (!result) return null;
    return result as Classification[];
};

const getElementData = async (
    projectId: string,
    modelId: string,
    ifcGuid: string,
    type: "Properties" | "Materials" | "Classifications"
): Promise<any> => {
    const url = `${config.api}api/v1/Projects/${projectId}/Models/${modelId}/Elements/${ifcGuid}/${type}`;
    const options: RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await fetch(url, options);
    if (!response.ok) return null;

    const result = await response.json();
    return result;
};
