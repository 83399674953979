import { Fragment } from "react";
import ReactTooltip from "react-tooltip";

const ActionBarItem = ({ name, tooltip, position, children, ...actions }) => {
    return (
        <Fragment>
            <div data-tip data-for={name} className="actionbar-button" {...actions}>
                {children}

                {tooltip && (
                    <ReactTooltip
                        id={name}
                        type="light"
                        effect="solid"
                        border={true}
                        borderColor={"#8c969b"}
                        place={"top"}
                        overridePosition={position}
                        data-event-off="click"
                    >
                        <span>{tooltip}</span>
                    </ReactTooltip>
                )}
            </div>
        </Fragment>
    );
};

export default ActionBarItem;
