import { createContext,useReducer } from "react";

const IfcViewerContext = createContext();

const initialState = {
    viewer: null,
    models: [],
    plugins: {
        navCube: null,
        sectionPlanes: null,
    },
    navCube: {
        visible: false,
    },
    sectionPlanes: [],
    selectedSurface: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_VIEWER":
            return { ...state, viewer: action.payload };

        case "SET_MODELS":
            return { ...state, models: action.payload };

        case "SET_NAV_CUBE_PLUGIN":
            return {
                ...state,
                plugins: { ...state.plugins, navCube: action.payload },
            };

        case "SET_NAV_CUBE_VISIBILITY":
            return {
                ...state,
                navCube: { ...state.navCube, visible: action.payload },
            };

        case "SET_SECTION_PLANES_PLUGIN":
            return {
                ...state,
                plugins: {
                    ...state.plugins,
                    sectionPlanes: action.payload,
                },
            };

        case "ADD_SECTION_PLANE":
            return {
                ...state,
                sectionPlanes: [...state.sectionPlanes, action.payload],
            };

        case "REMOVE_SECTION_PLANE":
            return {
                ...state,
                sectionPlanes: state.sectionPlanes.filter(
                    (section) => section.id !== action.payload
                ),
            };

        case "REFRESH_SECTION_PLANES":
            return {
                ...state,
                sectionPlanes: [...state.sectionPlanes],
            };

        case "SET_SECTION_PLANES":
            return {
                ...state,
                sectionPlanes: action.payload,
            };

        case "SET_SELECTED_SURFACE":
            return {
                ...state,
                selectedSurface: action.payload,
            };

        default:
            throw Error("Invalid action supplied.");
    }
};

const IfcViewerContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <IfcViewerContext.Provider value={{ state, dispatch }}>
            {props.children}
        </IfcViewerContext.Provider>
    );
};

export { IfcViewerContextProvider, IfcViewerContext };
