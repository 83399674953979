import { config } from "../../_configuration/configuration";

export const getVersion = async (): Promise<string | null> => {
    const url = `${config.api}api/v1/Version`;
    const options: RequestInit = {
        method: "GET",
    };

    const response = await fetch(url, options);
    if (!response.ok) return null;

    return response.text();
};
