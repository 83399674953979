import { Fragment, useContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import * as Icon from "react-feather";
import { Checkbox, useAuthentication, usePrismic } from "@buildwise/ui";
import { asText } from "@prismicio/helpers";

import Warning from "../../../../components/Warning";
import UpdateView from "../../Modals/UpdateView";
import ShareProject from "../../Modals/ShareProject";

import { ModelViewerContext } from "../../../../contexts/ModelViewerContextProvider";

import { config } from "../../../../_configuration/configuration";

const ViewInfo = ({ view }) => {
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);

    const [warning, setWarning] = useState({ visible: false });
    const [isRenaming, setIsRenaming] = useState(false);
    const [isSharing, setIsSharing] = useState(false);

    const { id: projectId } = useParams();
    const location = useLocation();
    const { isAuthenticated } = useAuthentication();
    const [document] = usePrismic("bimio_viewer");

    const isAdminPage = location.pathname.startsWith("/admin");

    const toggleStatus = () => {
        if (view.isActive) modelDispatch({ type: "RESET_ACTIVE_VIEW" });
        else modelDispatch({ type: "SET_ACTIVE_VIEW", payload: view.id });
    };

    const deleteView = (confirmed = false, result = false) => {
        if (!confirmed) {
            setWarning({
                visible: true,
                prompt: true,
                title: asText(document.data.RemoveViewQuestion),
                text: asText(document.data.RemoveNameDesc).replace("{{name}}", view.name),
                callback: (bool) => deleteView(true, bool),
            });

            return;
        }

        setWarning({ visible: false });

        if (!result) return;

        const options = {
            method: "DELETE",
            headers: {},
        };

        fetch(`${config.api}api/v1/Projects/${projectId}/Views/${view.id}`, options)
            .then((response) => {
                if (response.ok) {
                    modelDispatch({
                        type: "REMOVE_VIEW",
                        payload: { view: view.id, project: projectId },
                    });
                }
            })
            .catch((err) => console.warn(err));
    };

    const renderSharedUsersList = (users) => {
        if (users.length > 5) {
            const firstFour = users.slice(0, 4);
            return `${firstFour.join(", ")} ${asText(document.data.And)} ${users.length - 4} ${asText(
                document.data.Others
            )}`;
        }

        return users.join(", ").replace(/,(?!.*,)/gim, ` ${asText(document.data.And)}`);
    };

    return (
        <div className={view.isActive ? "view-infobox infobox active" : "view-infobox infobox"}>
            <div className="view-img" style={{ background: `url(${view.imageUrl})` }}></div>
            <div className="infobox-group">
                <div className="infobox-header">
                    <h3 title={view.name}>{view.name}</h3>
                    {!modelState.isSharedProject && isAuthenticated && (
                        <div className="infobox-actions" style={{ minWidth: "99px" }}>
                            <Icon.Edit className="action-button" onClick={() => setIsRenaming(true)} />
                            {!isAdminPage && (
                                <Icon.Share2 className="action-button" onClick={() => setIsSharing(true)} />
                            )}
                            <Icon.Trash2 className="action-button" onClick={() => deleteView()} />
                        </div>
                    )}
                </div>

                <div className={view.isActive ? "activation-toggle active" : "activation-toggle"}>
                    <Checkbox
                        label={view.isActive ? asText(document.data.Active) : asText(document.data.Activate)}
                        checked={view.isActive || false}
                        onChange={toggleStatus}
                    />
                </div>

                {!isAdminPage && !modelState.isSharedProject && (
                    <Fragment>
                        <div>
                            <p>
                                <strong style={{ paddingRight: "5px" }}>
                                    {view.users.length === 0
                                        ? asText(document.data.NotShared)
                                        : asText(document.data.SharedWithTitle)}
                                </strong>
                                {renderSharedUsersList(view.users)}
                            </p>
                        </div>
                    </Fragment>
                )}
            </div>

            {warning.visible && (
                <Warning promp={warning.prompt} title={warning.title} text={warning.text} callBack={warning.callback} />
            )}

            {isRenaming && <UpdateView view={view} onClose={() => setIsRenaming(false)} />}

            {isSharing && <ShareProject view={view} onClose={() => setIsSharing(false)} />}
        </div>
    );
};

export default ViewInfo;
