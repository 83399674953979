import { Fragment, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, usePrismic } from "@buildwise/ui";
import { asText } from "@prismicio/helpers";

import { config } from "../../_configuration/configuration";

const Warning = (props) => {
    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        window.document.addEventListener("keyup", handleKeyUp);
        return () => window.document.removeEventListener("keyup", handleKeyUp);
    }, []);

    const handleKeyUp = (e) => {
        if (e.key === "Enter") props.callBack(true);
        else if (e.key === "Escape") props.callBack(false);
    };

    return (
        <Modal size="small" onClose={() => props.callBack(false)} open={true} backdrop>
            <ModalHeader closeButton>
                <ModalTitle>{props.title}</ModalTitle>
            </ModalHeader>

            <ModalBody>{props.text}</ModalBody>

            <ModalFooter>
                {props.promp && (
                    <Fragment>
                        <Button id="warning-no-button" variant="tertiary" onClick={() => props.callBack(false)}>
                            {asText(document.data.No)}
                        </Button>
                        <Button id="warning-yes-button" onClick={() => props.callBack(true)}>{asText(document.data.Yes)}</Button>
                    </Fragment>
                )}

                {props.warning && (
                    <Fragment>
                        <Button id="warning-confirm-button" onClick={() => props.callBack(true)}>{asText(document.data.Ok)}</Button>
                    </Fragment>
                )}
            </ModalFooter>
        </Modal>
    );
};

export default Warning;
