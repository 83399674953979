import { Fragment } from "react";

const Highlight = ({
    text,
    term,
    useSpan,
    id,
    customClass,
    onClickEvent,
    onMouseEnterEvent,
    onMouseLeaveEvent,
    onDoubleClickEvent,
}) => {
    const highlightWords = (text) => {
        const pattern =
            term != null && term.length > 2 ? new RegExp("(" + term + ")", "i") : new RegExp("(" + /.*/ + ")", "gim");
        const splitWords = typeof text === "string" ? text.split(pattern) : text;
        const output = [];

        splitWords &&
            splitWords.forEach((entry, index) => {
                if (term != null && entry.toLowerCase() === term.toLowerCase()) {
                    output.push(
                        <span key={index} className="highlighted-text">
                            {entry}
                        </span>
                    );
                } else {
                    output.push(<Fragment key={index}>{entry}</Fragment>);
                }
            });

        return <Fragment>{output}</Fragment>;
    };

    const addLineBreaks = () => {
        const output = [];
        const splitWords = typeof text === "string" ? text.split("\n") : text;

        if (!splitWords) return;

        splitWords.forEach((line, index) => {
            if (useSpan !== undefined && useSpan !== false)
                output.push(
                    <span
                        key={index}
                        className={customClass}
                        id={id}
                        onClick={onClickEvent}
                        onDoubleClick={onDoubleClickEvent}
                        onMouseEnter={onMouseEnterEvent}
                        onMouseLeave={onMouseLeaveEvent}
                    >
                        {highlightWords(line.trim())}
                    </span>
                );
            else output.push(<p key={index}>{highlightWords(line.trim())}</p>);
        });

        return <Fragment>{output}</Fragment>;
    };

    return addLineBreaks();
};

export default Highlight;
