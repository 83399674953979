import { useContext, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Spinner, useLanguage, usePrismic } from "@buildwise/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { asText } from "@prismicio/helpers";

import PopupProject from "../PopupProject";

import { ModelViewerContext } from "../../contexts/ModelViewerContextProvider";

import "../../styles/model-viewer.css";

import { config } from "../../_configuration/configuration";
import { generatePath, useParams } from "react-router";

const NewProjectTile = () => {
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext) || {};

    const [newProject, setNewProject] = useState({ visible: false });
    const [isSavingProject, setIsSavingProject] = useState(false);
    const [nameExists, setNameExists] = useState(false);
    const projectsRef = useRef();

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const params = useParams();

    const [document] = usePrismic(config.prismic.documentType);
    const { language } = useLanguage();

    const onSaveNewProject = (body) => {
        const createProjectOptions = {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(body.projectBody),
        };

        setIsSavingProject(true);
        setNameExists(false);
        projectsRef.current = [];

        fetch(`${config.api}api/v1/Projects`, createProjectOptions).then((response) => {
            if (!response.ok) {
                setIsSavingProject(false);
                setNameExists(true);
                return;
            }

            return response.json().then((data) => {
                const projects = [...modelState.projects];
                projects.push(data);
                projectsRef.current = projects;

                setIsSavingProject(false);
                setNewProject(false);

                modelDispatch({ type: "SET_PROJECTS", payload: projectsRef.current });
                modelDispatch({ type: "RESET" });

                navigate(
                    generatePath(config.routes.project[language], {
                        ...params,
                        id: data.id,
                    })
                );
            });
        });
    };

    const hasDemoProject = () => {
        if (!Boolean(modelState?.projects)) return;

        return modelState.projects.some((x) => x.isDemo);
    };

    const onLoadDemoProject = () => {
        setIsLoading(true);

        const createProjectOptions = {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: {},
        };

        fetch(`${config.api}api/v1/Projects/CreateDemo`, createProjectOptions).then((response) => {
            fetch(`${config.api}api/v1/Projects`, {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            }).then(handleProjectsList);
        });
    };

    const handleProjectsList = async (response) => {
        if (response.ok) {
            const json = await response.json();
            const projectsList = json
                .map((x) => {
                    x.creationDate = new Date(x.creationDate);

                    return x;
                })
                .sort((a, b) => a.creationDate - b.creationDate);

            modelDispatch({
                type: "SET_PROJECTS",
                payload: projectsList,
            });

            setIsLoading(false);
        }
    };

    const classes = ["project-box"];
    if (isLoading) classes.push("disabled");

    return (
        <>
            <div className={classes.join(" ")}>
                <div className="project-box-create">
                    <p
                        onClick={() =>
                            setNewProject({
                                visible: true,
                                type: "Models",
                            })
                        }
                    >
                        {isLoading ? (
                            <Spinner />
                        ) : (
                            <FontAwesomeIcon icon={faPlusCircle} size={"10x"} color="rgba(0, 135, 183, 1)" />
                        )}
                    </p>
                </div>

                {!hasDemoProject() && (
                    <div className="project-box-create-footer">
                        <Link
                            id="new-project-tile-demo-project-load-button"
                            onClick={() => onLoadDemoProject()}
                            to={""}
                        >
                            {asText(document.data.projects_list_load_demo_project)}
                        </Link>
                    </div>
                )}
            </div>

            {newProject.visible && (
                <PopupProject
                    title={asText(document.data.NewProject)}
                    onClose={() => setNewProject({ visible: false })}
                    onSave={onSaveNewProject}
                    isSaving={isSavingProject}
                    nameExists={nameExists}
                />
            )}
        </>
    );
};

export default NewProjectTile;
