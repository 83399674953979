import { createContext, useReducer } from "react";
import sortBy from "lodash/sortBy";
import { MODEL_TREE_TAB } from "../pages/ModelViewer/Constants/SidebarTabs";
import { getProjectState, saveProjectState } from "../managers/UserStateManager";
import { ModelIndex as ModalIndex } from "../components/ModalHelper/ModalHelper";
const ModelViewerContext = createContext();

const initialState = {
    projects: [],
    sharedProjects: [],
    demoModelsLoadedOnce: false,

    projectId: null,
    isSharedProject: false,
    models: [],
    fetching: [],
    loaded: [],
    treeModel: null,
    spatialStructureTrees: null,
    classTrees: [],
    mergedClassTree: null,

    sidebar: true,
    activeSidebarTab: -1,

    filterModal: false,
    filterType: 0,
    filters: [],
    filterTrees: [],
    filtersTree: [],
    activeFilters: [],
    filteredTree: [],
    filtersLoading: false,

    tvSearch: false,
    properties: false,
    activePropertiesElement: null,

    selectedElement: null,

    sections: false,
    config: false,

    views: [],
    viewLoading: false,
    sharedViews: [],
    activeViewModels: [],
    activeViewFilters: [],

    camera: "perspective",

    selectionMode: "single",
    selectionCount: 0,

    filterGuids: [],
    colorFilterGuids: [],
    activeFilter: null,
    export: false,
    exportModels: [],
    exportView: false,
    activeColorFilter: null,
    modalOrder: [],
};

const ToggleModal = (state, propertyName, propertyIndex) => {
    let modalOrder = [...state.modalOrder];
    let newVisibility = !state[propertyName];
    if (!state[propertyName]) {
        modalOrder = modalOrder.filter((x) => x !== propertyIndex);
        modalOrder.push(propertyIndex);
    } else {
        if(modalOrder.length > 0 && modalOrder[modalOrder.length - 1] === propertyIndex){
            modalOrder.pop();
            newVisibility=false;
        }else{
            modalOrder = modalOrder.filter((x) => x !== propertyIndex);
            modalOrder.push(propertyIndex);
            newVisibility=true;
        }
    }
    let retState = { ...state, modalOrder };
    retState[propertyName] = newVisibility;
    return retState;
};

const reducer = (state, action) => {
    switch (action.type) {
        case "FULL_RESET": {
            return {
                ...state,
                projects: [],
                sharedProjects: [],
                projectId: null,
                isSharedProject: false,
                models: [],
                fetching: [],
                loaded: [],
                treeModel: null,
                spatialStructureTrees: null,
                classTrees: [],
                mergedClassTree: null,
                activeSidebarTab: -1,
                filters: [],
                filterTrees: [],
                filtersTree: [],
                activeFilters: [],
                filteredTree: [],
                filtersLoading: false,
                properties: false,
                activePropertiesElement: null,
                selectedElement: null,
                views: [],
                viewLoading: false,
                sharedViews: [],
                activeViewModels: [],
                activeViewFilters: [],
                filterGuids: [],
                colorFilterGuids: [],
                activeFilter: null,
                activeColorFilter: null,
                selectionCount: 0,
                modalOrder: [],
            };
        }

        case "RESET": {
            return {
                ...state,
                projectId: null,
                isSharedProject: false,
                models: [],
                fetching: [],
                loaded: [],
                treeModel: null,
                spatialStructureTrees: null,
                classTrees: [],
                mergedClassTree: null,
                activeSidebarTab: -1,
                filters: [],
                filterTrees: [],
                filtersTree: [],
                activeFilters: [],
                filteredTree: [],
                filtersLoading: false,
                properties: false,
                activePropertiesElement: null,
                selectedElement: null,
                views: [],
                viewLoading: false,
                sharedViews: [],
                activeViewModels: [],
                activeViewFilters: [],
                activeFilter: null,
                activeColorFilter: null,
                selectionCount: 0,
                modalOrder: [],
            };
        }

        case "SET_PROJECTS": {
            return {
                ...state,
                projects: action.payload,
            };
        }

        case "SET_SHARED_PROJECTS": {
            return {
                ...state,
                sharedProjects: action.payload,
            };
        }

        case "DEMO_MODELS_LOADED": {
            return {
                ...state,
                demoModelsLoadedOnce: true,
            };
        }

        case "UPDATE_PROJECT": {
            const projects = [...state.projects];
            const updatedProject = projects.find((x) => x.id === action.payload.id);
            updatedProject.name = action.payload.name;
            updatedProject.description = action.payload.description;

            return {
                ...state,
                projects,
            };
        }

        case "SET_PROJECT_SHARED": {
            const updatedProjects = state.projects.map((x) => {
                if (x.id === action.payload.id) {
                    return { ...x, isShared: action.payload.status };
                }
                return x;
            });

            return {
                ...state,
                projects: updatedProjects,
            };
        }

        case "REMOVE_PROJECT": {
            const projects = state.projects.filter((x) => x.id !== action.payload);

            return {
                ...state,
                projects,
            };
        }

        case "SELECT_PROJECT": {
            return {
                ...state,
                projectId: action.payload.id,
                isSharedProject: action.payload.isShared,
            };
        }

        case "TOGGLE_SIDEBAR": {
            return {
                ...state,
                sidebar: !state.sidebar,
            };
        }

        case "TOGGLE_PROPERTY_VIEWER": {
            return ToggleModal(state, "properties", ModalIndex.ElementProperties);
        }

        case "TOGGLE_TV_SEARCH": {
            return ToggleModal(state, "tvSearch", ModalIndex.TvSearch);
        }

        case "TOGGLE_SECTIONS_VIEWER": {
            return ToggleModal(state, "sections", ModalIndex.SectionPlaneManagement);
        }

        case "TOGGLE_VIEWER_CONFIGURATION": {
            return {
                ...state,
                config: !state.config,
            };
        }

        case "TOGGLE_FILTER_MODAL": {
            const filterType = action.payload ?? state.filterType;
            return {
                ...state,
                filterModal: !state.filterModal,
                filterType: filterType,
            };
        }

        case "SHOW_ELEMENT_PROPERTIES": {
            return {
                ...state,
                properties: true,
                activePropertiesElement: action.payload,
            };
        }

        case "CHANGE_PROPERTIES_ELEMENT": {
            return {
                ...state,
                activePropertiesElement: action.payload,
            };
        }

        case "CHANGE_SELECTED_ELEMENT": {
            return {
                ...state,
                selectedElement: action.payload,
                activePropertiesElement: action.payload,
            };
        }

        case "SET_CAMERA": {
            return { ...state, camera: action.payload };
        }

        case "SET_SELECTION_MODE": {
            return { ...state, selectionMode: action.payload };
        }

        case "SET_SELECTION_COUNT": {
            return { ...state, selectionCount: action.payload };
        }

        case "UPLOAD_MODEL": {
            const models = [...state.models];
            models.push(action.payload);

            return { ...state, models };
        }

        case "UPDATE_UPLOAD_PROGRESS": {
            const models = [...state.models];
            const model = models.find((x) => x.id === action.payload.id);
            if (!model) return state;

            const e = action.payload.uploadEvent;

            model.upload = {
                ...model.upload,
                progress: (e.loaded / e.total) * 100,
            };

            return { ...state, models };
        }

        case "FINISH_UPLOAD": {
            let models = [...state.models];
            const toUpdate = models.find((x) => x.id === action.payload.id);
            const filtered = models.filter((x) => x.id !== action.payload.id);
            const model = action.payload.model;
            model.order = toUpdate.order;

            filtered.push(model);
            models = sortBy(filtered, ["order"]);

            return { ...state, models };
        }

        case "SET_MODELS": {
            return {
                ...state,
                projectId: action.payload.project,
                models: action.payload.models,
            };
        }

        case "FETCH_MODEL": {
            const current = [...state.fetching];

            return {
                ...state,
                fetching: [...current, { id: action.payload.id, projectId: action.payload.projectId }],
            };
        }

        case "FETCHED_MODEL": {
            const current = state.fetching.filter(
                (x) => x.id !== action.payload.id && x.projectId !== action.payload.projectId
            );

            return {
                ...state,
                fetching: current,
            };
        }

        case "UPDATE_MODEL": {
            let models = [...state.models];
            const toUpdate = models.find((x) => x.id === action.payload.id);
            if (toUpdate) {
                toUpdate.conversionProgress = action.payload.totalUploadProgress;
            }

            const filtered = models.filter((x) => x.id !== action.payload.id);
            filtered.push({ ...toUpdate, ...action.payload });
            models = sortBy(filtered, ["order"]);

            return { ...state, models };
        }

        case "UPDATE_MODEL_CONVERSION_PROGRESS": {
            let models = [...state.models];
            const toUpdate = models.find((x) => x.id === action.payload.id);
            const filtered = models.filter((x) => x.id !== action.payload.id);
            toUpdate.conversionProgress = action.payload.progress;
            filtered.push(toUpdate);
            models = sortBy(filtered, ["order"]);

            return { ...state, models };
        }

        case "REMOVE_MODEL": {
            let models = [...state.models];
            const filtered = models.filter((x) => x.id !== action.payload);
            models = sortBy(filtered, ["order"]);

            return { ...state, models };
        }

        case "LOAD_MODEL": {
            if (action.payload.projectId !== state.projectId) return { ...state };

            const loaded = [...state.loaded];
            loaded.push(action.payload.model);

            const projectState = getProjectState(state.projectId);
            projectState.activeModels = loaded.map((x) => x.id);
            saveProjectState(state.projectId, projectState);

            return { ...state, loaded };
        }

        case "MODEL_LOADED": {
            let models = [...state.models];
            const toUpdate = models.find((x) => x.id === action.payload.id);
            toUpdate.loaded = true;
            toUpdate.xeokit = action.payload;

            const classTree = {
                modelId: action.payload.id,
                tree: JSON.parse(JSON.stringify(action.payload.ifcClassTree)),
            };

            const classTrees = [...state.classTrees];
            classTrees.push(classTree);

            const mergedClassTree = groupClassTree(classTrees);

            const spatialStructureTree = {
                id: action.payload.id,
                modelId: action.payload.id,
                name: action.payload.name,
                isModel: true,
                children: JSON.parse(JSON.stringify(action.payload.spatialStructureTree)),
            };

            let spatialStructureTrees = { ...state.spatialStructureTrees };
            if (!spatialStructureTrees.children)
                spatialStructureTrees = {
                    id: "root-node",
                    children: [],
                };

            spatialStructureTrees.children.push(spatialStructureTree);

            const filtered = models.filter((x) => x.id !== action.payload.id);
            filtered.push({ ...toUpdate });
            models = sortBy(filtered, ["order"]);

            return {
                ...state,
                models,
                classTrees,
                mergedClassTree,
                spatialStructureTrees,
            };
        }

        case "UNLOAD_MODEL": {
            const loaded = [...state.loaded].filter((x) => x.id !== action.payload);
            const unloaded = [...state.loaded].find((x) => x.id === action.payload);

            let activePropertiesElement = { ...state.activePropertiesElement };

            if (unloaded) {
                const selectedElements = unloaded.model.entityList.filter((x) => x.selected).map((x) => x.id);
                unloaded.model.scene.setObjectsSelected(selectedElements, false);
                unloaded.model.destroy();

                if (
                    selectedElements.length > 0 &&
                    state.activePropertiesElement &&
                    state.activePropertiesElement.id === selectedElements[0]
                ) {
                    activePropertiesElement = {};
                }
            }

            const projectState = getProjectState(state.projectId);
            projectState.activeModels = loaded.map((x) => x.id);
            saveProjectState(state.projectId, projectState);

            let models = [...state.models];
            const toUpdate = models.find((x) => x.id === action.payload);
            toUpdate.loaded = false;

            const filtered = models.filter((x) => x.id !== action.payload);
            filtered.push({ ...toUpdate });
            models = sortBy(filtered, ["order"]);

            const filterTrees = state.filterTrees.filter((x) => x.modelId !== action.payload);
            let spatialStructureTrees = state.spatialStructureTrees || {};
            if (!spatialStructureTrees.children)
                spatialStructureTrees = {
                    id: "root-node",
                    children: [],
                };

            spatialStructureTrees.children =
                spatialStructureTrees.children?.filter((x) => x.modelId !== action.payload) ?? [];

            if (spatialStructureTrees.children.length === 0)
                spatialStructureTrees.children.push({ name: "", id: "", nestingLevel: 0, children: [], type: "empty" });

            const classTrees = state.classTrees?.filter((x) => x.modelId !== action.payload) ?? [];
            const mergedClassTree = groupClassTree(classTrees);
            if (mergedClassTree.children.length === 0)
                mergedClassTree.children.push({ name: "", id: "", nestingLevel: 0, children: [], type: "empty" });

            return {
                ...state,
                loaded,
                models,
                filterTrees,
                mergedClassTree,
                spatialStructureTrees,
                activePropertiesElement,
                selectionCount: Object.keys(activePropertiesElement) > 0 ? 1 : 0,
            };
        }

        case "CHANGE_SIDEBAR_TAB": {
            return { ...state, activeSidebarTab: action.payload };
        }

        case "SHOW_MODEL_TREES": {
            return {
                ...state,
                treeModel: action.payload,
                activeSidebarTab: MODEL_TREE_TAB,
            };
        }

        case "SET_FILTERS": {
            const activeFilters = [...state.activeFilters];
            for (let i = 0; i < action.payload.length; i++) {
                const activeMatch = activeFilters.find((x) => x.name === action.payload[i].name);
                if (activeMatch) {
                    activeMatch.sets = [...action.payload[i].sets];
                }
            }
            return {
                ...state,
                filters: action.payload,
                activeFilters,
            };
        }

        case "SET_FILTER_TREE": {
            return {
                ...state,
                filtersTree: action.payload,
            };
        }

        case "SET_ACTIVE_FILTER": {
            return {
                ...state,
                activeFilters: action.payload,
            };
        }

        case "SET_FILTERED_TREE": {
            return {
                ...state,
                filteredTree: action.payload,
            };
        }

        case "SET_FILTERS_LOADING": {
            return {
                ...state,
                filtersLoading: action.payload,
            };
        }

        case "SET_VIEWS": {
            return {
                ...state,
                views: action.payload,
            };
        }

        case "SET_SHARED_VIEWS": {
            return {
                ...state,
                sharedViews: action.payload,
            };
        }

        case "ADD_SHARED_VIEW": {
            const sharedViews = [...state.sharedViews];
            sharedViews.push(action.payload);

            return {
                ...state,
                sharedViews: sharedViews,
            };
        }

        case "LOADING_VIEW": {
            return {
                ...state,
                viewLoading: action.payload,
            };
        }

        case "ADD_VIEW": {
            const views = state.views.map((x) => {
                return { ...x, isActive: false };
            });
            const view = action.payload;

            views.push(view);

            return { ...state, views };
        }

        case "REMOVE_VIEW": {
            const views = state.views.filter((x) => x.id !== action.payload.view);

            const users = views.map((x) => x.users).flat();
            const updatedProjects = state.projects.map((x) => {
                if (x.id === action.payload.project) return { ...x, isShared: users.length > 0 };
                return x;
            });

            return { ...state, views, projects: updatedProjects };
        }

        case "SET_ACTIVE_VIEW": {
            const projectState = getProjectState(state.projectId);
            projectState.activeView = action.payload;
            saveProjectState(state.projectId, projectState);

            const views = state.views.map((x) => {
                return { ...x, isActive: x.id === action.payload };
            });

            return { ...state, views };
        }

        case "RESET_ACTIVE_VIEW": {
            const projectState = getProjectState(state.projectId);
            projectState.activeView = "";
            saveProjectState(state.projectId, projectState);

            const views = state.views.map((x) => {
                return { ...x, isActive: false };
            });

            return { ...state, views };
        }

        case "SET_ACTIVE_VIEW_DETAILS": {
            return {
                ...state,
                viewLoading: false,
            };
        }

        case "UPDATE_VIEW": {
            const views = [...state.views];
            const toUpdate = views.find((x) => x.id === action.payload.id);
            toUpdate.name = action.payload.name;
            toUpdate.description = action.payload.description;
            toUpdate.viewModels = action.payload.modelIds.map((x) => {
                return { id: x };
            });
            toUpdate.modelFilterSets = action.payload.modelFilterSets;
            toUpdate.viewerBlob = action.payload.viewerBlob;
            toUpdate.viewer = {};

            return { ...state, views };
        }

        case "UPDATE_VIEWS_USERS": {
            const views = [...state.views];
            for (let i = 0; i < action.payload.length; i++) {
                const changes = action.payload[i];
                const view = views.find((x) => x.id === changes.view);
                if (!view) continue;

                if (!view.users) view.users = [];

                view.users = view.users.filter((x) => !changes.toRemove.some((y) => x === y));
                for (let i = 0, len = changes.toAdd.length; i < len; i++) {
                    if (view.users.indexOf(changes.toAdd[i]) === -1) view.users.push(changes.toAdd[i]);
                }
            }

            return { ...state, views };
        }

        case "FILTER_GUIDS":
            return {
                ...state,
                filterGuids: action.payload,
            };

        case "COLOR_FILTER_GUIDS":
            return {
                ...state,
                colorFilterGuids: action.payload,
            };

        case "ACTIVE_FILTER": {
            const projectState = getProjectState(state.projectId);
            projectState.activeFilter = action.payload ?? "";
            saveProjectState(state.projectId, projectState);

            return {
                ...state,
                activeFilter: action.payload,
            };
        }

        case "ACTIVE_COLOR_FILTER": {
            const projectState = getProjectState(state.projectId);
            projectState.activeColorFilter = action.payload ?? "";
            saveProjectState(state.projectId, projectState);

            return {
                ...state,
                activeColorFilter: action.payload,
            };
        }

        case "SET_EXPORT":
            return { ...state, export: true, exportModels: action.payload.models, exportView: action.payload.isView };

        case "CLEAR_EXPORT":
            return { ...state, export: false, exportModels: [] };
        case "SET_ACTIVE_MODAL": {
            let modalOrder = [...state.modalOrder];
            modalOrder = modalOrder.filter((x) => x !== action.payload);
            modalOrder.push(action.payload);
            return { ...state, modalOrder };
        }
        default:
            console.error(`Invalid action '${action.type}' supplied to ModelViewerContext.`);

            return;
    }
};

const groupClassTree = (classTrees) => {
    const mergedTree = [];
    if (classTrees.length > 0) {
        for (let i = 0; i < classTrees.length; i++) {
            const model = classTrees[i];

            for (let j = 0; j < model.tree.length; j++) {
                const ifcClass = model.tree[j];
                let existingIfcClass = mergedTree.find((x) => x.name === ifcClass.name);

                if (!existingIfcClass) {
                    existingIfcClass = { ...ifcClass, children: [] };
                    mergedTree.push(existingIfcClass);
                }

                for (let k = 0; k < ifcClass.children.length; k++) {
                    const ifcType = ifcClass.children[k];
                    let existingIfcType = existingIfcClass.children.find((x) => x.name === ifcType.name);

                    if (!existingIfcType) {
                        existingIfcType = { ...ifcType, children: [] };
                        existingIfcClass.children.push(existingIfcType);
                    }

                    for (let l = 0; l < ifcType.children.length; l++) {
                        const obj = { ...ifcType.children[l] };
                        existingIfcType.children.push(obj);
                    }
                }
            }
        }
    }

    return { id: "root-node", children: mergedTree };
};

const ModelViewerContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <ModelViewerContext.Provider value={{ state, dispatch }}>{props.children}</ModelViewerContext.Provider>;
};

export { ModelViewerContextProvider, ModelViewerContext };
