import { Fragment, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Button, useAuthentication, usePrismic } from "@buildwise/ui";
import { asText } from "@prismicio/helpers";

import ViewInfo from "./ViewInfo";
import CreateView from "../../Modals/CreateView";

import { ModelViewerContext } from "../../../../contexts/ModelViewerContextProvider";

import { config } from "../../../../_configuration/configuration";
import  useModelViewer from "../../../ModelViewer/Hooks/useModelViewer";
import  useSectionPlanes from "../../../ModelViewer/Hooks/useSectionPlanes";
import { CameraMemento } from "@xeokit/xeokit-sdk"

const Views = () => {
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);

    const [isCreatingNewView, setIsCreatingNewView] = useState(false);

    const { getXRayedObjects, getVisibleObjects, getObjects, getViewer, getSnapshot } = useModelViewer();
    const { getSectionPlanes } = useSectionPlanes();

    const { id: projectId } = useParams();
    const { isAuthenticated } = useAuthentication();
    const [document] = usePrismic(config.prismic.documentType);

    const onCreateNewView = (data) => {
        setIsCreatingNewView(false);

        const baseOptions = {
            method: "POST",
            mode: "cors",
            headers: {},
        };

        const cameraMemento = new CameraMemento();
        cameraMemento.saveCamera(getViewer());

        const blob = JSON.stringify({
            camera: cameraMemento,
            entities: {
                hidden: getObjects().filter((obj) => getVisibleObjects().indexOf(obj) === -1),
                xray: getXRayedObjects(),
            },
            sections: getSectionPlanes().map((section) => {
                return {
                    id: section.id,
                    active: section.active,
                    dir: section.dir,
                    pos: section.pos,
                    dist: section.dist,
                };
            }),
        });

        const imageBlob = getSnapshot({
            format: "png",
            width: "300",
            height: "300",
        });
        const file = DataURIToBlob(imageBlob);

        let formData = new FormData();
        formData.append("Name", data.name);
        formData.append("Description", data.description);
        formData.append("ViewerBlob", blob);

        const models = modelState.models.filter((x) => x.loaded).map((x) => x.id);
        for (let i = 0; i < models.length; i++) formData.append("ModelIds", models[i]);
        if (modelState.activeFilter) {
            formData.append("FilterIds", modelState.activeFilter);
        }
        formData.append("ImageMimeType", "image/png");
        formData.append("File", file, "snapshot.png");

        fetch(`${config.api}api/v1/Projects/${projectId}/Views`, {
            ...baseOptions,
            body: formData,
        })
            .then(handleNewView)
            // .then((view) => inviteUsers(view, data))
            .catch((err) => console.warn("Failed to save view:", err));
    };

    const handleNewView = async (response, data) => {
        const json = await response.json();
        if (!response.ok) throw new Error("Failed to save");

        modelDispatch({
            type: "ADD_VIEW",
            payload: { ...json, isActive: true, users: [] },
        });

        return json;
    };

    const DataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(",");
        const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
        return new Blob([ia], { type: mimeString });
    };

    return (
        <Fragment>
            <div id="tab-content" className="no-header">
                {modelState.views.length > 0 ? (
                    modelState.views.map((view, index) => <ViewInfo key={index} view={view} />)
                ) : (
                    <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
                        <p style={{ margin: "auto" }}>{asText(document.data.views_list_empty)}</p>
                    </div>
                )}
            </div>

            {!modelState.isSharedProject && (
                <div id="bottom-menu">
                    {!isAuthenticated ? (
                        <div
                            style={{ width: "100%", cursor: "pointer" }}
                            data-for="create-view-button"
                            data-tip={asText(document.data.UserFeature)}
                        >
                            <Button id="views-disabled-new-view-save-button" block disabled>
                                {asText(document.data.SaveNewViewButton)}
                            </Button>
                        </div>
                    ) : (
                        <Button
                            id="views-create-new-view-button"
                            block
                            onClick={() => setIsCreatingNewView(true)}
                            disabled={modelState.loaded.length === 0}
                        >
                            {asText(document.data.SaveNewViewButton)}
                        </Button>
                    )}
                </div>
            )}

            <ReactTooltip
                id={"create-view-button"}
                type="light"
                effect="solid"
                border={true}
                borderColor={"#8c969b"}
                place={"top"}
                getContent={(dataTip) => <span>{dataTip}</span>}
            />

            {isCreatingNewView && (
                <CreateView onSave={(data) => onCreateNewView(data)} onClose={() => setIsCreatingNewView(false)} />
            )}
        </Fragment>
    );
};

export default Views;
