import { useContext, useEffect } from "react";
import { Box } from "react-feather";
import ReactTooltip from "react-tooltip";
import { usePrismic } from "@buildwise/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrowser, faStream } from "@fortawesome/pro-regular-svg-icons";
import { asText } from "@prismicio/helpers";
import { useAuthentication } from "@buildwise/ui";
import Models from "./ModelManager/Models";
import ModelTree from "./ModelTrees/ModelTree";
import Views from "./Views/Views";
import { MODEL_TAB, VIEWS_TAB, MODEL_TREE_TAB } from "../Constants/SidebarTabs";

import { ModelViewerContext } from "../../../contexts/ModelViewerContextProvider";

import { config } from "../../../_configuration/configuration";

import "../../../styles/model-viewer.css";

const Sidebar = () => {
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);
    const [document] = usePrismic(config.prismic.documentType);
    const { isAuthenticated } = useAuthentication();
    const setActiveTab = (tab) => {
        modelDispatch({ type: "CHANGE_SIDEBAR_TAB", payload: tab });
    };
    useEffect(() => {
        if (!isAuthenticated) {
            if (modelState.models.length > 0 && modelState.activeSidebarTab === -1) {
                setTimeout(() => {
                    setActiveTab(MODEL_TREE_TAB);
                }, 10);//0 is enough too because react needs single tick of rerender before executing this. But to be sure I set it to 10
            }
        }
    }, [modelState.models]);
    const renderActiveTab = () => {
        switch (modelState.activeSidebarTab) {
            case MODEL_TREE_TAB:
                return <ModelTree />;
            case VIEWS_TAB:
                return <Views />;
            default:
                return <Models />;
        }
    };

    return modelState.sidebar ? (
        <div id="sidebar">
            <div id="tabs">
                <button
                    id="active-model-tab-set-button"
                    className={
                        modelState.activeSidebarTab === MODEL_TAB || modelState.activeSidebarTab === -1
                            ? "button secondary active"
                            : "button secondary"
                    }
                    onClick={() => setActiveTab(MODEL_TAB)}
                    data-for="sidebar-tab"
                    data-tip={modelState.activeSidebarTab === MODEL_TAB ? "" : asText(document.data.Models)}
                >
                    <Box />
                    {modelState.activeSidebarTab === MODEL_TAB && ` ${asText(document.data.Models)}`}
                </button>

                <button
                    id="active-model-tree-tab-set-button"
                    className={
                        modelState.activeSidebarTab === MODEL_TREE_TAB ? "button secondary active" : "button secondary"
                    }
                    onClick={() => setActiveTab(MODEL_TREE_TAB)}
                    data-for="sidebar-tab"
                    data-tip={modelState.activeSidebarTab === MODEL_TREE_TAB ? "" : asText(document.data.Tree)}
                >
                    <FontAwesomeIcon icon={faStream} />
                    {modelState.activeSidebarTab === MODEL_TREE_TAB && ` ${asText(document.data.Tree)}`}
                </button>

                <button
                    id="active-views-tab-set-button"
                    className={
                        modelState.activeSidebarTab === VIEWS_TAB ? "button secondary active" : "button secondary"
                    }
                    onClick={() => setActiveTab(VIEWS_TAB)}
                    data-for="sidebar-tab"
                    data-tip={modelState.activeSidebarTab === VIEWS_TAB ? "" : asText(document.data.Views)}
                >
                    <FontAwesomeIcon icon={faBrowser} />
                    {modelState.activeSidebarTab === VIEWS_TAB && ` ${asText(document.data.Views)}`}
                </button>

                <ReactTooltip
                    id={"sidebar-tab"}
                    type="light"
                    effect="solid"
                    border={true}
                    borderColor={"#8c969b"}
                    place={"top"}
                    getContent={(dataTip) => (dataTip ? <span>{dataTip}</span> : null)}
                />
            </div>

            {renderActiveTab()}
        </div>
    ) : null;
};

export default Sidebar;
