import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Text, usePrismic } from "@buildwise/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { asText } from "@prismicio/helpers";

import { ModelViewerContext } from "../../../contexts/ModelViewerContextProvider";

import { config } from "../../../_configuration/configuration";

const UpdateView = (props) => {
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);

    const [canSave, setCanSave] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [name, setName] = useState("");
    const [invitees, setInvitees] = useState([]);
    const [emails, setEmails] = useState([]);
    const [removedInvitees, setRemovedInvitees] = useState([]);

    const { id: projectId } = useParams();
    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        setName(props.view.name || "");
        setEmails(props.view.users || []);

        const options = {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
        };

        const sharedView = modelState.sharedViews.find((x) => x.viewId === props.view.id);
        if (!sharedView) return;

        fetch(`${config.api}api/v1/Projects/${projectId}/SharedViews/${sharedView.id}/Invitees`, options)
            .then((result) => {
                if (!result.ok) return;
                return result.json();
            })
            .then((json) => setInvitees(json));
    }, [props.view.id]);

    useEffect(() => {
        setCanSave(name.trim() !== "" && !isSaving && validateInput(name));
    }, [name, isSaving]);

    const validateInput = (input) => {
        const existingView = modelState.views.find((x) => x.name?.trim().toLowerCase() === input.trim().toLowerCase());
        setCanSave(existingView === null || existingView === undefined || existingView.id === props.view.id);
        return existingView === null || existingView === undefined || existingView.id === props.view.id;
    };

    const onSave = () => {
        setIsSaving(true);

        let promises = [];

        if (props.view.name !== name) promises = [...promises, renameView()];
        if (removedInvitees.length > 0) promises = [...promises, ...updateInvitees()];

        Promise.all(promises)
            .then((x) => {
                if (removedInvitees.length === 0) {
                    props.onClose();
                    return;
                }

                const removedEmails = invitees.filter((x) => removedInvitees.includes(x.id)).map((x) => x.email);

                modelDispatch({ type: "SET_PROJECT_SHARED", payload: { id: projectId, status: emails.length > 0 } });
                modelDispatch({
                    type: "UPDATE_VIEWS_USERS",
                    payload: [{ view: props.view.id, toAdd: [], toRemove: removedEmails }],
                });

                let options = {
                    method: "GET",
                    headers: {},
                };

                return fetch(`${config.api}api/v1/Projects/${projectId}/SharedViews`, options);
            })
            .then((response) => response.json())
            .then((sharedViews) => {
                modelDispatch({
                    type: "SET_SHARED_VIEWS",
                    payload: sharedViews.filter((x) => x.invitees.length > 0),
                });

                props.onClose();
            })
            .catch((e) => console.warn("Failed to obtain shared views:", e));
    };

    const renameView = () => {
        const formData = new FormData();
        formData.append("Name", name);
        formData.append("Description", "");
        formData.append("ViewerBlob", props.view.viewerBlob);
        const models = props.view.viewModels.map((x) => x.id);
        for (let i = 0; i < models.length; i++) formData.append("ModelIds", models[i]);
        if (modelState.activeFilter) {
            formData.append("FilterIds", modelState.activeFilter);
        }

        const options = {
            method: "PUT",
            headers: {},
            body: formData,
        };

        return fetch(`${config.api}api/v1/Projects/${projectId}/Views/${props.view.id}`, options)
            .then((response) => {
                if (response.ok) {
                    const view = { ...props.view };
                    view.name = name;

                    modelDispatch({
                        type: "UPDATE_VIEW",
                        payload: {
                            ...view,
                            modelIds: props.view.viewModels.map((x) => x.id),
                            filterIds: props.view.modelFilterSets.map((x) => x.filterId),
                            viewerBlob: props.view.viewerBlob,
                        },
                    });
                }
            })
            .catch((err) => console.warn("Error attempting to rename view:", err));
    };

    const updateInvitees = () => {
        const promises = [];

        const sharedView = modelState.sharedViews.find((x) => x.viewId === props.view.id);
        if (!sharedView) return promises;

        const options = {
            method: "DELETE",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
        };

        for (let i = 0, len = removedInvitees.length; i < len; i++) {
            const id = removedInvitees[i];
            promises.push(
                fetch(`${config.api}api/v1/Projects/${projectId}/SharedViews/${sharedView.id}/Invitees/${id}`, options)
            );
        }

        return promises;
    };

    const onRemove = (email) => {
        setEmails(emails.filter((x) => x !== email));
        const invitee = invitees.find((x) => x.email === email);
        setRemovedInvitees([...removedInvitees, invitee.id]);
    };

    return (
        <Modal size="small" onClose={() => props.onClose()} backdrop open={true}>
            <ModalHeader closeButton>
                <ModalTitle>{asText(document.data.EditName).replace("{{name}}", props.view.name)}</ModalTitle>
            </ModalHeader>

            <ModalBody>
                <div>
                    <strong style={{ margin: "0 0 8px 16px" }}>{asText(document.data.ViewName)}</strong>
                    <Text
                        type="text"
                        required={true}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onValidate={validateInput}
                        validationError={asText(document.data.ViewAlreadyExistsWarning)}
                    />
                </div>
                {emails.length > 0 && (
                    <div>
                        <strong style={{ margin: "0 0 8px 16px", display: "block" }}>
                            {asText(document.data.ManageViewShares)}
                        </strong>
                        <div>
                            <span className="chips">
                                {emails.map((email, index) => (
                                    <span key={index} className="chip">
                                        {email}
                                        <FontAwesomeIcon icon={faTimes} onClick={(e) => onRemove(email)} />
                                    </span>
                                ))}
                            </span>
                        </div>
                    </div>
                )}
                &nbsp;
            </ModalBody>

            <ModalFooter>
                <Button id="update-view-cancel-button" variant="tertiary" onClick={() => props.onClose()}>
                    {asText(document.data.Cancel)}
                </Button>
                <Button id="update-view-save-button" onClick={() => onSave()} disabled={!canSave}>
                    {isSaving ? asText(document.data.BusySaving) : asText(document.data.Save)}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default UpdateView;
