import { Fragment, useContext, useEffect, useState } from "react";
import { Rnd } from "react-rnd";
import ReactTooltip from "react-tooltip";
import { Checkbox, usePrismic } from "@buildwise/ui";
import { ReactComponent as CubeDirectional } from "../../../assets/icons/cube-directional.svg";
import useSectionPlanes from "../Hooks/useSectionPlanes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsV, faTimesSquare, faLayerGroup, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { asText } from "@prismicio/helpers";

import { ModelViewerContext } from "../../../contexts/ModelViewerContextProvider";
import {useIndexedModal,ModelIndex} from "../../../components/ModalHelper/ModalHelper";

import { config } from "../../../_configuration/configuration";

const SectionPlaneManagement = () => {
    const { dispatch: modelDispatch } = useContext(ModelViewerContext);
    const {zIndex} = useIndexedModal(ModelIndex.SectionPlaneManagement);

    const { getSectionPlanes, getActiveSectionPlaneControl } = useSectionPlanes();
    const [document] = usePrismic(config.prismic.documentType);

    const defaultPosition = {
        x: Number(localStorage.getItem("section-planes-x")) || 20,
        y: Number(localStorage.getItem("section-planes-y")) || 60,
        width: localStorage.getItem("section-planes-width") || 450,
        height: localStorage.getItem("section-planes-height") || "40%",
    };

    const storePosition = (x, y) => {
        localStorage.setItem("section-planes-x", x);
        localStorage.setItem("section-planes-y", y);
    };

    const storeSize = (width, height) => {
        localStorage.setItem("section-planes-width", width);
        localStorage.setItem("section-planes-height", height);
    };

    return (
        <Rnd
            default={defaultPosition}
            dragHandleClassName="handle"
            id="sections-viewer"
            onDragStop={(e, d) => storePosition(d.x, d.y)}
            onMouseDown={() => modelDispatch({ type: "SET_ACTIVE_MODAL", payload: ModelIndex.SectionPlaneManagement })}
            onResizeStop={(e, direction, ref, delta, position) => storeSize(ref.style.width, ref.style.height)}
            bounds="#viewer-page"
            style={{ zIndex: zIndex }}
        >
            <div id="sections-viewer-header" className="handle">
                <FontAwesomeIcon icon={faLayerGroup} />
                <h2>{asText(document.data.SectionManagement)}</h2>
                <FontAwesomeIcon
                    icon={faTimesSquare}
                    className="close-button"
                    onClick={() => modelDispatch({ type: "TOGGLE_SECTIONS_VIEWER" })}
                />
            </div>

            <div id="sections-viewer-content">
                {getSectionPlanes().length === 0 ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <p style={{ margin: "0 auto" }}>{asText(document.data.NoSectionsCreated)}</p>
                    </div>
                ) : (
                    <Fragment>
                        {getSectionPlanes().map((section, index) => (
                            <SectionPlane
                                key={index}
                                index={index + 1}
                                section={section}
                                isControlActive={section.id === getActiveSectionPlaneControl()}
                            />
                        ))}
                    </Fragment>
                )}
            </div>
        </Rnd>
    );
};

export default SectionPlaneManagement;


const SectionPlane = (props) => {
    const [isActive, setIsActive] = useState(false);
    const [orientation, setOrientation] = useState("");

    const {
        getActiveSectionPlaneControl,
        removeSectionPlane,
        toggleSectionPlane,
        toggleSectionPlaneControl,
        flipSectionPlane,
    } = useSectionPlanes();
    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        const splitId = props.section.id.split("-");
        setOrientation(splitId[2]);
    }, []);

    useEffect(() => {
        setIsActive(props.isSelected);
    }, [props.isSelected]);

    useEffect(() => {
        if (props.section.active) {
            if (getActiveSectionPlaneControl() !== props.section.id) toggleSectionPlaneControl(props.section);

            return;
        }

        if (getActiveSectionPlaneControl() === props.section.id) toggleSectionPlaneControl(props.section);
    }, [props.section.active]);

    return (
        <div className={isActive ? "infobox active" : "infobox"}>
            <div className="infobox-header">
                <h3>
                    {asText(document.data.Section)} {props.index} - {asText(document.data[orientation])}
                </h3>

                <div className="infobox-actions ">
                    {/* <FontAwesomeIcon
                        icon={faEdit}
                        className="action-button"
                        style={{ fontSize: "24px" }}
                        
                    /> */}
                    <div className="action-button imported"
                        id="section-plane-management-action-toggle-control"
                        onClick={() => toggleSectionPlaneControl(props.section)}
                        data-for={`section-${props.index}`}
                        data-tip={asText(document.data.SectionsActionControlsTooltip)}
                    >
                        <CubeDirectional />
                    </div>
                    <FontAwesomeIcon
                        id="section-plane-management-action-flip"
                        icon={faArrowsV}
                        className="action-button"
                        style={{ fontSize: "24px" }}
                        onClick={() => flipSectionPlane(props.section)}
                        data-for={`section-${props.index}`}
                        data-tip={asText(document.data.SectionsActionFlipTooltip)}
                    />
                    <FontAwesomeIcon
                        id="section-plane-management-action-delete"
                        icon={faTrashAlt}
                        className="action-button"
                        style={{ fontSize: "24px" }}
                        data-for={`section-${props.index}`}
                        onClick={() => removeSectionPlane(props.section)}
                        data-tip={asText(document.data.SectionsActionDeleteTooltip)}
                    />
                </div>
            </div>

            <div className={props.section.active ? "activation-toggle active" : "activation-toggle"}>
                <Checkbox
                    label={props.section.active ? asText(document.data.Active) : asText(document.data.Activate)}
                    checked={props.section.active}
                    indeterminate={""}
                    onChange={() => toggleSectionPlane(props.section)}
                />
            </div>

            <ReactTooltip
                id={`section-${props.index}`}
                type="light"
                effect="solid"
                border={true}
                borderColor={"#8c969b"}
                place={"top"}
                getContent={(dataTip) => <span style={{ whiteSpace: "nowrap" }}>{dataTip}</span>}
            />
        </div>
    );
};
