import { Fragment, useEffect, useRef, useContext, useState } from "react";
import { Link, useParams, useLocation, useNavigate, generatePath } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";
import { Edit, Trash2, ArrowUp, ArrowDown } from "react-feather";
import {
    Breadcrumb,
    Button,
    Content,
    ContentBody,
    IconButton,
    Panel,
    PanelBody,
    PanelHeader,
    Spinner,
    useAuthentication,
    useLanguage,
    usePrismic,
} from "@buildwise/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandArrows } from "@fortawesome/pro-regular-svg-icons";
import { asText } from "@prismicio/helpers";

import Sidebar from "./Sidebar";
import Viewer from "./Viewer";
import PopupProject from "../../components/PopupProject";
import Warning from "../../components/Warning";
import { VIEWS_TAB } from "./Constants/SidebarTabs";
import Tutorial from "../../components/Tutorial";

import { FilterContext } from "../../contexts/FilterContextProvider";
import { ModelViewerContext } from "../../contexts/ModelViewerContextProvider";
import { IfcViewerContextProvider } from "../../contexts/IfcViewerContextProvider";

import { config } from "../../_configuration/configuration";

import "../../styles/model-viewer.css";

const ProjectViewer = () => {
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);
    const { dispatch: filterDispatch } = useContext(FilterContext);

    const [document] = usePrismic(config.prismic.documentType);

    const [project, setProject] = useState({ name: asText(document.data.BusyLoading) });
    const [isEdittingProject, setIsEdittingProject] = useState(false);
    const [isSavingEdit, setIsSavingEdit] = useState(false);
    const [warning, setWarning] = useState({ visible: false });
    const [hasAccess, setHasAccess] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const headerRef = useRef();
    const isDemoProjectRef = useRef();
    const [viewerInView, setViewerInView] = useState(false);

    const params = useParams();
    const { id: projectId } = params;
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthenticated, login } = useAuthentication();
    const { language } = useLanguage();

    // Show a dialog when the user refreshes the page, this does not stop the user from changing to another page, we'll use react-router's <Prompt> for this
    useBeforeunload((e) => {
        if (modelState.models.some((x) => x.upload && x.upload.progress < 100))
            return asText(document.data.UploadWillBeCancelled);

        delete e.returnValue;
    });

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    });

    useEffect(() => {
        if (isLoading) return;

        const timeout = setTimeout(
            () => headerRef.current && headerRef.current.scrollIntoView({ behavior: "smooth" }),
            500
        );

        return () => clearTimeout(timeout);
    }, [isLoading]);

    useEffect(() => {
        if (modelState.projects.length === 0) return;

        if (isAuthenticated) {
            const hasAccess =
                modelState.projects.some((x) => x.id === projectId) ||
                modelState.sharedProjects.some((x) => x.id === projectId);
            setHasAccess(hasAccess);
            if (!hasAccess) setIsLoading(false);

            return;
        }

        const isDemoProject = modelState.projects.some((x) => x.id === projectId);
        isDemoProjectRef.current = isDemoProject;
        if (isDemoProject) return;

        const targetLocation = location.search ? location.pathname + location.search : location.pathname;
        login(
            () => navigate(generatePath(targetLocation, { ...params })),
            () => navigate(generatePath(config.routes[language], { ...params })),
            () => navigate(generatePath(config.routes[language], { ...params }))
        );
    }, [modelState.projects, modelState.sharedProjects]);

    useEffect(() => {
        const project = modelState.projects.find((x) => x.id === projectId);

        const sharedProject = modelState.sharedProjects.find((x) => x.id === projectId);

        if (project) setProject(project);
        if (sharedProject) setProject(sharedProject);

        if (!project && !sharedProject) return;

        modelDispatch({
            type: "SELECT_PROJECT",
            payload: {
                id: projectId,
                isShared: sharedProject !== undefined,
            },
        });
    }, [modelState.projects, modelState.sharedProjects, projectId]);

    useEffect(() => {
        if (!project.id) return;

        fetch(`${config.api}api/v1/Projects/${projectId}/Models`, {
            method: "GET",
            mode: "cors",
            headers: {},
        })
            .then((response) => {
                setIsLoading(false);
                return response.json();
            })
            .then((json) => {
                modelDispatch({
                    type: "SET_MODELS",
                    payload: { project: projectId, models: json },
                });
            })
            .catch((e) => console.warn("Failed to get models list:", e));

        fetch(`${config.api}api/v1/Projects/${projectId}/Filters`, {
            method: "GET",
            mode: "cors",
            headers: {},
        })
            .then((response) => response.json())
            .then((filters) => {
                filterDispatch({
                    type: "SET_FILTERS",
                    payload: filters,
                });
            })
            .catch((e) => console.warn("Failed to get filters list:", e));

        fetch(`${config.api}api/v1/Projects/${projectId}/ColorFilters`, {
            method: "GET",
            mode: "cors",
            headers: {},
        })
            .then((response) => response.json())
            .then((filters) => {
                filterDispatch({
                    type: "SET_COLOR_FILTERS",
                    payload: filters,
                });
            })
            .catch((e) => console.warn("Failed to get filters list:", e));
    }, [project]);

    useEffect(() => {
        if (project.name === asText(document.data.BusyLoading || !project)) return;

        const options = {
            headers: {
                "Content-Type": "application/json",
            },
            method: "GET",
        };

        fetch(`${config.api}api/v1/Projects/${projectId}/Views`, options)
            .then((response) => response.json())
            .then(parseViews)
            .catch((e) => console.warn("Failed to obtain views:", e));
    }, [project.id]);

    useEffect(() => {
        if (!isAuthenticated) return;

        if (isDemoProjectRef.current) navigate(generatePath(config.routes.landing[language], { ...params }));
    }, [isAuthenticated]);

    const onScroll = (e) => {
        if (!headerRef.current) return;
        setViewerInView(window.scrollY >= headerRef.current.offsetTop - 10);
    };

    const parseViews = (views) => {
        const options = {
            method: "GET",
            headers: {},
        };

        fetch(`${config.api}api/v1/Projects/${projectId}/SharedViews`, options)
            .then((response) => response.json())
            .then((sharedViews) => {
                const allViews = views.map((x) => {
                    return { ...x, users: [] };
                });

                const availableViews = [];

                for (let i = 0; i < sharedViews.length; i++) {
                    const sharedView = sharedViews[i];
                    const view = allViews.find((x) => x.id === sharedView.viewId);

                    if (!view) continue;

                    for (let j = 0; j < sharedView.invitees.length; j++) {
                        const invitee = sharedView.invitees[j];
                        availableViews.push(sharedView.viewId);
                        view.users.push(invitee.email);
                    }
                }

                const visibleViews = modelState.isSharedProject
                    ? allViews.filter((x) => availableViews.some((y) => y === x.id))
                    : allViews;

                modelDispatch({ type: "SET_VIEWS", payload: visibleViews });
                modelDispatch({ type: "SET_SHARED_VIEWS", payload: sharedViews });

                if (location.search && location.search.indexOf("?view=") !== -1) {
                    const params = new URLSearchParams(location.search);
                    const viewId = params.get("view");

                    modelDispatch({ type: "CHANGE_SIDEBAR_TAB", payload: VIEWS_TAB });
                    modelDispatch({ type: "SET_ACTIVE_VIEW", payload: viewId });

                    params.delete("view");
                    navigate(generatePath(location, { ...params }), { search: params.toString() });
                }
            })
            .catch((e) => console.warn("Failed to obtain views:", e));
    };

    const updateProject = (response) => {
        setIsSavingEdit(true);

        const body = response.projectBody;
        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        };

        fetch(`${config.api}api/v1/Projects/${projectId}`, options).then((response) => {
            setIsSavingEdit(false);

            if (!response.ok) return;

            const updatedProject = {
                ...project,
                name: body.name,
                description: body.description,
            };

            setProject(updatedProject);
            modelDispatch({
                type: "UPDATE_PROJECT",
                payload: updatedProject,
            });

            setIsEdittingProject(false);
        });
    };

    const deleteProject = (confirmed = false, result = false) => {
        if (!confirmed) {
            setWarning({
                visible: true,
                prompt: true,
                title: asText(document.data.RemoveProjectTitle),
                text: asText(document.data.RemoveNameDesc).replace("{{name}}", project.name),
                callback: (bool) => deleteProject(true, bool),
            });

            return;
        }

        setWarning({ visible: false });

        if (!result) return;

        const options = {
            method: "DELETE",
            headers: {},
        };

        fetch(`${config.api}api/v1/Projects/${project.id}`, options).then((response) => {
            if (response.ok) {
                modelDispatch({
                    type: "REMOVE_PROJECT",
                    payload: project.id,
                });

                navigate(generatePath(config.routes.landing[language], { ...params }));
            }
        });
    };

    const toggleFullscreen = () => {
        const elem = window.document.getElementById("viewer-content");
        const headerElem = window.document.getElementById("viewer-header");

        if (window.document.fullscreenElement === elem) {
            window.document.exitFullscreen();
            headerElem.scrollIntoView();
            return;
        }

        if (window.document.mozFullScreenElement === elem) {
            window.document.mozCancelFullScreen();
            headerElem.scrollIntoView();
            return;
        }

        if (window.document.msFullscreenElement === elem) {
            window.document.msExitFullscreen();
            headerElem.scrollIntoView();
            return;
        }

        if (window.document.webkitFullscreenElement === elem) {
            window.document.webkitExitFullscreen();
            headerElem.scrollIntoView();
            return;
        }

        window.scrollTo(0, 0);

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            /* IE/Edge */
            elem.msRequestFullscreen();
        }
    };

    const handleScrollButton = () => {
        if (viewerInView) {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        } else {
            headerRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    return isLoading ? (
        <Spinner />
    ) : hasAccess ? (
        <IfcViewerContextProvider>
            {/* TODO Replace this implementation with something custom */}
            {/* <Prompt
                when={modelState.models.some((x) => x.upload && x.upload.progress < 100)}
                message={asText(document.data.UploadWillBeCancelled)}
            /> */}

            {project?.name && (
                <Breadcrumb>
                    <Link
                        id="project-viewer-breadcrumb-project-link"
                        to={generatePath(config.routes.project[language], { ...params })}
                    >
                        {project.name}
                    </Link>
                </Breadcrumb>
            )}

            <div id="viewer-header" ref={headerRef}>
                <h1>{project && project.name}</h1>
                <div id="action-buttons">
                    {!modelState.isSharedProject && isAuthenticated && (
                        <Fragment>
                            <Button
                                id="project-viewer-project-delete-button"
                                variant="tertiary"
                                onClick={() => deleteProject()}
                                style={{ marginRight: "15px" }}
                                startIcon={<Trash2 />}
                            >
                                {asText(document.data.Delete)}
                            </Button>
                            <Button
                                id="project-viewer-edit-details-button"
                                variant="secondary"
                                onClick={() => setIsEdittingProject(true)}
                                style={{ marginRight: "15px" }}
                                startIcon={<Edit />}
                            >
                                {asText(document.data.EditDetails)}
                            </Button>
                            <div className="vertical-spacer"></div>
                        </Fragment>
                    )}

                    {!isAuthenticated && (
                        <Button
                            id="project-viewer-login-button"
                            variant="primary"
                            onClick={() =>
                                login(() => navigate(generatePath(config.routes.landing[language], { ...params })))
                            }
                            style={{ marginRight: "15px" }}
                        >
                            {asText(document.data.Login)}
                        </Button>
                    )}

                    <Button
                        id="project-viewer-fullscreen-toggle-button"
                        variant="secondary"
                        onClick={() => toggleFullscreen()}
                        style={{ marginRight: "15px" }}
                        startIcon={<FontAwesomeIcon icon={faExpandArrows} />}
                    >
                        {asText(document.data.FullScreen)}
                    </Button>

                    <div className="vertical-spacer"></div>

                    <Tutorial />

                    <IconButton variant="secondary" onClick={() => handleScrollButton()} id="viewer-in-view-scroller">
                        {viewerInView ? <ArrowUp /> : <ArrowDown />}
                    </IconButton>
                </div>

                <div className="break"></div>

                {modelState.isSharedProject ? (
                    <p className="tag shared">{project.ownerEmail}</p>
                ) : (
                    <p className="tag">
                        {project.isShared ? asText(document.data.Shared) : asText(document.data.NotShared)}
                    </p>
                )}
            </div>

            <div id="viewer-page">
                <div id="viewer-content">
                    <Sidebar />
                    <Viewer />
                </div>
            </div>

            {warning.visible && (
                <Warning promp={warning.prompt} title={warning.title} text={warning.text} callBack={warning.callback} />
            )}

            {isEdittingProject && (
                <PopupProject
                    title={asText(document.data.EditDetails)}
                    project={project}
                    onClose={() => {
                        setIsEdittingProject(false);
                        setIsSavingEdit(false);
                    }}
                    onSave={updateProject}
                    isSaving={isSavingEdit}
                />
            )}
        </IfcViewerContextProvider>
    ) : (
        <Content>
            <ContentBody>
                <Panel>
                    <PanelHeader>
                        <h2>{asText(document.data.ProjectNotFound)}</h2>
                    </PanelHeader>
                    <PanelBody>{asText(document.data.ProjectNotFoundDetails)}</PanelBody>
                </Panel>
            </ContentBody>
        </Content>
    );
};

export default ProjectViewer;
