export const TUTORIAL = [
    {
        type: "dialog",
        scrollTo: "viewer-header",
    },
    {
        type: "selection",
        element: "#viewer-canvas",
        scrollTo: "viewer-header",
    },
    {
        type: "selection",
        element: "#viewer-navcube-canvas",
        scrollTo: "viewer-header",
    },
    {
        type: "selection",
        element: ".actionbar",
        scrollTo: "viewer-header",
    },
    {
        type: "selection",
        element: ".actionbar:nth-child(2)",
        scrollTo: "viewer-header",
    },
    {
        type: "selection",
        element: "#action-bar-property-viewer-toggle-button",
        scrollTo: "viewer-header",
    },
    {
        type: "selection",
        element: "#action-bar-tv-search-toggle-button",
        scrollTo: "viewer-header",
    },
    {
        type: "selection",
        element: "#action-bar-export-modal-button",
        scrollTo: "viewer-header",
    },
    {
        type: "selection",
        element: "#active-model-tree-tab-set-button",
        scrollTo: "viewer-header",
        placement: "right",
        performClick: true,
    },
    {
        type: "selection",
        element: "#tab-content-filters-holder",
        scrollTo: "viewer-header",
    },
    {
        type: "selection",
        element: ".model-tree",
        scrollTo: "viewer-header",
    },
    {
        type: "selection",
        element: "#tab-subtabs",
        scrollTo: "viewer-header",
    },
    {
        type: "selection",
        element: "#view-info",
        scrollTo: "viewer-header",
    },
    {
        type: "selection",
        element: "#active-views-tab-set-button",
        scrollTo: "viewer-header",
        placement: "right",
        performClick: true,
    },
    {
        type: "selection",
        element: "#active-model-tab-set-button",
        scrollTo: "viewer-header",
        placement: "right",
        performClick: true,
    },
    {
        type: "selection",
        element: "div[data-for=model-upload-button]",
        scrollTo: "viewer-header",
    },
    {
        type: "selection",
        element: "#project-viewer-login-button",
        scrollTo: "viewer-header",
    },
    {
        type: "selection",
        element: "#action-buttons button:last-child",
        scrollTo: "viewer-header",
    },
    {
        type: "selection",
        element: "#site-app-nav",
        scrollTo: "site-header",
    },
    {
        type: "selection",
        element: "#tutorial-button",
        scrollTo: "viewer-header",
    },
];
