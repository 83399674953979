import { useEffect, useRef, useState } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { IconButton, usePrismic } from "@buildwise/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/pro-regular-svg-icons";
import { Tooltip } from "./Components/Tooltip";
import { BeaconComponent } from "./Components/Beacon";

import { config } from "../../_configuration/configuration";
import { TUTORIAL } from "./Steps";

const TUTORIAL_STORAGE_KEY = "bimio-tutorial-autorun";

const Tutorial = () => {
    const [prismicDocument] = usePrismic(config.prismic.documentType);
    const [run, setRun] = useState(false);
    const [steps, setSteps] = useState([]);
    const [index, setIndex] = useState(0);

    const joyrideRef = useRef(null);

    useEffect(() => {
        const steps = [];

        for (let i = 0; i < TUTORIAL.length; i++) {
            const step = TUTORIAL[i];
            const value = getText(i);

            const joyrideStep = {
                content: value,
                spotlightPadding: 10,
            };

            switch (step.type) {
                case "dialog":
                    joyrideStep.target = "body";
                    joyrideStep.placement = "center";
                    break;
                case "selection":
                    joyrideStep.target = step.element;
                    if (step.placement) joyrideStep.placement = step.placement;
                    break;
                default:
                    break;
            }

            steps.push(joyrideStep);
        }

        setSteps(steps);
    }, [prismicDocument]);

    useEffect(() => {
        const hasRun = localStorage.getItem(TUTORIAL_STORAGE_KEY) === "1";
        setRun(!hasRun);
    }, []);

    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;

        const currentStep = TUTORIAL[index];
        if (currentStep) {
            if (currentStep?.scrollTo)
                document.getElementById(currentStep.scrollTo).scrollIntoView({ behavior: "smooth" });
        }

        if (ACTIONS.CLOSE === action) {
            setIndex(index);
            if (index === 0) setRun(false);
        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            const nextIndex = index + (action === ACTIONS.PREV ? -1 : 1);
            const nextStep = TUTORIAL[nextIndex];
            if (nextStep?.performClick) document.querySelector(nextStep.element).click();

            setIndex(nextIndex);
        } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setIndex(0);
            setRun(false);
            localStorage.setItem(TUTORIAL_STORAGE_KEY, "1");
        }
    };

    const getText = (step) =>
        prismicDocument.data.tutorial_steps[step]?.value ?? { type: "paragraph", text: step, spans: [] };

    return (
        <>
            <IconButton
                style={{ marginRight: "15px", position: "relative" }}
                variant="secondary"
                id="tutorial-button"
                onClick={() => {
                    if (!run) setRun(true);
                    else joyrideRef.current?.helpers.open();
                }}
            >
                <FontAwesomeIcon icon={faQuestion} style={{ fontSize: 18, height: 20 }} />
            </IconButton>

            <Joyride
                ref={joyrideRef}
                callback={handleJoyrideCallback}
                beaconComponent={BeaconComponent}
                tooltipComponent={Tooltip}
                continuous
                hideCloseButton
                run={run}
                scrollToFirstStep
                showSkipButton
                disableScrolling={true}
                disableScrollParentFix
                steps={steps}
                stepIndex={index}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                }}
                locale={{
                    close: prismicDocument.data.tutorial_close,
                    next: prismicDocument.data.tutorial_next,
                    skip: prismicDocument.data.tutorial_skip,
                }}
                floaterProps={{ hideArrow: index === 1 }}
            />
        </>
    );
};

export default Tutorial;
