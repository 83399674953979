import { useContext, useEffect } from "react";
import { IfcViewerContext } from "../../contexts/IfcViewerContextProvider";
import { NavCubePlugin } from "@xeokit/xeokit-sdk";

const IfcNavCube = (props) => {
    const { state, dispatch } = useContext(IfcViewerContext);
    const { viewer, navCube, plugins } = state;
    const { navCube: navCubePlugin } = plugins;
    const { canvasId } = props.cfg;

    // Setup the NavCubePlugin if the viewer has been setup and there is no instance of NavCubePlugin
    useEffect(() => {
        if (viewer === null || navCubePlugin !== null) {
            return;
        }

        const { canvasId } = props.cfg;

        if (canvasId === undefined || canvasId === null) {
            throw new Error("`IfcNavCube` requires a canvas id to be set.");
        }

        dispatch({
            type: "SET_NAV_CUBE_PLUGIN",
            payload: new NavCubePlugin(viewer, {
                canvasId: canvasId,
                visible: navCube.visible,
                ...props.cfg,
            }),
        });
    }, [viewer]);

    // Handle visibility toggle of the NavCube
    useEffect(() => {
        if (navCubePlugin === null) {
            return;
        }

        navCubePlugin.setVisible(navCube.visible);
    }, [navCube.visible]);

    return <canvas id={canvasId} />;
};

export default IfcNavCube;
