import { Fragment, useState, useEffect } from "react";
import TreeItem from "./TreeItem";

const Tree = (props) => {
    const {
        items,
        search,
        filter,
        onItemChecked,
    } = props;
    const [, forceRender] = useState(0);

    useEffect(() => {
        if (!filter) return;
        applyFilter();
    }, [filter]);

    const applyFilter = () => {
        handleFilter(items);
        forceRender((x) => x + 1);
    };

    const handleFilter = (array) => {
        for (let i = 0; i < array.length; i++) {
            const item = array[i];
            if (filter.length <= 2) {
                item.match = false;
                item.expanded = false;
            } else item.match = search(item, filter);

            if (item.children) {
                handleFilter(item.children);

                if (item.children.some((x) => x.match || x.expanded)) {
                    item.expanded = true;
                } else {
                    item.expanded = false;
                }
            }
        }
    };

    const toggleExpand = (item) => {
        item.expanded = !item.expanded;
        forceRender((cnt) => cnt + 1);
    };

    const checkboxChanged = (item, checked) => {
        updateItems(item, checked);
        verifyCheckboxHierarchy(item);

        forceRender((cnt) => cnt + 1);
        onItemChecked && onItemChecked(item, checked);
    };

    const updateItems = (item, checked) => {
        item.status = checked ? "checked" : "unchecked";

        if (item.children && item.children.length > 0) {
            for (let i = 0; i < item.children.length; i++) {
                const child = item.children[i];
                updateItems(child, checked);
            }
        }
    };

    const verifyCheckboxHierarchy = (item) => {
        const parent = item.parent;
        if (!parent) return;

        if (parent.children) {
            let checked = 0;
            let indeterminate = 0;

            for (let i = 0; i < parent.children.length; i++) {
                const child = parent.children[i];
                if (child.status === "checked") checked++;
                if (child.status === "indeterminate") indeterminate++;
            }

            if (checked === 0 && indeterminate === 0) {
                parent.status = "unchecked";
            } else {
                if (checked === parent.children.length)
                    parent.status = "checked";
                else parent.status = "indeterminate";
            }
        }

        verifyCheckboxHierarchy(parent);
    };

    const createTreeItems = (items, parent, level) => {
        return items.map((item, index) => {
            item.parent = parent;

            return (
                <Fragment key={index}>
                    <TreeItem
                        {...props}
                        item={item}
                        level={level}
                        parent={parent}
                        onToggleExpand={toggleExpand}
                        onCheckboxChanged={checkboxChanged}
                    />
                    {item.children && item.children.length > 0 && item.expanded
                        ? createTreeItems(item.children, item, level + 1)
                        : undefined}
                </Fragment>
            );
        });
    };

    return items && items.length > 0 ? createTreeItems(items, null, 0) : null;
};

export default Tree;
