import { useRef, useEffect, useState } from "react";
import * as Icon from "react-feather";

const TreeItem = (props) => {
    const [indentation, setIndentation] = useState(30);

    useEffect(() => {
        if (props.indentation) setIndentation(props.indentation);
    }, []);

    const {
        item,
        level,
        actions,
        selectable,
        render,
        onToggleExpand,
        onCheckboxChanged,
    } = props;

    const renderExpander = (item) => {
        return (
            <div
                className="tree-item-expander"
                // onClick={() => onToggleExpand(item)}
            >
                {item.children && item.children.length > 0 ? (
                    item.expanded ? (
                        <Icon.ChevronDown />
                    ) : (
                        <Icon.ChevronRight />
                    )
                ) : null}
            </div>
        );
    };

    const renderCheckbox = (item) =>
        selectable ? (
            <div className="tree-item-checkbox">
                <IndeterminateCheckbox
                    checked={item.status === "checked"}
                    indeterminate={
                        item.status === "indeterminate"
                            ? item.status
                            : undefined
                    }
                    style={{
                        display: "inline-block",
                        WebkitAppearance: "checkbox",
                    }}
                    onChange={(e) => onCheckboxChanged(item, e.target.checked)}
                />
            </div>
        ) : null;

    const renderItem = (item) =>
        render && <div className="tree-item-value">{render(item)}</div>;

    const renderActions = (item) =>
        actions && (
            <div
                className="tree-item-actions"
                onClick={(e) => e.stopPropagation()}
            >
                {actions(item)}
            </div>
        );

    return item.visible !== false ? (
        <div
            className="tree-item"
            onClick={() => onToggleExpand(item)}
            style={{ paddingLeft: `${level * indentation}px` }}
        >
            {renderExpander(item)}
            {renderCheckbox(item)}
            {renderItem(item)}
            {renderActions(item)}
        </div>
    ) : null;
};

export default TreeItem;

const IndeterminateCheckbox = (props) => {
    const checkbox = useRef();

    useEffect(() => {
        checkbox.current.indeterminate = props.indeterminate;
    }, [props.indeterminate]);

    const { ...attrs } = props;
    return (
        <label className="container">
            <input ref={checkbox} type="checkbox" {...attrs} />
            <span className="checkmark"></span>
        </label>
    );
};
