import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Text, usePrismic } from "@buildwise/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { asText } from "@prismicio/helpers";

import { ModelViewerContext } from "../../../contexts/ModelViewerContextProvider";

import { config } from "../../../_configuration/configuration";

const ShareProject = (props) => {
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);

    const [email, setEmail] = useState("");
    const [emails, setEmails] = useState([]);
    const [remarks, setRemarks] = useState();
    const [canSave, setCanSave] = useState(false);
    const [isSaving, setIsSaving] = useState();
    const [error, setError] = useState(null);
    const emailInputRef = useRef();

    const { id: projectId } = useParams();
    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        if (!emailInputRef.current) return;

        if (error) emailInputRef.current.showError();
        else emailInputRef.current.clearError();
    }, [error]);

    useEffect(() => setCanSave(emails.length > 0), [emails]);

    const handleKeyDown = (e) => {
        if (["Enter", "Tab", ",", ";", " "].includes(e.key)) {
            e.preventDefault();

            const value = email.trim();
            if (value && isValid(value)) {
                const list = [...emails];
                list.push(value);
                setEmails(list);
                setEmail("");
            }
        } else setError(null);
    };

    const onRemove = (email) => {
        setEmails(emails.filter((x) => x !== email));
    };

    const onPaste = (e) => {
        e.preventDefault();

        const pastedValue = e.clipboardData.getData("text");
        const pastedEmails = pastedValue.match(
            /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g
        );

        if (pastedEmails) {
            const toBeAdded = pastedEmails.filter((email) => !isInList(email));
            setEmails([...emails, ...toBeAdded]);
        }
    };

    const isValid = (value) => {
        if (value.length === 0) return true;

        if (isInList(value)) {
            setError("EmailAlreadyInList");
            return false;
        }

        if (!isEmail(value)) {
            setError("EmailInvalid");
            return false;
        }

        setError(null);
        return true;
    };

    const isInList = (email) => {
        return emails.includes(email);
    };

    const isEmail = (email) => {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
        );
    };

    const onShare = () => {
        setIsSaving(true);

        const alreadyShared = modelState.sharedViews.find((x) => x.viewId === props.view.id);
        if (alreadyShared) updateSharedView(alreadyShared);
        else shareView();
    };

    const shareView = () => {
        const view = modelState.views.find((x) => x.id === props.view.id);
        if (emails.length > 0) {
            const options = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: view.name,
                    viewId: view.id,
                    emailAddresses: emails,
                    additionalMessage: remarks,
                }),
            };

            fetch(`${config.api}api/v1/Projects/${projectId}/SharedViews`, options)
                .then((result) => {
                    if (!result.ok) return;
                    return result.json();
                })
                .then((json) => {
                    if (!json) return;
                    modelDispatch({
                        type: "ADD_SHARED_VIEW",
                        payload: json,
                    });

                    modelDispatch({ type: "SET_PROJECT_SHARED", payload: { id: projectId, status: true } });
                    modelDispatch({
                        type: "UPDATE_VIEWS_USERS",
                        payload: [{ view: view.id, toAdd: emails, toRemove: [] }],
                    });
                    setIsSaving(false);
                    props.onClose();
                });
        }
    };

    const updateSharedView = (sharedView) => {
        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: emails.join(","),
                message: remarks,
            }),
        };

        fetch(`${config.api}api/v1/Projects/${projectId}/SharedViews/${sharedView.id}/Invitees`, options).then(
            (result) => {
                if (!result.ok) return;
                modelDispatch({ type: "SET_PROJECT_SHARED", payload: { id: projectId, status: true } });
                modelDispatch({
                    type: "UPDATE_VIEWS_USERS",
                    payload: [{ view: props.view.id, toAdd: emails, toRemove: [] }],
                });

                options = {
                    method: "GET",
                    headers: {},
                };
                fetch(`${config.api}api/v1/Projects/${projectId}/SharedViews`, options)
                    .then((response) => response.json())
                    .then((sharedViews) => {
                        modelDispatch({
                            type: "SET_SHARED_VIEWS",
                            payload: sharedViews.filter((x) => x.invitees.length > 0),
                        });

                        setIsSaving(false);
                        props.onClose();
                    })
                    .catch((e) => console.warn("Failed to obtain shared views:", e));
            }
        );
    };

    return (
        <Modal size="small" onClose={() => props.onClose()} open={true} backdrop>
            <ModalHeader closeButton>
                <ModalTitle>{asText(document.data.ShareViewsWithContactsTitle)}</ModalTitle>
            </ModalHeader>

            <ModalBody>
                <div>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: asText(document.data.ShareViewsWithContactsInfo),
                        }}
                    ></p>
                </div>

                <div>
                    <strong style={{ margin: "0 0 8px 16px", display: "block" }}>
                        {asText(document.data.MailaddressesOfContactsToShare)}
                    </strong>
                    <div>
                        <span className="chips">
                            {emails.map((email, index) => (
                                <span key={index} className="chip">
                                    {email}
                                    <FontAwesomeIcon icon={faTimes} onClick={(e) => onRemove(email)} />
                                </span>
                            ))}
                        </span>
                    </div>
                    <Text
                        type="text"
                        placeholder={asText(document.data.MailaddressesPlaceholder)}
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onPaste={(e) => onPaste(e)}
                        validationError={asText(document.data[error])}
                        ref={emailInputRef}
                        onBlur={() => {
                            const value = email.trim();

                            if (value && isValid(value)) {
                                const list = [...emails];
                                list.push(value);
                                setEmails(list);
                                setEmail("");
                            }
                        }}
                    />
                </div>

                <div>
                    <strong style={{ margin: "0 0 8px 16px" }}>{asText(document.data.ShareRemarks)}</strong>
                    <Text
                        type="textarea"
                        placeholder={asText(document.data.ShareRemarksPlaceholder)}
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                    />
                </div>
            </ModalBody>

            <ModalFooter>
                <Button id="share-project-cancel-button" variant="tertiary" onClick={() => props.onClose()}>
                    {asText(document.data.Cancel)}
                </Button>
                <Button id="share-project-share-button" onClick={() => onShare()} disabled={isSaving || !canSave}>
                    {isSaving ? asText(document.data.BusySharing) : asText(document.data.Share)}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ShareProject;
