import { useContext } from "react";
import { ModelViewerContext } from "../../contexts/ModelViewerContextProvider";

export const ModelIndex = {
    TvSearch: 1,
    SectionPlaneManagement: 2,
    ElementProperties: 3,
};

export const useIndexedModal = (index) => {
    const { state: modelState } = useContext(ModelViewerContext);
    const zIndex = modelState.modalOrder.indexOf(index) + 16;

    return { zIndex };
};
