import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ChevronDown, ChevronRight, X } from "react-feather";
import ReactTooltip from "react-tooltip";
import FileSaver from "file-saver";
import {
    Button,
    Checkbox,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    Portal,
    Spinner,
    Text,
    useAuthentication,
    usePrismic,
} from "@buildwise/ui";
import { asText } from "@prismicio/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faGripLines, faTrash } from "@fortawesome/pro-light-svg-icons";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";

import Tree from "../../../../components/Tree";
import Highlight from "../../../../components/Highlighter";

import { ModelViewerContext } from "../../../../contexts/ModelViewerContextProvider";

import {
    createExportConfiguration,
    deleteExportConfiguration,
    generateExport,
    getExportConfigurations,
    updateExportConfiguration,
} from "../../../../adapters/ExportAdapter";

import { config } from "../../../../_configuration/configuration";

import styles from "./ExportConfigurator.module.css";

const generateUUID = () => {
    let d = new Date().getTime();
    let d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0;
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        let r = Math.random() * 16;
        if (d > 0) {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }

        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
};

const matches = (text, partial) => text.toLowerCase().indexOf(partial.toLowerCase()) > -1;

const ExportConfigurator = () => {
    const [ifcdocument] = usePrismic("ifc_definitions");
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchValue, setSearchValue] = useState("");

    const [name, setName] = useState("");
    const [editName, setEditName] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [configurations, setConfigurations] = useState([]);

    const [isFetching, setIsFetching] = useState(true);
    const [isExporting, setIsExporting] = useState(false);
    const [tree, setTree] = useState([]);

    const [configName, setConfigName] = useState("");
    const [showConfigNameModal, setShowConfigNameModal] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const { id: projectId } = useParams();
    const { isAuthenticated } = useAuthentication();

    const [expandedTreeIndices, setExpandedTreeIndices] = useState({});
    const [, forceRender] = useState(0);
    const searchTimeoutRef = useRef();

    const [document] = usePrismic(config.prismic.documentType);

    useLayoutEffect(() => {
        window.document.body.classList.toggle("noexportscroll", modelState.export);
        setSelectedItem();
    }, [modelState.export]);

    useLayoutEffect(() => {
        if (modelState.export) {
            getIfcData();

            if (modelState.exportView) setName(document.data.export_qto_active_view);
            else {
                const info = modelState.models.find((x) => x.id === modelState.exportModels[0]);
                setName(info.name);
            }

            getExportConfigurations().then(setConfigurations);
        }
    }, [modelState.export]);

    useEffect(() => {
        if (!isFetching && selectedItem) {
            const configuration = configurations.find((x) => x.id === selectedItem.id);
            setName(configuration.name);
            applyConfiguration(configuration);
        }
    }, [selectedItem, isFetching]);

    useEffect(() => {
        clearTimeout(searchTimeoutRef.current);
        searchTimeoutRef.current = setTimeout(() => {
            setSearchValue(searchQuery);
        }, 300);
    }, [searchQuery]);

    const getIfcData = () => {
        setIsFetching(true);
        fetch(`${config.api}api/v1/Projects/${projectId}/Filters/FilterProperties/ByClass`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(modelState.exportModels),
        })
            .then((response) => response.json())
            .then((json) => {
                const tree = propertiesToTree(json);
                setTree(tree);
                setIsFetching(false);
            })
            .catch((err) => console.warn("Error attempting to get filter classes/properties:", err));
    };

    const propertiesToTree = (data, visible = true) => {
        const tree = [];

        for (let i = 0, len = data.length; i < len; i++) {
            const obj = data[i];
            if (!obj.name) continue;

            let type;
            let children = [];
            if (obj.hasOwnProperty("propertySets")) {
                type = "IfcClass";

                let visible = false;
                if (modelState.exportView && modelState.filterGuids && modelState.filterGuids.length > 0) {
                    visible = elementsInIfcClassVisible(obj.name);
                } else {
                    visible = true;
                }

                children = propertiesToTree(obj.propertySets, visible);
            } else if (obj.hasOwnProperty("properties")) {
                type = "PropertySet";
                children = propertiesToTree(obj.properties, visible);
            } else {
                type = "Property";
            }
            let name = obj.name;
            if (ifcdocument?.data[name]) {
                name = `${ifcdocument.data[name]} (${name})`;
            }
            tree.push({
                identifier: generateUUID(),
                name,
                type,
                children,
                originalName: obj.name,
                checked: visible,
            });
        }

        return tree.sort((a, b) => a.name.localeCompare(b.name));
    };

    const elementsInIfcClassVisible = (ifcClass) => {
        const classTree = modelState.mergedClassTree.children.find((x) => x.name === ifcClass);
        if (!classTree) return false;

        let found = false;
        for (let i = 0; i < classTree.children.length; i++) {
            const group = classTree.children[i];

            if (group.isType) {
                found = group.children.some((x) => modelState.filterGuids.some((y) => y === x.id));
                if (found) break;
            }
        }

        return found;
    };

    const toggleExpandedIndex = (index, isExpanded) => {
        const indices = JSON.parse(JSON.stringify(expandedTreeIndices || {}));
        if (isExpanded) {
            delete indices[index];
            Object.keys(indices).forEach((expandedTreeIndex) => {
                if (expandedTreeIndex.startsWith(index)) {
                    delete indices[expandedTreeIndex];
                }
            });
            const getParentIndex = (index) => index.split("-").slice(0, -1).join("-");
            let parentIndex = getParentIndex(index);
            while (parentIndex) {
                indices[parentIndex] = true;
                parentIndex = getParentIndex(parentIndex);
            }
        } else {
            indices[index] = true;
        }

        setExpandedTreeIndices(indices);
    };

    const getChecked = (item) => {
        function* flatten(array, depth) {
            if (depth === undefined) depth = 1;
            for (const item of array) {
                if (Array.isArray(item.children) && item.children.length > 0 && depth > 0) {
                    yield* flatten(item.children, depth - 1);
                } else {
                    yield item;
                }
            }
        }

        const flat = [...flatten(item.children, Infinity)];
        if (flat.every(({ checked }) => checked)) return true;
        if (flat.some(({ checked }) => checked)) return "indeterminate";
        return false;
    };

    const getGlobalCheckedStatus = () => {
        if (!tree) return;

        let previousValue = -1;

        for (let i = 0; i < tree.length; i++) {
            const ifcClass = tree[i];
            const checked = getChecked(ifcClass);
            if (previousValue !== -1 && previousValue !== checked) return "indeterminate";
            previousValue = checked;
        }

        return previousValue;
    };

    const toggleGlobalCheckbox = () => {
        if (!tree) return;
        const status = getGlobalCheckedStatus();

        for (let i = 0; i < tree.length; i++) {
            checkGroup(tree[i], status);
        }

        forceRender((i) => i + 1);
    };

    const checkGroup = (item, status) => {
        let checked;
        if (status === "indeterminate") checked = false;
        else checked = status;

        for (let i = 0, len = item.children.length; i < len; i++) {
            const element = item.children[i];
            if (element.children && element.children.length > 0) checkGroup(element, status);
            else element.checked = !checked;
        }
    };

    const filterChecked = (data, selected = []) => {
        return data.reduce((selected, item) => {
            const clone = {};

            if (item.type === "Property") {
                if (item.checked) return [...selected, { name: item.name }];
            } else {
                if (item.children && item.children.length) {
                    const nameName = item.type === "IfcClass" ? "ifcClass" : "name";
                    const childrenName = item.type === "IfcClass" ? "propertySets" : "properties";
                    const children = filterChecked(item.children, []);
                    clone[nameName] = item.originalName ?? item.name;
                    clone[childrenName] = children;

                    if (children.length > 0) return [...selected, clone];
                }
            }

            return selected;
        }, selected);
    };

    const applyConfiguration = (configuration) => {
        for (let i = 0; i < tree.length; i++) {
            const item = tree[i];
            const configItem = configuration.ifcClasses.find((x) => x.ifcClass === item.name);

            if (!configItem) {
                checkGroup(item, true);
                continue;
            }

            if (item.children) {
                let allPropertySetsSelected = true;
                for (let j = 0; j < item.children.length; j++) {
                    const propertySet = item.children[j];
                    const configPropertySet = configItem.propertySets.find((x) => x.name === propertySet.name);

                    if (!configPropertySet) {
                        allPropertySetsSelected = false;
                        checkGroup(propertySet, true);
                        continue;
                    }

                    let allPropertiesSelected = true;
                    for (let k = 0; k < propertySet.children.length; k++) {
                        const property = propertySet.children[k];
                        const configProperty = configPropertySet.properties.find((x) => x.name === property.name);

                        if (!configProperty) {
                            allPropertiesSelected = false;
                            property.checked = false;
                            continue;
                        }

                        property.checked = true;
                    }

                    propertySet.checked = allPropertiesSelected;
                }

                item.checked = allPropertySetsSelected;
            }
        }

        forceRender((x) => x + 1);
    };

    const onSaveConfiguration = () => {
        setIsCreating(true);
        setShowConfigNameModal(true);
    };

    const onCreateConfiguration = () => {
        setShowConfigNameModal(false);
        createExportConfiguration({ name: configName, ifcClasses: filterChecked(tree) }).then((result) => {
            setConfigName("");
            setConfigurations((x) => [...x, result]);
            setName(result.name);
            setSelectedItem({ id: result.id, name: result.name });
            setIsCreating(false);
        });
    };

    const onUpdateConfiguration = () => {
        if (!selectedItem) return;
        const config = configurations.find((x) => x.id === selectedItem.id);

        setIsUpdating(true);
        updateExportConfiguration(config.id, { name, ifcClasses: filterChecked(tree) }).then((result) => {
            const configs = [...configurations];
            const toUpdate = configs.find((x) => x.id === result.id);
            toUpdate.name = result.name;
            toUpdate.ifcClasses = result.ifcClasses;
            setConfigurations(configs);
            setIsUpdating(false);
        });
    };

    const onRemoveConfiguration = (item) => {
        setIsDeleting(true);
        deleteExportConfiguration(item.id).then((x) => {
            setConfigurations((x) => x.filter((y) => y.id !== item.id));
            if (selectedItem.id === item.id) setSelectedItem();
            setIsDeleting(false);
        });
    };

    const onExport = () => {
        const activeModels = modelState.exportModels;
        const activeFilter = modelState.exportView ? modelState.activeFilter : null;

        setIsExporting(true);

        generateExport(projectId, {
            models: activeModels,
            filterId: activeFilter,
            ifcClasses: filterChecked(tree),
        }).then((blob) => {
            FileSaver.saveAs(blob, `export.xlsx`);
            setIsExporting(false);
        });
    };

    const checkForSearchQuery = (item, isGroup) => {
        if (searchValue.length < 3) return item.expanded;

        if (isGroup) {
            return item.children.some(
                (x) =>
                    matches(x.name, searchValue) ||
                    checkForSearchQuery(x, x.type === "IfcClass" || x.type === "PropertySet")
            );
        }

        return matches(item.name, searchValue);
    };

    const renderTree = () => {
        const renderGroup =
            (level = 0, prevTreeIndex = [], parentId = 0) =>
            (item, index) => {
                const treeIndex = [...prevTreeIndex, index];
                const treeIndexString = getTreeIndexString(treeIndex);
                const key = `Layers__renderThemes__renderGroup__${treeIndexString}`;
                const isGroup = item.type === "IfcClass" || item.type === "PropertySet";
                const isChecked = isGroup ? getChecked(item) : item.checked === true;
                let style = {
                    paddingLeft: 10 + 25 * level + !isGroup * 35,
                };
                const shouldExpandForSearch = checkForSearchQuery(item, isGroup) && searchQuery.length > 0;
                const isExpanded = item.expanded === true || shouldExpandForSearch;

                switch (level) {
                    case 0:
                        style = { ...style, fontWeight: "bold" };
                        break;
                    case 1:
                        style = { ...style, fontStyle: "italic" };
                        break;
                    case 2:
                        style = { ...style, color: "#666666" };
                        break;
                    default:
                        break;
                }
                return (
                    <Draggable key={item.identifier} draggableId={`${item.identifier}`} index={index}>
                        {(provided, snapshot) => {
                            const classes = [styles.itemContainer];
                            if (snapshot.isDragging) classes.push(styles.isDragging);

                            return (
                                <div
                                    key={key}
                                    className={classes.join(" ")}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                >
                                    {Boolean(isGroup) ? (
                                        <div>
                                            <div
                                                className={styles.titleContainer}
                                                onClick={() => {
                                                    toggleExpandedIndex(treeIndexString, isExpanded);
                                                    item.expanded = !item.expanded;
                                                }}
                                                style={{ ...style, alignItems: "center" }}
                                            >
                                                <div style={{ marginRight: 10, cursor: "pointer" }}>
                                                    {item.children && item.children.length > 0 ? (
                                                        item.expanded ? (
                                                            <ChevronDown />
                                                        ) : (
                                                            <ChevronRight />
                                                        )
                                                    ) : null}
                                                </div>
                                                <Checkbox
                                                    className={styles.checkbox}
                                                    checked={isChecked}
                                                    onChange={(e) => checkGroup(item, isChecked)}
                                                    indeterminate={isChecked === "indeterminate" ? true : false}
                                                />
                                                <div className={styles.title}>
                                                    <Highlight text={item.name} term={searchValue} useSpan />
                                                    <span onClick={(e) => e.stopPropagation()}>
                                                        <div
                                                            className={styles.dragHandle}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <FontAwesomeIcon icon={faGripLines} />
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>

                                            <Droppable droppableId={treeIndexString} type={`group-${item.identifier}`}>
                                                {(provider, snapshot) => {
                                                    return (
                                                        <div ref={provider.innerRef} {...provider.droppableProps}>
                                                            {isExpanded &&
                                                                item.children.map(
                                                                    renderGroup(level + 1, treeIndex, item.identifier)
                                                                )}
                                                            {provider.placeholder}
                                                        </div>
                                                    );
                                                }}
                                            </Droppable>
                                        </div>
                                    ) : (
                                        <div className={[styles.titleContainer, styles.layer].join(" ")} style={style}>
                                            <Checkbox
                                                className={styles.checkbox}
                                                checked={isChecked}
                                                onChange={() => {
                                                    item.checked = !Boolean(item.checked);
                                                    forceRender((x) => x + 1);
                                                }}
                                            />

                                            <span className={styles.title}>
                                                <Highlight text={item.name} term={searchValue} useSpan />
                                            </span>
                                            <span onClick={(e) => e.stopPropagation()}>
                                                <div className={styles.dragHandle} {...provided.dragHandleProps}>
                                                    <FontAwesomeIcon icon={faGripLines} />
                                                </div>
                                            </span>
                                        </div>
                                    )}
                                </div>
                            );
                        }}
                    </Draggable>
                );
            };

        return tree.map(renderGroup());
    };

    const getTreeIndexString = (treeIndex) => {
        return treeIndex.map((index) => `[${index}]`).join("-");
    };

    const handleDragEnd = (result) => {
        const treeCopy = JSON.parse(JSON.stringify(tree));
        if (!result.destination) return;

        if (result.type !== "DEFAULT") {
            if (result.source.droppableId !== result.destination.droppableId) return;

            const levels = result.source.droppableId
                .split("-")
                .map((accessor) => Number(accessor.replace("[", "").replace("]", "")));
            let subList = treeCopy;

            for (let i = 0; i < levels.length; i++) {
                subList = subList[levels[i]].children;
            }

            moveArrayElement(subList, result.source.index, result.destination.index);
        } else {
            moveArrayElement(treeCopy, result.source.index, result.destination.index);
        }

        setTree(treeCopy);
    };

    const moveArrayElement = (arr, old_index, new_index) => {
        while (old_index < 0) old_index += arr.length;
        while (new_index < 0) new_index += arr.length;

        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) arr.push(undefined);
        }

        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    };

    const renderItem = (item) => {
        const classes = [styles.configurationName];
        if (selectedItem?.id && selectedItem.id === item.id) classes.push(styles.selected);

        return (
            <span className={classes.join(" ")} onClick={(e) => setSelectedItem({ ...item })}>
                {item.name}
            </span>
        );
    };

    const renderAction = (item) => {
        // if (item.isGroup || !authState.isAuthenticated) return null;

        return (
            <>
                {/* <button
                    onClick={(e) => {
                        const copy = { ...item, name: `${item.name} - ${asText(document.data.Copy)}` };
                        delete copy.id;
                        delete copy.parent;
                        copy.isPublished = false;

                        set({ ...copy });
                    }}
                >
                    <FontAwesomeIcon icon={faCopy} />
                </button> */}

                {/* <button onClick={() => onRemoveConfiguration(item)} className={item.parent.canRemove ? "" : "disabled"}> */}
                <button
                    id="export-configurator-configuration-remove-button"
                    onClick={() => onRemoveConfiguration(item)}
                >
                    <FontAwesomeIcon
                        icon={faTrash}
                        data-for={`export-tooltips`}
                        data-tip={asText(document.data.Delete)}
                    />
                </button>
            </>
        );
    };

    return (
        modelState.export && (
            <Portal>
                <div className="overlay">
                    <div className={styles.modal}>
                        {isAuthenticated && (
                            <div className={styles.left}>
                                <h2>{document.data.export_qto_title}</h2>
                                <div className={styles.sideContent}>
                                    <div className={styles.tree}>
                                        <Tree
                                            indentation={15}
                                            items={configurations.sort((a, b) => a.name.localeCompare(b.name))}
                                            render={(item) => renderItem(item)}
                                            actions={(item) => renderAction(item)}
                                        />
                                    </div>
                                </div>

                                {/* <div className={styles.action}>
                                <Button
                                block
                                disabled={isCreating || isDeleting || isUpdating}
                                onClick={() => onSaveConfiguration()}
                                startIcon={<Plus />}
                                >
                                {isCreating
                                    ? document.data.export_qto_configuration_is_saving
                                    : document.data.export_qto_configuration_save}
                                    </Button>
                                </div> */}
                            </div>
                        )}

                        <div className={styles.right}>
                            <div className={styles.header}>
                                <div className={styles.editableTitle}>
                                    {selectedItem ? (
                                        editName ? (
                                            <Text
                                                type="text"
                                                autoFocus
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                label={document.data.export_qto_new_profile_name}
                                                onFocus={(e) =>
                                                    name === document.data.export_qto_untitled_profile &&
                                                    e.target.select()
                                                }
                                                onBlur={() => setEditName(false)}
                                            />
                                        ) : (
                                            <h2 className={styles.question} onClick={() => setEditName(true)}>
                                                {name} <FontAwesomeIcon icon={faEdit} />
                                            </h2>
                                        )
                                    ) : (
                                        <h2 className={styles.question}>{name}</h2>
                                    )}
                                </div>
                                <div className={styles.close} onClick={() => modelDispatch({ type: "CLEAR_EXPORT" })}>
                                    <X />
                                </div>
                            </div>

                            {isFetching ? (
                                <div className={styles.body}>
                                    <Spinner />
                                </div>
                            ) : (
                                <>
                                    {/* <div className={styles.body}> */}
                                    <div className={styles.topbar}>
                                        <Checkbox
                                            indeterminate={getGlobalCheckedStatus() === "indeterminate"}
                                            checked={getGlobalCheckedStatus() === true}
                                            onChange={() => toggleGlobalCheckbox()}
                                            label={
                                                getGlobalCheckedStatus() === true
                                                    ? document.data.export_qto_configuration_deselect_all
                                                    : document.data.export_qto_configuration_select_all
                                            }
                                        />
                                        <Text
                                            type="search"
                                            placeholder={asText(document.data.Search)}
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.currentTarget.value)}
                                        />
                                    </div>
                                    {/* </div> */}
                                    <div className={styles.body}>
                                        <DragDropContext onDragEnd={handleDragEnd}>
                                            <Droppable droppableId="group">
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                                        {renderTree()}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>
                                </>
                            )}

                            <div className={styles.footer}>
                                {isAuthenticated ? (
                                    <Button
                                        id="export-configurator-configuration-update-button"
                                        disabled={isCreating || isDeleting || isUpdating}
                                        onClick={() => (selectedItem ? onUpdateConfiguration() : onSaveConfiguration())}
                                    >
                                        {selectedItem
                                            ? isUpdating
                                                ? document.data.export_qto_configuration_is_updating
                                                : document.data.export_qto_configuration_update
                                            : isCreating
                                            ? document.data.export_qto_configuration_is_saving
                                            : document.data.export_qto_configuration_save}
                                    </Button>
                                ) : (
                                    <div
                                        style={{ cursor: "pointer" }}
                                        data-for="export-tooltips"
                                        data-tip={asText(document.data.UserFeature)}
                                    >
                                        <Button id="export-configurator-configuration-save-button" disabled>
                                            {document.data.export_qto_configuration_save}
                                        </Button>
                                    </div>
                                )}
                                <Button
                                    id="export-configurator-export-clear-button"
                                    style={{ marginLeft: "auto" }}
                                    variant="secondary"
                                    onClick={() => modelDispatch({ type: "CLEAR_EXPORT" })}
                                >
                                    {asText(document.data.Cancel)}
                                </Button>
                                <Button
                                    id="export-configurator-export-button"
                                    style={{ marginLeft: 10 }}
                                    startIcon={<FontAwesomeIcon icon={faFileExport} />}
                                    onClick={() => onExport()}
                                    disabled={isExporting}
                                >
                                    {isExporting
                                        ? document.data.export_qto_is_exporting
                                        : document.data.export_qto_export}
                                </Button>
                            </div>

                            <ReactTooltip
                                id={`export-tooltips`}
                                type="light"
                                effect="solid"
                                border={true}
                                borderColor={"#8c969b"}
                                place={"top"}
                                getContent={(dataTip) => <span style={{ whiteSpace: "nowrap" }}>{dataTip}</span>}
                            />

                            <Modal
                                width={"small"}
                                open={showConfigNameModal}
                                onClose={() => {
                                    setShowConfigNameModal(false);
                                    setIsCreating(false);
                                }}
                                backdrop
                            >
                                <ModalHeader closeButton>
                                    <ModalTitle>{document.data.export_qto_new_configuration_title}</ModalTitle>
                                </ModalHeader>
                                <ModalBody>
                                    <div>
                                        <strong style={{ margin: "0 0 8px 16px" }}>
                                            {document.data.export_qto_new_configuration_name}
                                        </strong>

                                        <Text
                                            type="text"
                                            required={configName.trim().length === 0}
                                            value={configName}
                                            onChange={(e) => setConfigName(e.target.value)}
                                        />
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        id="export-configurator-config-name-modal-show-button"
                                        variant="tertiary"
                                        onClick={() => {
                                            setShowConfigNameModal(false);
                                            setIsCreating(false);
                                        }}
                                    >
                                        {asText(document.data.Cancel)}
                                    </Button>
                                    <Button
                                        id="export-configurator-configuration-create-button"
                                        onClick={() => onCreateConfiguration()}
                                        disabled={configName.trim().length === 0}
                                    >
                                        {asText(document.data.Save)}
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </div>
                </div>
            </Portal>
        )
    );
};

export default ExportConfigurator;
