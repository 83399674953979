import { Trash2 } from "react-feather";
import { Button, Separator, useLanguage, usePrismic } from "@buildwise/ui";
import { asText } from "@prismicio/helpers";

import { config } from "../../_configuration/configuration";

import "../../styles/model-viewer.css";

const ProjectInfo = (props) => {
    const { language } = useLanguage();
    const [document] = usePrismic(config.prismic.documentType);

    const creationDate = props.project.creationDate && new Date(props.project.creationDate);
    const modificationDate =
        (props.project.modificationDate && new Date(props.project.modificationDate)) || creationDate;
    const isSharedProject = props.isSharedProject === true;

    return (
        <div className="project-box">
            <div className="project-box-header">
                <h2>{props.project.name}</h2>
            </div>

            {props.onDelete &&
                (isSharedProject ? (
                    <Separator items={[{ name: props.project.ownerEmail }]} color="#005B55" textColor="#FFFFFF" />
                ) : (
                    <Separator
                        items={
                            props.project.isShared
                                ? [{ name: asText(document.data.Shared) }]
                                : [{ name: asText(document.data.NotShared) }]
                        }
                        color="#C45B00"
                        textColor="#FFFFFF"
                    />
                ))}

            {props.project.description && (
                <div className="project-box-content">
                    <p>{props.project.description}</p>
                </div>
            )}

            <div className="project-box-image">
                <p style={{ margin: "0 24px 24px 24px" }}>
                    <span style={{ display: "block" }}>
                        <strong>{asText(document.data.CreationDate)}</strong>{" "}
                        {creationDate.toLocaleDateString(language)}
                    </span>
                    <span style={{ display: "block" }}>
                        <strong>{asText(document.data.ModificationDate)}</strong>{" "}
                        {modificationDate.toLocaleDateString(language)}
                    </span>
                </p>
                <img src="/img/venster_bg.jpg" alt={props.project.name} style={{ width: "100%", height: "150px" }} />
            </div>

            <div className="project-box-footer">
                {!isSharedProject && props.onDelete && (
                    <Button id="project-info-delete-button" variant="tertiary" onClick={() => props.onDelete()} startIcon={<Trash2 />}>
                        {asText(document.data.Delete)}
                    </Button>
                )}
                <Button id="project-info-open-button" variant="primary" onClick={() => props.onOpen()} style={{ marginLeft: "auto" }}>
                    {asText(document.data.Open)}
                </Button>
            </div>
        </div>
    );
};

export default ProjectInfo;
