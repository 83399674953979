import { Fragment, useEffect, useContext, useState } from "react";
import { generatePath, useLocation, useParams } from "react-router-dom";
import { IconButton, Select, Text, useAuthentication, useLanguage, usePrismic } from "@buildwise/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { asText } from "@prismicio/helpers";

import ColorPicker from "../../../../components/ColorPicker";

import { FilterContext } from "../../../../contexts/FilterContextProvider";
import { ModelViewerContext } from "../../../../contexts/ModelViewerContextProvider";

import { config } from "../../../../_configuration/configuration";

import styles from "./FilterManagement.module.css";

const ColorFilterPart = (props) => {
    const { state: modelState } = useContext(ModelViewerContext);
    const { state: filterState } = useContext(FilterContext);

    // const [colorModes] = useState(["Manual"]);
    const [properties, setProperties] = useState(props.properties || []);
    const [operations, setOperations] = useState([]);

    const [isEditing, setIsEditing] = useState();
    const [, forceRender] = useState(0);

    const [selectedClass, setSelectedClass] = useState([]);
    const [classesSelection, setClassesSelection] = useState([]);
    const [selectedOperand, setSelectedOperand] = useState([]);
    const [operandsSelection, setOperandsSelection] = useState([]);
    const [selectedOperator, setSelectedOperator] = useState([]);

    const params = useParams();
    const { id: projectId } = params;
    const { pathname } = useLocation();
    const { isAuthenticated } = useAuthentication();
    const { language } = useLanguage();
    const [document] = usePrismic(config.prismic.documentType);
    const isAdminPage = pathname.startsWith(generatePath(config.routes.admin[language], { ...params }));

    useEffect(() => {
        setIsEditing(props.item.isEditing ?? false);

        const classes = props.classes.map((classItem) => {
            if (classItem.length > 1) return { label: classItem, value: classItem };
            else
                return {
                    label: asText(document.data.filter_management_filter_part_any_ifc_element),
                    value: "",
                };
        });
        setClassesSelection(classes);
        const selectedClass = classes.find((classItem) => classItem.value === props.item.class);
        if (selectedClass) setSelectedClass([selectedClass]);
    }, [props.item]);

    useEffect(() => {
        const propertyList = properties.map((propertyItem) => ({
            label: propertyItem.name,
            value: propertyItem.name,
        }));
        setOperandsSelection(propertyList);
        const selectedOperand = propertyList.find((propertyItem) => propertyItem.value === props.item.operand);
        if (selectedOperand) setSelectedOperand([selectedOperand]);

        if (props.item.operand && properties.length > 0) {
            const selectedProperty = properties.find((p) => p.name === props.item.operand);
            if (selectedProperty) {
                const operations = filterState.operands[selectedProperty.target.toLowerCase()].map((operationItem) => ({
                    label: operationItem,
                    value: operationItem,
                }));

                setOperations(operations);
            }
        }

        if (props.item.operator) {
            const selectedOperator = operations.find((operationItem) => operationItem.value === props.item.operator);
            if (selectedOperator) setSelectedOperator([selectedOperator]);
        }
    }, [properties, props.item.operand]);

    useEffect(() => {
        if (props.item.class !== "") {
            const activeModels = modelState.loaded.map((m) => m.id);
            if (activeModels.length > 1) {
                const options = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        models: activeModels,
                        class: props.item.class,
                    }),
                };
                fetch(`${config.api}api/v1/Projects/${projectId}/Filters/FilterProperties`, options)
                    .then((response) => response.json())
                    .then((json) => setProperties(json))
                    .catch((err) => console.warn("Error attempting to get element properties:", err));
            } else {
                const options = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
                fetch(
                    `${config.api}api/v1/Projects/${projectId}/Filters/FilterProperties/${activeModels}/Class/${props.item.class}`,
                    options
                )
                    .then((response) => response.json())
                    .then((json) => setProperties(json))
                    .catch((err) => console.warn("Error attempting to get element properties:", err));
            }
        } else {
            setProperties(props.properties);
        }
    }, [props.item.class]);

    // const onChangeColorMode = (value) => {
    //     props.update("colorMode", value);
    //     props.update("operator", "");
    //     props.update("value", "");
    //     forceRender((x) => x + 1);
    // };

    const onChangeIfcClass = (activeIfcClassSelection) => {
        let value = "";
        if (activeIfcClassSelection.length > 0) value = activeIfcClassSelection[0].value;

        props.update("class", value);
        props.update("operand", "");
        setSelectedOperand([]);
        props.update("operator", "");
        setSelectedOperator([]);
        props.update("value", "");
    };

    const onChangeOperand = (activeOperandSelection) => {
        let value = "";
        if (activeOperandSelection.length > 0) value = activeOperandSelection[0].value;

        props.update("operand", value);
        props.update("operator", "");
        setSelectedOperator([]);
        props.update("value", "");
    };

    const onChangeOperator = (activeOperatorSelection) => {
        let value = "";
        if (activeOperatorSelection.length > 0) value = activeOperatorSelection[0].value;

        props.update("operator", value);
        props.update("value", "");
        forceRender((x) => x + 1);
    };

    const onChangeValue = (value) => {
        props.update("value", value);
        forceRender((x) => x + 1);
    };

    const onChangeColor = (value) => {
        props.update("color", value);
        forceRender((x) => x + 1);
    };

    return (
        <Fragment>
            {isEditing ? (
                <div className={styles.filterEditor}>
                    {/* <div className={styles.filterEditorLine}>
                        <div className={styles.filterEditorElement}>
                            <Select
                                options={colorModes}
                                selection={props.item.colorMode}
                                onChange={(e) => onChangeColorMode(e)}
                                noMargin
                            />
                        </div>
                    </div> */}
                    <div className={styles.filterEditorLine}>
                        <div className={styles.filterEditorElement} style={{ flex: 1 }}>
                            <Select
                                placeholder={asText(document.data.IfcClass)}
                                options={classesSelection}
                                selection={selectedClass}
                                onChangeSelection={(items) => onChangeIfcClass(items)}
                                filterable
                                style={{ height: 48 }}
                            />
                        </div>
                    </div>
                    <div className={styles.filterEditorLine}>
                        <div className={styles.filterEditorElement} style={{ flex: 1 }}>
                            <Select
                                placeholder={asText(document.data.Property)}
                                options={operandsSelection}
                                selection={selectedOperand}
                                onChangeSelection={(items) => onChangeOperand(items)}
                                clearable
                                filterable
                                style={{ height: 48 }}
                            />
                        </div>
                    </div>
                    <div className={styles.filterEditorLine}>
                        <div className={styles.filterEditorElement}>
                            <Select
                                placeholder={asText(document.data.Operator)}
                                options={operations}
                                selection={selectedOperator}
                                disabled={props.item.operand === "" || props.item.operand === null}
                                onChangeSelection={(items) => onChangeOperator(items)}
                                clearable
                                style={{ height: 48 }}
                            />
                        </div>

                        <div className={styles.filterEditorElementText} style={{ flex: 1 }}>
                            <Text
                                type="text"
                                placeholder={asText(document.data.Value)}
                                value={props.item.value}
                                onChange={(e) => onChangeValue(e.target.value)}
                                disabled={
                                    props.item.operator === "Exists" ||
                                    props.item.operator === "NotExists" ||
                                    props.item.operator === "IsFalse" ||
                                    props.item.operator === "IsTrue" ||
                                    props.item.operator === "" ||
                                    props.item.operator === null
                                }
                                style={{ marginTop: 0, height: 18, flex: 1, maxWidth: "unset", width: "unset" }}
                            />
                        </div>

                        <div className={styles.filterEditorElement} style={{ display: "flex", alignItems: "center" }}>
                            {props.item.colorMode === "Manual" && (
                                <ColorPicker rgb={props.item.color} onChange={(rgb) => onChangeColor(rgb)} />
                            )}
                            <IconButton
                                variant="primary"
                                onClick={() => setIsEditing(false)}
                                disabled={props.item.invalid === true}
                            >
                                <FontAwesomeIcon icon={faCheck} />
                            </IconButton>

                            <IconButton variant="secondary" onClick={props.remove} style={{ marginLeft: 10 }}>
                                <FontAwesomeIcon icon={faTrash} />
                            </IconButton>
                        </div>
                    </div>
                </div>
            ) : (
                <Fragment>
                    <div className={styles.filterAsString}>
                        <span>
                            {props.item.class || asText(document.data.filter_management_filter_part_any_ifc_element)}
                        </span>
                        {props.item.operand && <span>{props.item.operand}</span>}
                        {props.item.operator && <span>{props.item.operator}</span>}
                        {props.item.value && <span>{props.item.value}</span>}
                        {props.item.colorMode && (
                            <span>
                                {props.item.colorMode === "Automatic" ? (
                                    "Automatische kleur bepaling"
                                ) : (
                                    <Swatch color={props.item.color} />
                                )}
                            </span>
                        )}
                    </div>
                    {(!props.filter.isPublished || isAdminPage) && isAuthenticated && (
                        <div className={styles.filterAsStringActions}>
                            <FontAwesomeIcon
                                icon={faEdit}
                                onClick={() => setIsEditing(true)}
                                style={{ cursor: "pointer" }}
                            />
                            <FontAwesomeIcon
                                icon={faTrash}
                                onClick={props.remove}
                                style={{ marginLeft: 25, cursor: "pointer" }}
                            />
                        </div>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default ColorFilterPart;

const Swatch = ({ color }) => {
    return (
        <div className={styles.miniswatch}>
            <div
                className={styles.color}
                style={{
                    background: `linear-gradient(135deg, rgba(${color.r}, ${color.g}, ${color.b}, ${color.a}) 50%, rgba(${color.r}, ${color.g}, ${color.b}, 1) 50%)`,
                }}
            />
        </div>
    );
};
