import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Rnd } from "react-rnd";
import { Spinner, useLanguage, usePrismic } from "@buildwise/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompass, faTimesSquare } from "@fortawesome/pro-regular-svg-icons";
import { asText } from "@prismicio/helpers";

import { ModelViewerContext } from "../../../contexts/ModelViewerContextProvider";
import { useIndexedModal, ModelIndex } from "../../../components/ModalHelper/ModalHelper";

import { getElementProperties } from "../../../adapters/ElementAdapter";

import { config } from "../../../_configuration/configuration";

const TvSearch = () => {
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);
    const { zIndex } = useIndexedModal(ModelIndex.TvSearch);

    const [mapping, setMapping] = useState([]);
    const [keywords, setKeywords] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const { id: projectId } = useParams();
    const { language } = useLanguage();
    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        const mapping = require("../Data/TvSearch.json");
        setMapping(mapping);
    }, []);

    const defaultPosition = {
        x: Number(localStorage.getItem("tvsearch-x")) || 20,
        y: Number(localStorage.getItem("tvsearch-y")) || 60,
        width: localStorage.getItem("tvsearch-width") || 550,
        height: localStorage.getItem("tvsearch-height") || "80%",
    };

    useEffect(() => {
        if (!mapping) return;

        if (!modelState.selectedElement) {
            setKeywords(null);
            return;
        }

        const { id: ifcGuid, modelId } = modelState.selectedElement;
        if (!modelId || !ifcGuid) {
            return;
        }

        setIsLoading(true);

        getElementProperties(projectId, modelId, ifcGuid).then((props) => {
            const identification = props.find((x) => x.name === "Identification");
            const ifcEntity = identification.properties.find((x) => x.name === "IFC Entity");
            const ifcClass = ifcEntity.value;

            const mappingKeys = Object.keys(mapping).filter((x) => x.startsWith(ifcClass.toLowerCase()));
            let keywords = [];
            for (let i = 0, len = mappingKeys.length; i < len; i++) {
                keywords = keywords.concat(mapping[mappingKeys[i]]);
            }

            keywords = keywords.filter(
                (value, index) =>
                    keywords.indexOf(value) === index &&
                    (language === "nl-be" ? Boolean(value.Dutch) : Boolean(value.French))
            );
            keywords = keywords.sort((a, b) =>
                language === "nl-be" ? a.Dutch?.localeCompare(b.Dutch) : a.French?.localeCompare(b.French)
            );

            if (keywords.length === 0) setKeywords(null);
            else setKeywords(keywords);
            setIsLoading(false);
        });
    }, [modelState.selectedElement, mapping]);

    const storePosition = (x, y) => {
        localStorage.setItem("tvsearch-x", x);
        localStorage.setItem("tvsearch-y", y);
    };

    const storeSize = (width, height) => {
        localStorage.setItem("tvsearch-width", width);
        localStorage.setItem("tvsearch-height", height);
    };

    const getUrl = (keyword, type) => {
        if (language === "nl-be")
            return `https://www.wtcb.be/publicaties/publicaties-zoeken/?searchQuery=${keyword.Dutch}&category=${type}`;
        else
            return `https://www.cstc.be/publications/chercher-une-publication/?searchQuery=${keyword.French}&category=${type}`;
    };
    return (
        <Rnd
            default={defaultPosition}
            dragHandleClassName="handle"
            id="tv-search"
            onMouseDown={() => modelDispatch({ type: "SET_ACTIVE_MODAL", payload: ModelIndex.TvSearch })}
            onDragStop={(e, d) => storePosition(d.x, d.y)}
            onResizeStop={(e, direction, ref, delta, position) => storeSize(ref.style.width, ref.style.height)}
            bounds="#viewer-content"
            style={{ display: "flex", zIndex: zIndex }}
        >
            <div id="tv-search-header" className="handle">
                <FontAwesomeIcon icon={faCompass} />
                <h2>{asText(document.data.TvSearch)}</h2>
                <FontAwesomeIcon
                    icon={faTimesSquare}
                    className="close-button"
                    onClick={() => modelDispatch({ type: "TOGGLE_TV_SEARCH" })}
                />
            </div>

            <div id="tv-search-content">
                {isLoading ? (
                    <Spinner />
                ) : keywords ? (
                    <div className="property-set">
                        <div className="property-set-header expanded">
                            <h3>{asText(document.data.TvSearchSelectKeyword)}</h3>
                        </div>

                        <div className="property-set-data">
                            <table className="material-table">
                                <tbody>
                                    {keywords.map((keyword, i) => (
                                        <tr key={i}>
                                            <td>{language === "nl-be" ? keyword.Dutch : keyword.French}</td>
                                            <td width="100px">
                                                <a
                                                    href={getUrl(keyword, "publications")}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {asText(document.data.Publications)}
                                                </a>
                                            </td>
                                            <td width="100px">
                                                <a href={getUrl(keyword, "standards")} target="_blank" rel="noreferrer">
                                                    {asText(document.data.Regulations)}
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <span style={{ height: "1px", display: "block" }}></span>
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <p style={{ margin: "0 auto" }}>
                            {modelState.selectedElement && Object.keys(modelState.selectedElement).length > 0
                                ? asText(document.data.TvSearchNoKeywords)
                                : asText(document.data.TvSearchNoSelection)}
                        </p>
                    </div>
                )}
            </div>
        </Rnd>
    );
};

export default TvSearch;
