import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Spinner, useAuthentication, usePrismic } from "@buildwise/ui";

import App from "./App";

import Projects from "./pages/ModelViewer/Projects";
import ProjectViewer from "./pages/ModelViewer/ProjectViewer";
import AdminProjectViewer from "./pages/Admin/AdminProjectViewer";

import { config } from "./_configuration/configuration";
import Manage from "./pages/Admin/Manage";

const AppRoutes = () => {
    const { isAdmin } = useAuthentication();
    const [isLoading, setIsLoading] = useState(true);

    const { instance } = useAuthentication();
    const [, { state: prismicState }] = usePrismic(config.prismic.documentType);
    const navigate = useNavigate();

    useEffect(() => {
        const account = instance.getActiveAccount();

        if (!account) {
            setIsLoading(false);
            return;
        }

        instance
            .acquireTokenSilent({
                scopes: config.auth.scopes,
                account: account,
            })
            .then(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.name === "InteractionRequiredAuthError") {
                    instance
                        .acquireTokenPopup({
                            scopes: config.auth.scopes,
                            account: account,
                        })
                        .catch(() => {
                            instance.logoutRedirect().then(() => {
                                navigate("/");
                            });
                        });

                    return;
                }
            });
    }, []);

    return prismicState === "loaded" && !isLoading ? (
        <Routes>
            <Route index element={<App />} />
            <Route path=":lang" element={<App />}>
                <Route index element={<Projects />} />
                <Route path={"projects"}>
                    <Route path={":id"}>
                        <Route index element={<ProjectViewer />} />
                    </Route>
                </Route>

                {isAdmin && (
                    <Route path={"admin"}>
                        <Route path={"projects"}>
                            <Route index element={<Manage />} />
                            <Route path={":id"} element={<AdminProjectViewer />} />
                        </Route>
                    </Route>
                )}
            </Route>
        </Routes>
    ) : (
        <Spinner />
    );
};

export default AppRoutes;
