import { config } from "../../_configuration/configuration";

export const generateExport = async (projectId: string, exportOptions: any): Promise<Blob | null> => {
    const url = `${config.api}/api/v1/Projects/${projectId}/QtoExport`;
    const options: RequestInit = {
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        body: JSON.stringify(exportOptions),
    };

    const response = await fetch(url, options);
    if (!response.ok) return null;

    return response.blob();
};

export const getExportConfigurations = async (): Promise<Array<any>> => {
    const url = `${config.api}/api/v1/QtoExport`;
    const options: RequestInit = {
        method: "GET",
    };

    const response = await fetch(url, options);
    if (!response.ok) return [];

    return response.json();
};

export const createExportConfiguration = async (configurationOptions: any): Promise<any> => {
    const url = `${config.api}/api/v1/QtoExport`;
    const options: RequestInit = {
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        body: JSON.stringify(configurationOptions),
    };

    const response = await fetch(url, options);
    if (!response.ok) return null;

    return response.json();
};

export const updateExportConfiguration = async (id: number, configurationOptions: any): Promise<any> => {
    const url = `${config.api}/api/v1/QtoExport/${id}`;
    const options: RequestInit = {
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        body: JSON.stringify(configurationOptions),
    };

    const response = await fetch(url, options);
    if (!response.ok) return null;

    return response.json();
};

export const deleteExportConfiguration = async (id: number): Promise<boolean> => {
    const url = `${config.api}/api/v1/QtoExport/${id}`;
    const options: RequestInit = {
        method: "DELETE",
    };

    const response = await fetch(url, options);
    return response.ok;
};
