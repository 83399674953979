import { config } from "../../_configuration/configuration";

export interface Project {
    creationDate: Date;
    description: string;
    id: string;
    isShared: boolean;
    modificationDate: Date;
    name: string;
    isDemo: boolean;
}

export const getDemoProject = async (): Promise<Project | null> => {
    const url = `${config.api}api/v1/Projects?admin=true`;
    const options: RequestInit = {
        method: "GET",
        headers: {
            "content-type": "application/json",
        },
    };

    const response = await fetch(url, options);
    const projects = (await response.json()) as Project[];

    return projects.find((x) => x.isDemo) ?? null;
};
