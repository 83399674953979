export const production = {
    api: "https://wtcb-tools-production-ifcviewer.azurewebsites.net/",
    sentry: {
        dsn: "https://a5d63dc482094abdbf8810c44225923c@o399431.ingest.sentry.io/5940620",
        env: "production",
        sampleRate: 0.1,
    },
    gtm: {
        gtmId: "GTM-K32P2WN",
    },
    auth: {
        clientId: "68db96b4-e340-4a6b-adac-ea393079800e",
        authority: "https://login.bbri.be/wtcbidence.onmicrosoft.com/b2c_1a_signup_signin/",
        knownAuthorities: ["https://login.bbri.be/"],
        redirectUri: `${window.location.protocol}//${window.location.hostname}`,
        postLogoutRedirectUri: `${window.location.protocol}//${window.location.hostname}`,
        cacheLocation: "localStorage",
        scopes: ["https://wtcbidence.onmicrosoft.com/BIMio/BIMio.Access", "openid", "profile", "offline_access"],
    },
    feedback: "https://wtcb-tools-production-ifcviewer.azurewebsites.net/api/v1/Feedback",
};
