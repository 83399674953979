import { useState, useContext } from "react";
import { Button, Checkbox, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, usePrismic } from "@buildwise/ui";
import useModelViewer from "../Hooks/useModelViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAlt, faArrowDown, faArrowLeft, faArrowRight, faArrowUp } from "@fortawesome/pro-regular-svg-icons";
import { asText } from "@prismicio/helpers";

import { ModelViewerContext } from "../../../contexts/ModelViewerContextProvider";

import mouseLeftClick from "../../../assets/icons/mouse-light-left.svg";
import mouseRightClick from "../../../assets/icons/mouse-light-right.svg";
import rotateFree from "../../../assets/icons/cube-light-rotate.svg";
import rotateXNeg from "../../../assets/icons/cube-light-rotate-x-neg.svg";
import rotateXPos from "../../../assets/icons/cube-light-rotate-x-pos.svg";
import rotateYNeg from "../../../assets/icons/cube-light-rotate-y-neg.svg";
import rotateYPos from "../../../assets/icons/cube-light-rotate-y-pos.svg";

import { config } from "../../../_configuration/configuration";

import "../../../styles/model-viewer.css";

const ViewerSettings = () => {
    const { dispatch: modelDispatch } = useContext(ModelViewerContext);

    const [ambientShadow, setAmbientShadow] = useState(localStorage.getItem("viewer-sao") === "true" || false);

    const { getSAO } = useModelViewer();
    const [document] = usePrismic(config.prismic.documentType);

    const saveChanges = () => {
        try {
            const info = getSAO();
            info.sao.enabled = ambientShadow;
            localStorage.setItem("viewer-sao", ambientShadow);
        } catch {}

        closeModal();
    };

    const closeModal = () => {
        modelDispatch({ type: "TOGGLE_VIEWER_CONFIGURATION" });
    };

    return (
        <Modal size="small" onClose={() => closeModal()} open={true} backdrop>
            <ModalHeader closeButton>
                <ModalTitle>{asText(document.data.ManageViewerSettings)}</ModalTitle>
            </ModalHeader>

            <ModalBody>
                <div>
                    <p>{asText(document.data.ManageViewerSettingsInfo)}</p>
                </div>

                <div id="viewer-settings">
                    <Checkbox
                        label="Ambient Shadow"
                        checked={ambientShadow}
                        indeterminate={""}
                        onChange={(e) => setAmbientShadow(e.target.checked)}
                    />
                </div>
            </ModalBody>

            <div style={{ marginBottom: 30 }} />

            <ModalHeader closeButton={false}>
                <ModalTitle>{asText(document.data.viewer_settings_keybinds)}</ModalTitle>
            </ModalHeader>

            <ModalBody>
                <div className="keybind-wrapper">
                    <div className="keybind-column">
                        <div className="keybind">
                            <kbd className="keyboard-button">A</kbd>
                            <span>{asText(document.data.viewer_settings_keybinds_pan_left)}</span>
                        </div>
                        <div className="keybind">
                            <kbd className="keyboard-button">D</kbd>
                            <span>{asText(document.data.viewer_settings_keybinds_pan_right)}</span>
                        </div>
                        <div className="keybind">
                            <kbd className="keyboard-button">Z</kbd>
                            <span>{asText(document.data.viewer_settings_keybinds_pan_up)}</span>
                        </div>
                        <div className="keybind">
                            <kbd className="keyboard-button">X</kbd>
                            <span>{asText(document.data.viewer_settings_keybinds_pan_down)}</span>
                        </div>
                        <div className="keybind">
                            <kbd className="keyboard-button">W</kbd>
                            <span>{asText(document.data.viewer_settings_keybinds_dolly_forwards)}</span>
                        </div>
                        <div className="keybind">
                            <kbd className="keyboard-button">S</kbd>
                            <span>{asText(document.data.viewer_settings_keybinds_dolly_backwards)}</span>
                        </div>
                    </div>
                    <div className="keybind-column" style={{ marginLeft: "auto" }}>
                        <div className="keybind">
                            <kbd className="keyboard-button" style={{ padding: "0.25rem 0.6rem" }}>
                                <FontAwesomeIcon icon={faArrowDown} style={{ fontSize: 14, height: 20 }} />
                            </kbd>
                            <div className="info-image-container">
                                <img src={rotateXPos} alt="" />
                            </div>
                            <span>{asText(document.data.viewer_settings_keybinds_rotate_x_pos)}</span>
                        </div>
                        <div className="keybind">
                            <kbd className="keyboard-button" style={{ padding: "0.25rem 0.6rem" }}>
                                <FontAwesomeIcon icon={faArrowUp} style={{ fontSize: 14, height: 20 }} />
                            </kbd>
                            <div className="info-image-container">
                                <img src={rotateXNeg} alt="" />
                            </div>
                            <span>{asText(document.data.viewer_settings_keybinds_rotate_x_neg)}</span>
                        </div>
                        <div className="keybind">
                            <kbd className="keyboard-button" style={{ padding: "0.25rem 0.725rem" }}>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: 14, height: 20 }} />
                            </kbd>
                            <span className="key-separator" />
                            <kbd className="keyboard-button">Q</kbd>
                            <div className="info-image-container">
                                <img src={rotateYPos} alt="" />
                            </div>
                            <span>{asText(document.data.viewer_settings_keybinds_rotate_y_pos)}</span>
                        </div>
                        <div className="keybind">
                            <kbd className="keyboard-button" style={{ padding: "0.25rem 0.725rem" }}>
                                <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 14, height: 20 }} />
                            </kbd>
                            <span className="key-separator" />
                            <kbd className="keyboard-button">E</kbd>
                            <div className="info-image-container">
                                <img src={rotateYNeg} alt="" />
                            </div>
                            <span>{asText(document.data.viewer_settings_keybinds_rotate_y_neg)}</span>
                        </div>
                        <div className="keybind">
                            <div className="image-container">
                                <img src={mouseLeftClick} alt="" />
                            </div>
                            <div className="info-image-container">
                                <img src={rotateFree} alt="" />
                            </div>
                            <span>{asText(document.data.viewer_settings_keybinds_rotate)}</span>
                        </div>
                        <div className="keybind">
                            <div className="image-container">
                                <img src={mouseRightClick} className="mouse" alt="" />
                            </div>
                            <div className="info-image-container">
                                <FontAwesomeIcon icon={faArrowsAlt} style={{ width: 30, height: 50 }} />
                            </div>
                            <span>{asText(document.data.viewer_settings_keybinds_pan)}</span>
                        </div>
                    </div>
                </div>
            </ModalBody>

            <ModalFooter>
                <Button id="viewer-settings-changes-cancel-button" variant="tertiary" onClick={() => closeModal()}>
                    {asText(document.data.Cancel)}
                </Button>
                <Button id="viewer-settings-changes-save-button" onClick={() => saveChanges()}>
                    {asText(document.data.Save)}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ViewerSettings;
