export const staging = {
    api: "https://wtcb-tools-staging-ifc-models-service.azurewebsites.net/",
    sentry: {
        dsn: "https://a5d63dc482094abdbf8810c44225923c@o399431.ingest.sentry.io/5940620",
        env: "staging",
        sampleRate: 1,
    },
    gtm: {
        gtmId: "GTM-55SCF3M",
    },
    auth: {
        clientId: "256cd153-7731-434f-ac1e-e955e40f962b",
        authority: "https://login-staging.bbri.be/wtcbidencestaging.onmicrosoft.com/b2c_1a_signup_signin/",
        knownAuthorities: ["https://login-staging.bbri.be/"],
        redirectUri: `${window.location.protocol}//${window.location.hostname}`,
        postLogoutRedirectUri: `${window.location.protocol}//${window.location.hostname}`,
        cacheLocation: "localStorage",
        scopes: ["https://wtcbidencestaging.onmicrosoft.com/BIMio/BIMio.Access", "openid", "profile", "offline_access"],
    },
    feedback: "https://wtcb-tools-staging-ifc-models-service.azurewebsites.net/api/v1/Feedback",
};
