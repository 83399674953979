import { forwardRef } from "react";

export const BeaconComponent = forwardRef((props, ref) => {
    return (
        <span
            style={{
                width: "15px",
                height: "15px",
                display: "inline-block",
                background: "orange",
                borderRadius: "50%",
            }}
            ref={ref}
            {...props}
        >
            <span
                style={{
                    background: "orange",
                    borderRadius: "50%",
                    animation: "1s ease-in-out 0s infinite normal none running pulse",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                }}
            ></span>
        </span>
    );
});
