import { Fragment, useEffect, useState, useContext } from "react";
import { generatePath, useLocation, useParams } from "react-router-dom";
import { Plus, X } from "react-feather";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Checkbox, Text, useAuthentication, useLanguage, usePrismic } from "@buildwise/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faEdit } from "@fortawesome/pro-regular-svg-icons";
import { asText } from "@prismicio/helpers";

import ColorFilterPart from "./ColorFilterPart";

import { FilterContext } from "../../../../contexts/FilterContextProvider";
import { ModelViewerContext } from "../../../../contexts/ModelViewerContextProvider";

import { config } from "../../../../_configuration/configuration";

import styles from "./FilterManagement.module.css";

const ColorFilters = ({ filter, classes, properties }) => {
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);
    const { state: filterState, dispatch: filterDispatch } = useContext(FilterContext);

    const [currentFilter, setCurrentFilter] = useState();
    const [filterName, setFilterName] = useState();
    const [shouldPublish, setShouldPublish] = useState(false);

    const [editName, setEditName] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [, forceRender] = useState(0);

    const params = useParams();
    const { id: projectId } = params;
    const { pathname } = useLocation();
    const { language } = useLanguage();
    const [document] = usePrismic(config.prismic.documentType);
    const { isAuthenticated } = useAuthentication();
    const isAdminPage = pathname.startsWith(generatePath(config.routes.admin[language], { ...params }));

    useEffect(() => {
        setCurrentFilter(filter);
        if (filter) {
            setFilterName(filter.name);
            setShouldPublish(filter.isPublished);
        }
    }, [filter]);

    useEffect(() => {
        validateFilter();
    }, [currentFilter]);

    useEffect(() => {
        if (filterName === asText(document.data.UnnamedFilter)) setEditName(true);
        setCanSave(Boolean(filterName));
    }, [filterName]);

    const validateFilter = () => {
        if (!currentFilter) return;

        if (!filterName) {
            setCanSave(false);
            return;
        }

        if (currentFilter.parts.length === 0) {
            setCanSave(false);
            return;
        }

        for (let i = 0, len = currentFilter.parts.length; i < len; i++) {
            const part = currentFilter.parts[i];
            validatePart(part);
        }

        setCanSave(!currentFilter.parts.some((x) => x.invalid));
        forceRender((x) => x + 1);
    };

    const validatePart = (part) => {
        let valid = true;

        // if (!part.class) valid = false;
        if (!part.operand && part.colorMode === "Automatic") valid = false;
        if (part.operand && part.colorMode === "Manual" && !part.operator) valid = false;

        part.invalid = !valid;
    };

    const addPart = () => {
        const filter = { ...currentFilter };
        filter.parts.push({
            order: Number(filter.parts.length) + 1,
            colorMode: "Manual",
            class: "",
            operand: "",
            operator: "",
            value: "",
            color: { r: 241, g: 112, b: 19, a: 1 },
            isEditing: true,
        });

        setCurrentFilter(filter);
    };

    const updatePart = (id, type, value) => {
        const filter = { ...currentFilter };
        const part = filter.parts.find((x) => x.order === id);
        part[type] = value;

        setCurrentFilter(filter);
    };

    const removePart = (id) => {
        const filter = { ...currentFilter };
        filter.parts = filter.parts.filter((x) => x.order !== id);

        setCurrentFilter(filter);
    };

    const reorderParts = (result) => {
        if (!result.destination) return;
        const filter = { ...currentFilter };
        moveArrayElement(filter.parts, result.source.index, result.destination.index);

        setCurrentFilter(filter);
    };

    const moveArrayElement = (arr, old_index, new_index) => {
        while (old_index < 0) old_index += arr.length;
        while (new_index < 0) new_index += arr.length;

        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) arr.push(undefined);
        }

        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    };

    const refreshFilters = () => {
        fetch(`${config.api}api/v1/Projects/${projectId}/ColorFilters`, {
            method: "GET",
            mode: "cors",
            headers: {},
        })
            .then((response) => response.json())
            .then((filters) => {
                filterDispatch({
                    type: "SET_COLOR_FILTERS",
                    payload: filters,
                });
            })
            .catch((e) => console.warn("Failed to get filters list:", e));
    };

    const saveFilter = () => {
        let url = `${config.api}api/v1/ColorFilters`;

        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...currentFilter,
                name: filterName,
                parts: currentFilter.parts.map((part, index) => {
                    part.order = index;
                    return part;
                }),
                parent: null,
            }),
        };

        if (Boolean(currentFilter.id)) {
            options.method = "PUT";
            url = `${url}/${currentFilter.id}`;
        }

        return fetch(url, options)
            .then((response) => response.json())
            .then((filter) => {
                if (isAdminPage) {
                    let updateUrl = `${config.api}api/v1/ColorFilters/${filter.id}`;
                    const updateOptions = {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    };

                    if (shouldPublish) updateUrl = `${updateUrl}/Publish`;
                    else updateUrl = `${updateUrl}/Unpublish`;

                    return fetch(updateUrl, updateOptions).then((r) => {
                        return filter;
                    });
                }

                return filter;
            })
            .then((json) => {
                refreshFilters();
                const filter = { ...currentFilter };
                filter.id = json.id;
                setCurrentFilter({ ...filter });

                return json.id;
            });
    };

    const cancelChanges = () => {
        if (currentFilter.id) {
            const filters = JSON.parse(JSON.stringify(filterState.colorFilters));
            const filter = filters.find((x) => x.id === currentFilter.id);
            filter.parts = filter.parts.sort((a, b) => (a.order > b.order ? 1 : -1));

            setCurrentFilter({ ...filter });
            setEditName(false);
            setFilterName(filter.name);
        } else {
            setCurrentFilter();
        }

        modelDispatch({
            type: "TOGGLE_FILTER_MODAL",
        });
    };

    const applyFilter = (id) => {
        const activeModels = modelState.loaded.map((m) => m.id);
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(activeModels),
        };

        fetch(`${config.api}api/v1/Projects/${projectId}/ColorFilters/${id}/Apply`, options)
            .then((response) => response.json())
            .then((json) => {
                modelDispatch({
                    type: "COLOR_FILTER_GUIDS",
                    payload: json,
                });
                modelDispatch({
                    type: "ACTIVE_COLOR_FILTER",
                    payload: id,
                });
                modelDispatch({
                    type: "TOGGLE_FILTER_MODAL",
                });
            })
            .catch((err) => console.warn("Error attempting to get element properties:", err));
    };

    const saveAndApply = () => {
        saveFilter().then((res) => applyFilter(res));
    };

    const renderParts = () => {
        return (
            <DragDropContext onDragEnd={reorderParts}>
                <Droppable droppableId="listline">
                    {(provided) => (
                        <div className="listline" {...provided.droppableProps} ref={provided.innerRef}>
                            {currentFilter.parts.length > 0 &&
                                currentFilter.parts.map((part, index) => (
                                    <Draggable key={"l_" + part.order} draggableId={"l_" + part.order} index={index}>
                                        {(provided) => (
                                            <div
                                                className={
                                                    part.invalid
                                                        ? [styles.filterPart, styles.invalidPart].join(" ")
                                                        : styles.filterPart
                                                }
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                            >
                                                <div className={styles.handle} {...provided.dragHandleProps}>
                                                    <FontAwesomeIcon icon={faBars} />
                                                </div>

                                                <ColorFilterPart
                                                    filter={currentFilter}
                                                    item={part}
                                                    classes={classes}
                                                    properties={properties}
                                                    update={(type, value) => updatePart(part.order, type, value)}
                                                    remove={() => removePart(part.order)}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };

    return (
        <div className={styles.right}>
            <div className={styles.header}>
                <div className={styles.editableTitle}>
                    {Boolean(currentFilter) &&
                        (editName ? (
                            <Text
                                type="text"
                                autoFocus
                                value={filterName}
                                onChange={(e) => setFilterName(e.target.value)}
                                label={asText(document.data.FilterName)}
                                onFocus={(e) => filterName === asText(document.data.UnnamedFilter) && e.target.select()}
                                onBlur={() => setEditName(false)}
                            />
                        ) : (
                            <h2
                                className={styles.question}
                                onClick={() => (isAdminPage || !currentFilter.isPublished) && setEditName(true)}
                            >
                                {currentFilter.isPublished && !isAdminPage
                                    ? document.data[filterName] ?? `Missing Prismic Key: '${filterName}'`
                                    : filterName}{" "}
                                {(isAdminPage || !currentFilter.isPublished) && isAuthenticated && (
                                    <FontAwesomeIcon icon={faEdit} />
                                )}
                            </h2>
                        ))}
                </div>
                <div
                    className={styles.close}
                    onClick={() =>
                        modelDispatch({
                            type: "TOGGLE_FILTER_MODAL",
                        })
                    }
                >
                    <X />
                </div>
            </div>

            {Boolean(currentFilter) ? (
                <Fragment>
                    <div className={styles.body}>
                        {isAdminPage && (
                            <Checkbox
                                label={document.data.manage_filters_set_as_bbri_filter}
                                checked={shouldPublish}
                                onChange={() => setShouldPublish((x) => !x)}
                            />
                        )}

                        {renderParts()}

                        {(!currentFilter.isPublished || isAdminPage) && isAuthenticated && (
                            <div>
                                <Button id="color-filters-part-add-button" variant="secondary" onClick={() => addPart()} startIcon={<Plus />}>
                                    {asText(document.data.AddPart)}
                                </Button>
                            </div>
                        )}
                    </div>

                    <div className={styles.footer}>
                        <Button id="color-filters-changes-cancel-button" variant="secondary" onClick={() => cancelChanges()}>
                            {asText(document.data.Cancel)}
                        </Button>
                        {(!currentFilter.isPublished || isAdminPage) && isAuthenticated && (
                            <Fragment>
                                <Button id="color-filters-filter-save-button" variant="primary" onClick={() => saveFilter()} disabled={!canSave}>
                                    {asText(document.data.Save)}
                                </Button>
                                <Button id="color-filters-filter-save-and-apply-button" onClick={() => saveAndApply()} disabled={!canSave}>
                                    {asText(document.data.SaveAndApply)}
                                </Button>
                            </Fragment>
                        )}
                    </div>
                </Fragment>
            ) : (
                <div className={styles.message}>
                    <p>{document.data.color_filters_no_selection}</p>
                </div>
            )}
        </div>
    );
};

export default ColorFilters;
