import { Fragment, useContext, useRef, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ArrowLeft, ArrowRight } from "react-feather";
import { useAuthentication } from "@buildwise/ui";

import { FilterContext } from "../../contexts/FilterContextProvider";
import { ModelViewerContext } from "../../contexts/ModelViewerContextProvider";

import useSharedModelFunctions from "./Hooks/useSharedModelFunctions";
import useModelViewer from "./Hooks/useModelViewer";
import IfcViewer from "../../components/IfcViewer/IfcViewer";
import useSectionPlanes from "./Hooks/useSectionPlanes";
import ActionBar from "./ActionBar";
import ActiveView from "./ActiveView";
import ElementProperties from "./Modals/ElementProperties";
import ExportConfigurator from "./Modals/ExportConfigurator/ExportConfigurator";
import FilterManagement from "./Modals/FilterManagement/FilterManagement";
import SectionPlaneManagement from "./Modals/SectionPlaneManagement";
import TvSearch from "./Modals/TvSearch";
import ViewerSettings from "./Modals/ViewerSettings";
import IfcSectionPlanes from "../../components/IfcSectionPlanes/IfcSectionPlanes";
import { getProjectState } from "../../managers/UserStateManager";
import IfcNavCube from "../../components/IfcNavCube/IfcNavCube";
import { config } from "../../_configuration/configuration";

const Viewer = () => {
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);
    const { state: filterState } = useContext(FilterContext);

    const loadedProjectState = useRef(false);
    const loadingProjectState = useRef(null);
    const defaultCameraAngleSet = useRef(false);
    const clickedElement = useRef();

    const { id: projectId } = useParams();
    const location = useLocation();
    const { isAuthenticated } = useAuthentication();

    const { loadModel } = useSharedModelFunctions();
    const {
        setCameraAngle,
        getObjects,
        setVisibleObjects,
        getSelectedObjects,
        setSelectedObjects,
        startLoadingAnimation,
        stopLoadingAnimation,
        getSAO,
        setXRayedObjects,
        getViewer,
        setObjectsColor,
        setObjectsOpacity,
    } = useModelViewer();

    const {
        getSectionPlanes,
        createSectionPlane,
        removeSectionPlane,
        getActiveSectionPlaneControl,
        toggleSectionPlaneControl,
    } = useSectionPlanes();

    useEffect(() => {
        if (clickedElement.current) {
            if (modelState.selectionMode === "single") setSelectedObjects(getSelectedObjects(), false);

            setSelectedObjects(clickedElement.current.ifcGuid, !clickedElement.current.selected);

            const currentSelection = getSelectedObjects();
            if (currentSelection.length <= 1)
                modelDispatch({
                    type: "CHANGE_SELECTED_ELEMENT",
                    payload: !clickedElement.current.selected
                        ? {
                              id: clickedElement.current.ifcGuid,
                              modelId: clickedElement.current.model,
                          }
                        : {},
                });
            else
                modelDispatch({
                    type: "CHANGE_SELECTED_ELEMENT",
                    payload: {},
                });

            modelDispatch({
                type: "SET_SELECTION_COUNT",
                payload: currentSelection.length,
            });
        } else {
            setSelectedObjects(getSelectedObjects(), false);
            modelDispatch({
                type: "CHANGE_SELECTED_ELEMENT",
                payload: {},
            });
            modelDispatch({
                type: "CHANGE_PROPERTIES_ELEMENT",
                payload: {},
            });
            modelDispatch({
                type: "SET_SELECTION_COUNT",
                payload: 0,
            });
        }
    }, [clickedElement.current]);

    useEffect(() => {
        if (modelState.filterGuids) {
            setVisibleObjects(getObjects(), true);
            setXRayedObjects(getObjects(), false);

            if (filterState.filterAction === 0) {
                // Action upon filtering set to hiding other elements
                setVisibleObjects(getObjects(), false);
            } else {
                // Action upon filtering set to Xray other elements
                setXRayedObjects(getObjects(), true);
                setXRayedObjects(modelState.filterGuids, false);
            }

            setVisibleObjects(modelState.filterGuids, true);
        } else {
            setXRayedObjects(getObjects(), false);
            setVisibleObjects(getObjects(), true);
            if (modelState.loaded) modelState.loaded.forEach((model) => hideIfcSpaceElements(model));
        }
    }, [modelState.filterGuids, filterState.filterAction]);

    useEffect(() => {
        if (modelState.colorFilterGuids) {
            setObjectsColor(getObjects(), null);
            setObjectsOpacity(getObjects(), null);

            for (let i = 0, len = modelState.colorFilterGuids.length; i < len; i++) {
                const colorDef = modelState.colorFilterGuids[i];
                if (!colorDef) continue;
                const color = [colorDef.color.r / 255, colorDef.color.g / 255, colorDef.color.b / 255];
                const alpha = [colorDef.color.a];
                setObjectsColor(colorDef.elements, color);
                setObjectsOpacity(colorDef.elements, alpha);
            }
        } else {
            setObjectsColor(getObjects(), null);
            setObjectsOpacity(getObjects(), null);
            if (modelState.loaded) modelState.loaded.forEach((model) => resetIfcSpaceElements(model));
        }
    }, [modelState.colorFilterGuids]);

    useEffect(() => {
        const activeView = modelState.views.find((x) => x.isActive);
        if (!activeView) {
            return;
        }

        modelDispatch({ type: "LOADING_VIEW", payload: true });

        for (let i = 0; i < activeView.viewModels.length; i++) {
            const modelInfo = activeView.viewModels[i];
            const alreadyLoaded = modelState.loaded.find((x) => x.id === modelInfo.id);

            if (alreadyLoaded) continue;

            startLoadingAnimation();
            loadModel(projectId, modelInfo.id, modelInfo.name);
        }

        const modelIds = activeView.viewModels.map((x) => x.id);
        const extraLoaded = modelState.loaded.filter((x) => modelIds.indexOf(x.id) === -1);

        for (let i = 0; i < extraLoaded.length; i++) {
            modelDispatch({
                type: "UNLOAD_MODEL",
                payload: extraLoaded[i].id,
            });
        }

        if (loadedProjectState.current && loadingProjectState.current) {
            const projectState = loadingProjectState.current;

            if (projectState.activeFilter) {
                modelDispatch({
                    type: "ACTIVE_FILTER",
                    payload: projectState.activeFilter,
                });
            }

            if (projectState.activeModels && projectState.activeModels.length > 0) {
                for (let i = 0; i < projectState.activeModels.length; i++) {
                    const modelId = projectState.activeModels[i];
                    const alreadyLoaded = modelState.loaded.find((x) => x.id === modelId);
                    const alreadyLoadingFromView = activeView.viewModels.find((x) => x.id === modelId);

                    if (alreadyLoaded || alreadyLoadingFromView) continue;

                    const modelInfo = modelState.models.find((x) => x.id === modelId);

                    startLoadingAnimation();
                    loadModel(projectId, modelInfo.id, modelInfo.name);
                }
            }
        }
    }, [modelState.views]);

    useEffect(() => {
        if (modelState.viewLoading) restoreViewData();
    }, [modelState.viewLoading]);

    useEffect(() => {
        if (loadedProjectState.current) return;
        if (!projectId) return;

        if (!isAuthenticated) {
            loadedProjectState.current = true;
            return;
        }

        const projectState = getProjectState(projectId);

        if (location.search && location.search.indexOf("?view=") !== -1) {
            const params = new URLSearchParams(location.search);
            const viewId = params.get("view");

            if (viewId) {
                loadedProjectState.current = true;
                return;
            }
        }

        if (modelState.models.length <= 0) return;
        if (projectState.activeView && modelState.views.length <= 0) return;
        if (projectState.activeFilter && filterState.filters.length <= 0) return;

        loadedProjectState.current = true;
        loadProjectState(projectState);
    }, [projectId, modelState.filters, modelState.models, modelState.views]);

    const loadProjectState = (projectState) => {
        if (projectState.activeView) {
            modelDispatch({ type: "SET_ACTIVE_VIEW", payload: projectState.activeView });
            loadingProjectState.current = projectState;
            return;
        }

        if (projectState.activeFilter) {
            modelDispatch({
                type: "ACTIVE_FILTER",
                payload: projectState.activeFilter,
            });
        }

        if (projectState.activeColorFilter) {
            modelDispatch({
                type: "ACTIVE_COLOR_FILTER",
                payload: projectState.activeColorFilter,
            });
        }

        if (projectState.activeModels && projectState.activeModels.length > 0) {
            for (let i = 0; i < projectState.activeModels.length; i++) {
                const modelId = projectState.activeModels[i];
                const alreadyLoaded = modelState.loaded.find((x) => x.id === modelId);

                if (alreadyLoaded) continue;

                const modelInfo = modelState.models.find((x) => x.id === modelId);

                startLoadingAnimation();
                loadModel(projectId, modelInfo.id, modelInfo.name);
            }
        }
    };

    useEffect(() => {
        const viewer = getViewer();
        if (viewer == null || viewer.scene === undefined || modelState.camera === undefined) {
            return;
        }

        const { camera } = viewer.scene;

        camera.projection = modelState.camera;
    }, [modelState.camera]);

    const restoreViewData = (onlyRestoreViewState = false) => {
        const activeView = modelState.views.find((x) => x.isActive);
        if (!activeView) return;

        const loaded = modelState.models.filter((x) => x.loaded);

        if (
            areEqual(
                loaded.map((x) => x.id),
                activeView.viewModels.map((x) => x.id)
            )
        ) {
            const viewer = getViewer();
            const blob = JSON.parse(activeView.viewerBlob) ?? {};

            if (blob.camera) restoreCamera(viewer.scene, blob.camera);
            if (blob.entities) restoreElements(blob.entities);
            if (blob.sections) restoreSections(blob.sections);

            if (activeView.modelFilterSets.length > 0 && !onlyRestoreViewState) {
                applyFilter(activeView.modelFilterSets[0].filterId).then((_) => {
                    restoreViewData(true); // Apply camera and element state again after applying filter
                });
            } else {
                modelDispatch({ type: "LOADING_VIEW", payload: false });
            }
        }
    };

    const applyFilter = (id) => {
        const activeModels = modelState.loaded.map((m) => m.id);
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(activeModels),
        };

        return fetch(`${config.api}api/v1/Projects/${projectId}/Filters/${id}/Apply`, options)
            .then((response) => response.json())
            .then((json) => {
                modelDispatch({
                    type: "FILTER_GUIDS",
                    payload: json,
                });
                modelDispatch({
                    type: "ACTIVE_FILTER",
                    payload: id,
                });
            })
            .catch((err) => console.warn("Error attempting to get element properties:", err));
    };

    const areEqual = (arr1, arr2) => {
        const distinct = (value, index, self) => self.indexOf(value) === index;

        const sortedArr1 = arr1.filter(distinct).sort();
        const sortedArr2 = arr2.filter(distinct).sort();

        if (sortedArr1.length !== sortedArr2.length) return false;

        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) return false;
        }

        return true;
    };

    const restoreCamera = (scene, memento) => {
        const camera = scene.camera;
        const savedProjection = memento._projection;
        const restoreProjection = () => {
            switch (savedProjection.projection) {
                case "perspective":
                    camera.perspective.fov = savedProjection.fov;
                    camera.perspective.fovAxis = savedProjection.fovAxis;
                    camera.perspective.near = savedProjection.near;
                    camera.perspective.far = savedProjection.far;
                    break;

                case "ortho":
                    camera.ortho.scale = savedProjection.scale;
                    camera.ortho.near = savedProjection.near;
                    camera.ortho.far = savedProjection.far;
                    break;

                case "frustum":
                    camera.frustum.left = savedProjection.left;
                    camera.frustum.right = savedProjection.right;
                    camera.frustum.top = savedProjection.top;
                    camera.frustum.bottom = savedProjection.bottom;
                    camera.frustum.near = savedProjection.near;
                    camera.frustum.far = savedProjection.far;
                    break;

                case "custom":
                    camera.customProjection.matrix = savedProjection.matrix;
                    break;

                default:
                    throw new Error(`Invalid projection type: ${savedProjection.type}`);
            }
        };

        scene.viewer.cameraFlight.flyTo(
            {
                eye: memento._eye,
                look: memento._look,
                up: memento._up,
                orthoScale: savedProjection.scale,
                projection: savedProjection.projection,
                duration: 0.2,
            },
            () => {
                restoreProjection();
            }
        );
    };

    const restoreElements = (opts) => {
        // Reset X-ray and visibility
        setXRayedObjects(getObjects(), false);
        setVisibleObjects(getObjects(), true);

        // Apply saved state
        setVisibleObjects(opts.hidden, false);
        setXRayedObjects(opts.xray, true);
    };

    const restoreSections = (opts) => {
        const existing = getSectionPlanes();
        for (let i = 0, len = existing.length; i < len; i++) {
            removeSectionPlane(existing[i]);
        }

        for (let i = 0, len = opts.length; i < len; i++) {
            const section = opts[i];
            createSectionPlane({
                id: section.id,
                pos: [section.pos[0], section.pos[1], section.pos[2]],
                dir: [section.dir[0], section.dir[1], section.dir[2]],
                active: section.active,
                dist: section.dist,
            });
        }

        toggleSectionPlaneControl({ id: getActiveSectionPlaneControl() });
    };

    useEffect(() => {
        const id = modelState.activeFilter;

        if (!id) return;

        const activeModels = modelState.loaded.map((m) => m.id);
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(activeModels),
        };

        fetch(`${config.api}api/v1/Projects/${projectId}/Filters/${id}/Apply`, options)
            .then((response) => response.json())
            .then((json) => {
                modelDispatch({
                    type: "FILTER_GUIDS",
                    payload: json,
                });
                modelDispatch({
                    type: "ACTIVE_FILTER",
                    payload: id,
                });
            })
            .catch((err) => console.warn("Error attempting to get element properties:", err));
    }, [modelState.loaded]);

    const onModelLoaded = (model) => {
        hideIfcSpaceElements(model);

        modelDispatch({ type: "MODEL_LOADED", payload: model });

        stopLoadingAnimation();

        restoreViewData();

        if (defaultCameraAngleSet.current) return;
        defaultCameraAngleSet.current = true;

        const activeView = modelState.views.find((x) => x.isActive);
        if (!activeView) setCameraAngle("front-top-right");

        try {
            const info = getSAO();
            info.sao.enabled = localStorage.getItem("viewer-sao") === "true" || false;
        } catch {}
    };

    const hideIfcSpaceElements = (model) => {
        const ifcSpaceElements = [];

        if (!model || !modelState.mergedClassTree || modelState.mergedClassTree.length === 0) return;

        traverseIfcSpaceNodes(
            model ? model.id : null,
            model ? model.spatialStructureTree : modelState.mergedClassTree,
            ifcSpaceElements
        );

        setVisibleObjects(ifcSpaceElements, false);
    };

    const resetIfcSpaceElements = (model) => {
        const ifcSpaceElements = [];

        if (!model || !modelState.mergedClassTree || modelState.mergedClassTree.length === 0) return;

        traverseIfcSpaceNodes(
            model ? model.id : null,
            model ? model.spatialStructureTree : modelState.mergedClassTree,
            ifcSpaceElements
        );

        setObjectsOpacity(ifcSpaceElements, 0.2);
    };

    const traverseIfcSpaceNodes = (modelId, nodes, arr) => {
        const myNodes = Array.isArray(nodes) ? nodes : [nodes];
        for (let i = 0; i < myNodes.length; i++) {
            const node = myNodes[i];

            if (node?.type === "IfcSpace" || (node.parent && node.parent.endsWith("IfcSpace"))) {
                node.visible = true;
                arr.push(node.id);
            }

            if (node.children && node.children.length > 0) {
                traverseIfcSpaceNodes(modelId, node.children, arr);
            }
        }
    };


    return (
        <div id="viewer" >
            <button
                className="button tertiary"
                id="sidebar-control"
                onClick={() => modelDispatch({ type: "TOGGLE_SIDEBAR" })}
            >
                {modelState.sidebar ? (
                    <Fragment>
                        <ArrowLeft style={{ marginRight: "14px" }} />
                    </Fragment>
                ) : (
                    <Fragment>
                        <ArrowRight style={{ marginRight: "14px" }} />
                    </Fragment>
                )}
            </button>

            <ActiveView />
            <div id="viewer-controls">
                <IfcNavCube
                    cfg={{
                        canvasId: "viewer-navcube-canvas",
                        cameraFly: true,
                        cameraFitFOV: 45,
                        cameraFlyDuration: 0.5,
                        color: "lightgrey",
                        synchProjection: true,
                    }}
                />
                <ActionBar />
            </div>

            <IfcSectionPlanes
                cfg={{
                    canvasId: "viewer-section-planes",
                }}
            />

            <IfcViewer
                onIfcElementClicked={(e) => (clickedElement.current = e)}
                models={modelState.loaded}
                onModelLoaded={onModelLoaded}
            />

            {modelState.tvSearch && <TvSearch />}
            {/* Drag */}
            {modelState.properties && <ElementProperties />}
            {modelState.sections && <SectionPlaneManagement />}
            {/*draggable  */}
            {modelState.config && <ViewerSettings />}
            {modelState.filterModal && <FilterManagement />}

            <ExportConfigurator />
            {/* draggable */}
        </div>
    );
};

export default Viewer;
