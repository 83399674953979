import { useContext } from "react";

import { ModelViewerContext } from "../../../contexts/ModelViewerContextProvider";

import { config } from "../../../_configuration/configuration";

const useSharedModelFunctions = () => {
    const { state: modelState, dispatch: modelDispatch } = useContext(ModelViewerContext);

    const loadModel = async (projectId, id, name) => {
        let model;

        const modelInfo = modelState.models.find((x) => x.id === id);
        if (modelInfo.src && modelInfo.classTree && modelInfo.structureTree) {
            model = {
                id,
                name: name,
                src: modelInfo.src,
                extension: "xkt",
                spatialStructureTree: modelInfo.structureTree,
                ifcClassTree: modelInfo.classTree,
                performance: true,
                backfaces: false,
                edges: false,
            };
        } else {
            modelDispatch({
                type: "FETCH_MODEL",
                payload: { projectId: projectId, id },
            });

            const [xkt, spatialStructureTree, ifcClassTree] = await Promise.all([
                fetch(`${config.api}api/v1/Projects/${projectId}/Models/${id}/Files/XKT/Download`, {
                    method: "GET",
                    mode: "cors",
                    headers: {},
                }).then((result) => result.blob()),
                fetch(`${config.api}api/v1/Projects/${projectId}/Models/${id}/Files/TREEVIEW/Download`, {
                    method: "GET",
                    mode: "cors",
                    headers: {},
                }).then((result) => result.json()),
                fetch(`${config.api}api/v1/Projects/${projectId}/Models/${id}/Files/GROUPVIEW/Download`, {
                    method: "GET",
                    mode: "cors",
                    headers: {},
                }).then((result) => result.json()),
            ]);

            modelDispatch({
                type: "FETCHED_MODEL",
                payload: { projectId: projectId, id },
            });

            model = {
                id,
                name: name,
                src: URL.createObjectURL(xkt),
                extension: "xkt",
                spatialStructureTree: [addModelId(spatialStructureTree, id)],
                ifcClassTree: organize(addModelId(ifcClassTree, id)),
                performance: true,
                backfaces: false,
                edges: false,
            };

            modelDispatch({
                type: "UPDATE_MODEL",
                payload: {
                    id,
                    src: model.src,
                    structureTree: model.spatialStructureTree,
                    classTree: model.ifcClassTree,
                },
            });
        }

        modelDispatch({
            type: "LOAD_MODEL",
            payload: { model: model, projectId: projectId },
        });
    };

    const addModelId = (tree, modelId) => {
        const arr = Array.isArray(tree) ? tree : [tree];

        for (let i = 0; i < arr.length; i++) {
            arr[i].modelId = modelId;

            if (arr[i].children && arr[i].children.length > 0) {
                addModelId(arr[i].children, modelId);
            }

            if (arr[i].Types && arr[i].Types.length > 0) {
                arr[i].isClass = true;
                arr[i].children = [...arr[i].Types];

                addModelId(arr[i].Types, modelId);
            }

            if (arr[i].Objects && arr[i].Objects.length > 0) {
                arr[i].isType = true;
                arr[i].children = [...arr[i].Objects];

                addModelId(arr[i].Objects, modelId);
            }
        }

        return tree;
    };

    const organize = (tree) => {
        for (let i = 0; i < tree.length; i++) {
            const classEntry = tree[i];
            classEntry.id = classEntry.IfcClass.toLowerCase();
            classEntry.name = classEntry.IfcClass;
            classEntry.isClassGroup = true;

            delete classEntry.IfcClass;
            delete classEntry.Types;

            for (let j = 0; j < classEntry.children.length; j++) {
                const typeEntry = classEntry.children[j];
                typeEntry.id = `${classEntry.name}_${typeEntry.Name || ""}`;
                typeEntry.name = typeEntry.Name || "";
                typeEntry.isClassGroup = true;

                delete typeEntry.Name;
                delete typeEntry.Objects;

                for (let k = 0; k < typeEntry.children.length; k++) {
                    const elementEntry = typeEntry.children[k];
                    if (!elementEntry.children) elementEntry.children = [];
                }
            }
        }

        return tree;
    };

    return { loadModel };
};

export default useSharedModelFunctions;
